import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import ListOldLead from "./ListOldLead";
import HelmetComponent from "../../Common/HelmetComponent";

const OldLead = () => {
  return (
    <>
      <HelmetComponent title="Old-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Old Lead"
          home="Dashboard"
          currentPage="Old Lead"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <ListOldLead />
      </div>
    </>
  );
};

export default OldLead;
