import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddOwner from "./AddOwnerList";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddOwnerPage = () => {
  return (
    <>
      <HelmetComponent title="Add-Owner" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add Owner"
          home="Dashboard"
          currentPage="Add Owner"
          addExtraPageTitle="Owner"
          addExtraPageTitlePath="owners"
        />
        <AddOwner />
      </div>
    </>
  );
};

export default AddOwnerPage;
