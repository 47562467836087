import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import ProjectList from "./ProjectList";
import HelmetComponent from "../../Common/HelmetComponent";

const ProjectPage = () => {
  return (
    <>
      <HelmetComponent title="Create-Project" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Create Project"
          home="Dashbord"
          currentPage="Create Project"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <ProjectList />
      </div>
    </>
  );
};

export default ProjectPage;
