import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditOwnersList from "./EditOwnerList";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditOwnerPage = () => {
  return (
    <>
      <HelmetComponent title="Edit-Owner" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit Owner"
          home="Dashboard"
          currentPage="Edit Owner"
          addExtraPageTitle="Owner"
          addExtraPageTitlePath="owners"
        />
        <EditOwnersList />
      </div>
    </>
  );
};

export default EditOwnerPage;
