import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddTeamLeader from "./AddTeamLeader";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddTeamLeaderPage = () => {
  return (
    <>
      <HelmetComponent title="Add-Team-Leader" />
      <div className="container page-containners-main set-default-height-min">
        <CustomBreadcrumb
          title="Add Team Leader"
          home="Dashboard"
          currentPage="Add Team Leader"
          addExtraPageTitle="Team Leader"
          addExtraPageTitlePath="team-leaders"
        />
        <AddTeamLeader />
      </div>
    </>
  );
};

export default AddTeamLeaderPage;
