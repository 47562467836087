import React from "react";
import "../../../../asset/css/pages/tenants.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikDateField,
  FormikInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const AddTenants = () => {
  const options = [
    {
      is_approved: [
        {
          id: 1,
          option: "Yes",
        },
        {
          id: 2,
          option: "Partially",
        },
      ],
      is_approved_document: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
    },
  ];

  const initialValues = {
    tenant_id: "",
    tenant_type: "",
    tenant_name: "",
    email: "",
    emirates_id: "",
    passport: "",
    passports: "",
    trade_license: "",
    no_of_cheque: "",
    primary_no: "",
    secondary_no: "",
    nationality: "",
    is_document_to_tenant: "",
    is_approved: "",
    date_of_birth: "",
    passport_expiry_birth: "",
    emirates_id_expiry_date: "",
    trade_license_expiry_date: "",
    tradecy_contract_start_date: "",
    tradecy_contract_end_date: "",
  };

  const validateYupSchema = Yup.object()
    .shape({
      tenant_id: Yup.string().required("Field is required."),
      tenant_type: Yup.string().required("Field is required."),
      tenant_name: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      emirates_id: Yup.string().required("Field is required."),
      passport: Yup.string().required("Field is required."),
      passports: Yup.string().required("Field is required."),
      trade_license: Yup.string().required("Field is required."),
      no_of_cheque: Yup.string().required("Field is required."),
      primary_no: Yup.string().required("Field is required."),
      secondary_no: Yup.string().required("Field is required."),
      nationality: Yup.string().required("Field is required."),
      is_document_to_tenant: Yup.string().required("Field is required."),
      is_approved: Yup.string().required("Field is required."),
      date_of_birth: Yup.date().nullable().required("Date is required"),
      passport_expiry_birth: Yup.date().nullable().required("Date is required"),
      emirates_id_expiry_date: Yup.date()
        .nullable()
        .required("Date is required"),
      trade_license_expiry_date: Yup.date()
        .nullable()
        .required("Date is required"),
      tradecy_contract_start_date: Yup.date()
        .nullable()
        .required("Date is required"),
      tradecy_contract_end_date: Yup.date()
        .nullable()
        .required("Date is required"),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="mb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validateYupSchema}
          onSubmit={handleSubmitForm}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row my-3">
                    <FormikInputField
                      label="Tenant Id"
                      name="tenant_id"
                      placeholder="Enter Tenant Id"
                    />
                    <FormikInputField
                      label="Tenant Type"
                      name="tenant_type"
                      placeholder="Enter Tenant Type"
                    />
                    <FormikInputField
                      label="Tenant Name"
                      name="tenant_name"
                      placeholder="Enter Tenant Name"
                    />
                    <FormikInputField
                      label="Email"
                      name="email"
                      placeholder="Enter Email"
                    />
                    <FormikInputField
                      label="Emirates ID Front"
                      name="emirates_id"
                      placeholder="Enter Emirates ID Front"
                    />
                    <FormikInputField
                      label="Passport"
                      name="passport"
                      placeholder="Enter Passport"
                      type="file"
                    />
                    <FormikInputField
                      label="Passports"
                      name="passports"
                      placeholder="Enter Passports"
                      type="file"
                    />
                    <FormikInputField
                      label="Trade License"
                      name="trade_license"
                      placeholder="Enter Trade License"
                      type="file"
                    />
                    <FormikInputField
                      label="No. of Cheque"
                      name="no_of_cheque"
                      placeholder="Enter No. of Cheque"
                    />
                    <FormikInputField
                      label="Primary mobile number"
                      name="primary_no"
                      placeholder="Enter Primary mobile number"
                    />
                    <FormikInputField
                      label="Secondary mobile number"
                      name="secondary_no"
                      placeholder="Enter Secondary mobile number"
                    />
                    <FormikInputField
                      label="Nationality"
                      name="nationality"
                      placeholder="Enter Nationality"
                    />
                    <FormikSelectField
                      label="Is Document to Tenant ?"
                      name="is_document_to_tenant"
                      options={options[0]?.is_approved}
                    />
                    <FormikSelectField
                      label="Is Approved ?"
                      name="is_approved"
                      options={options[0]?.is_approved_document}
                    />
                    <FormikDateField
                      label="Date Of Birth"
                      name="date_of_birth"
                    />
                    <FormikDateField
                      label="Passport Expiry Date"
                      name="passport_expiry_birth"
                    />
                    <FormikDateField
                      label="Emirates ID Expiry Date"
                      name="emirates_id_expiry_date"
                    />
                    <FormikDateField
                      label="Trade license Expiry date"
                      name="trade_license_expiry_date"
                    />
                    <FormikDateField
                      label="Tenancy Contract Start Date"
                      name="tradecy_contract_start_date"
                    />
                    <FormikDateField
                      label="enancy Contract End Date"
                      name="tradecy_contract_end_date"
                    />
                  </div>
                  <div className="text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/tenants" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddTenants;
