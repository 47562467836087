import React, { useEffect, useState } from "react";
import { oldLeadDataTableData } from "../../../asset/StastucJsonData/OldLeadJsonData";
import { useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import DetailComponent from "../../Common/DetailsPageComponent/detailComponent";
import HelmetComponent from "../../Common/HelmetComponent";

const OldLeadDetails = () => {
  const [selectData, setSelectData] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const finData = oldLeadDataTableData.find((userID) => id === userID._id);
    setSelectData(finData);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <HelmetComponent title="View-Old-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Old Lead Details"
          home="Dashboard"
          addExtraPageTitle="All Lead"
          addExtraPageTitlePath="all-leads"
          currentPage="Old Lead Details"
        />

        <DetailComponent
          title="Lead Ref No. :"
          title1="Type :"
          title2="Status :"
          title3="Sub Status :"
          data={selectData?.Ref}
          data1={selectData?.Type}
          data2={selectData?.Status}
          data3={selectData?.Sub_Status}
        />
        <DetailComponent
          title="Priority :"
          title1="Hot :"
          title2="Contact Ref :"
          title3="First Name :"
          data={selectData?.Priority}
          data1={selectData?.Hot}
          data2={selectData?.Contact_Ref}
          data3={selectData?.First_name}
        />
        <DetailComponent
          title="Last Name :"
          title1="Contact Company :"
          title2="Mobile No :"
          title3="Phone No. :"
          data={selectData?.Last_name}
          data1={selectData?.Contact_Company}
          data2={selectData?.Mobile_No}
          data3={selectData?.Phone_No}
        />
        <DetailComponent
          title="Email :"
          title1="Category :"
          title2="Emirate :"
          title3="Location :"
          data={selectData?.Email_Address}
          data1={selectData?.Category}
          data2={selectData?.Emirate}
          data3={selectData?.Location}
        />
        <DetailComponent
          title="Sub Location :"
          title1="Unit Type :"
          title2="Unit No :"
          title3="Min Beds :"
          data={selectData?.Sub_location}
          data1={selectData?.Unit_Type}
          data2={selectData?.Unit_No}
          data3={selectData?.Min_Beds}
        />
        <DetailComponent
          title="Max Beds :"
          title1="Min Price :"
          title2="Max Price :"
          title3="Min Area :"
          data={selectData?.Max_Beds}
          data1={selectData?.Min_Price}
          data2={selectData?.Max_Price}
          data3={selectData?.Min_Area}
        />
        <DetailComponent
          title="Max Area :"
          title1="Listing Ref :"
          title2="Property Requirements 2 :"
          title3="Property Requirements 3 :"
          data={selectData?.Max_Area}
          data1={selectData?.Listing_Ref}
          data2={selectData?.Property_Requirements_2}
          data3={selectData?.Property_Requirements_3}
        />
        <DetailComponent
          title="Property Requirements 4  :"
          title1="Source :"
          title2="Other Source of Lead :"
          title3="Agent 1 :"
          data={selectData?.Property_Requirements_4}
          data1={selectData?.Source}
          data2={" - "}
          data3={selectData?.Agent1}
        />
        <DetailComponent
          title="Agent 2  :"
          title1="Agent 3 :"
          title2="Agent 4 :"
          title3="Agent 5 :"
          data={selectData?.Agent2}
          data1={selectData?.Agent3}
          data2={selectData?.Agent4}
          data3={selectData?.Agent5}
        />
        <DetailComponent
          title="Created By :"
          title1="Listing Ref :"
          title2="Is Phone Lead :"
          title3="Enquiry Date :"
          data={selectData?.Created_By}
          data1={selectData?.Listing_Ref}
          data2={selectData?.Is_Phone_Lead}
          data3={selectData?.Enquiry_Date}
        />
        <DetailComponent
          title="Date Added :"
          title1="Date Updated :"
          title2="Agent Referral :"
          title3="Share this lead :"
          data={selectData?.Date_Added}
          data1={selectData?.Date_Updated}
          data2={selectData?.Agent_Referral}
          data3={selectData?.Share_this_lead}
        />
        <DetailComponent
          title="Country :"
          title1="City :"
          title2="Location :"
          title3="Sub Location :"
          data={selectData?.Country}
          data1={selectData?.City}
          data2={selectData?.Location}
          data3={selectData?.Sub_location}
        />
        <DetailComponent
          title="Initial Contact :"
          title1="Notes :"
          title2=""
          title3=""
          data={selectData?.Initial_Contact}
          data1={selectData?.Notes}
          data2=""
          data3=""
        />
      </div>
    </>
  );
};

export default OldLeadDetails;
