import React from "react";
import ListAllLead from "./ListAllLead.jsx";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb.jsx";
import HelmetComponent from "../../Common/HelmetComponent/index.jsx";

const AllLeads = () => {
  return (
    <>
      <HelmetComponent title="All-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="All Lead"
          home="Dashboard"
          currentPage="All Leads"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <ListAllLead />
      </div>
    </>
  );
};

export default AllLeads;
