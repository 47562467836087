import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../../Common/DataTableStyle/dataTableStyle";
import { developerListData } from "../../../../../asset/StastucJsonData/developerLogoJsonData";
import { Link, useParams } from "react-router-dom";
import Modal from "../../../../Common/Model/ModelCommon";

const ChequeListPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(developerListData || []);
  const [showDeleteModel, setShowDeleteModel] = useState();
  const [deleteId, setDeleteId] = useState("");
  const { id } = useParams();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const getIdWiseData = (id) => {
    const findData = data.find((item) => id === item?._id);
    setData(findData);
  };

  const deleteCheque = (item) => {
    const datas = data.filter((user) => user._id !== item._id);
    setData(datas);
  };

  console.info(data);

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);
  useEffect(() => {
    deleteCheque(data);
  }, [data]);

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: "title",
      selector: (row) => row.title || " - ",
      wrap: true,
      minWidht: "200px",
      width: "400px",
    },

    {
      name: "Image",
      selector: (row) => {
        return (
          <>
            <div className="p-3">
              <img
                width="100px"
                height="100px"
                src={row?.image_url}
                alt="imgs"
                className="rounded-circle"
              />
            </div>
          </>
        );
      },
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Payment Type",
      selector: (row) => row?._id,
      center: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row?.date || "-",
      center: true,
      wrap: true,
    },
    {
      name: "Chequi Number",
      selector: (row) => row?.date || "-",
      center: true,
      wrap: true,
    },
    {
      name: "Bank",
      selector: (row) => row?.date || "-",
      center: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => row?.date || "-",
      center: true,
      wrap: true,
    },
    {
      name: "Details",
      selector: (row) => row?.date || "-",
      center: true,
      wrap: true,
    },
    {
      name: "Delete",
      selector: (row) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowDeleteModel(true);
                setDeleteId(row);
              }}
            >
              <img width={"30px;"} src="/svg/trash_images.png" alt="img-pen" />
            </div>
          </>
        );
      },
      center: true,
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="my-3 text-right w-100">
        <button type="submit" className="success-btn mx-2">
          Export
        </button>
      </div>
      <div className="d-flex datatable-header-main mx-0">
        <Link to={`/all-listing/cheque/add/add-cheque/${id}`}>
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="20px"
              alt="images"
            />
            Add Cheque
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : data}
        customStyles={customStyles}
        columns={column}
        noHeader={false}
        highlightOnHover
        persistTableHead
        pagination
        searchable
        responsive
        noDataComponent="No data available"
      />
      {/* Delete Model... */}
      {showDeleteModel && (
        <>
          <div className="common-status-button-containner">
            <Modal
              show={showDeleteModel}
              handleClose={() => setShowDeleteModel(false)}
            >
              <div className="sdvds">
                <div className="text-center">
                  <img
                    className="commonstatusbutton-img "
                    src="/svg/warning-animation.gif"
                    alt="user"
                  />
                </div>
                <h5 className="mb-5 text-center">
                  Are you sure you want to delete cheque ?
                </h5>

                <div className="modal-footer w-100 px-0">
                  <button
                    className="modal-footer-cancel-btn"
                    onClick={() => setShowDeleteModel(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="modal-footer-confirm-btn"
                    onClick={() => {
                      deleteCheque(deleteId);
                      setShowDeleteModel(false);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default ChequeListPage;
