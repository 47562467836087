import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { notificationListData } from "../../../asset/StastucJsonData/notificationJsonData";

const NotificationPageList = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = notificationListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      center: "true",
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      center: "true",
      wrap: true,
    },

    {
      name: "Created Date",
      selector: (row) => row?.create_at,
      center: "true",
      wrap: true,
    },
  ];

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-in-filter-main">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-100 search-box-in-filter-main-input "
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : notificationListData}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default NotificationPageList;
