import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import RolePermissionPage from "./RolePermission";
import HelmetComponent from "../../../Common/HelmetComponent";

const RolePermission = () => {
  return (
    <>
      <HelmetComponent title="Role-Permission" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Role Permission"
          home="Dashboard"
          currentPage="Role Permission"
          addExtraPageTitle="Role"
          addExtraPageTitlePath="role"
        />
        <RolePermissionPage />
      </div>
    </>
  );
};

export default RolePermission;
