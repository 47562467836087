import React from "react";
import HelmetComponent from "../../Common/HelmetComponent";
import ListAllListing from "./List/ListAllListing";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";

const Alllisting = () => {
  return (
    <>
      <HelmetComponent title="All-Listing" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="All Listing"
          home="Dashboard"
          currentPage="All Listing"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <ListAllListing />
      </div>
    </>
  );
};

export default Alllisting;
