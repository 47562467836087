import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditRole from "./EditRole";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditRolePage = () => {
  return (
    <>
      <HelmetComponent title="Edit-Role" />
      <div className="container page-containners-main set-default-height-min">
        <CustomBreadcrumb
          title="Edit Role"
          home="Dashbord"
          currentPage="Edit Role"
          addExtraPageTitle="Role Management"
          addExtraPageTitlePath="role"
        />
        <EditRole />
      </div>
    </>
  );
};

export default EditRolePage;
