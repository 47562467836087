import React from "react";
import HelmetComponent from "../../../../Common/HelmetComponent";
import CustomBreadcrumb from "../../../../Common/Breadcrumb/customBreadcrumb";
import AddCheque from "./addCheque";

const AddChequiePage = () => {
  return (
    <>
      <HelmetComponent title="Add Cheque" />
      <div className="container page-containners-main set-default-height-min">
        <CustomBreadcrumb
          title="Manage Add Cheque"
          home="Dashboard"
          currentPage="Add Cheque"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <AddCheque />
      </div>
    </>
  );
};

export default AddChequiePage;
