import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../asset/css/common/customBreadCrumb.css";

const CustomBreadcrumb = ({
  title,
  home,
  currentPage,
  addExtraPageTitle,
  addExtraPageTitlePath,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="page_title d-flex page-title-main">
        <div className="title-corrent-page-title-single">
          <h2>{title}</h2>
        </div>
        <div className="d-flex align-items-center page-sub-title-main">
          <img
            src="/svg/home.icon.png"
            alt="home"
            className="cursor-pointer breadcomp-home-icon"
            onClick={() => {
              navigate("/");
            }}
          />
          <h2
            className="my-3 page-sub-titles title-corrent-page-title"
            onClick={() => {
              navigate("/");
            }}
          >
            {home}
          </h2>
          {addExtraPageTitle ? (
            <>
              <h2 className="my-3 px-2 page-sub-titles title-corrent-page-title">
                /
              </h2>
              <h2
                className="my-3 page-sub-titles title-corrent-page-title"
                onClick={() => {
                  navigate(`/${addExtraPageTitlePath}`);
                }}
              >
                {addExtraPageTitle}
              </h2>
            </>
          ) : (
            ""
          )}
          <h2 className="my-3 px-2 page-sub-titles title-corrent-page-title">
            /
          </h2>
          <h2 className="my-3 page-sub-titles title-corrent-page-title active-color-sub-title">
            {currentPage}
          </h2>
        </div>
      </div>
    </>
  );
};

export default CustomBreadcrumb;
