import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddAgent from "./AddAgent";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddAgentsPage = () => {
  return (
    <>
      <HelmetComponent title="Add-Agent" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add New Agent"
          home="Dashboard"
          currentPage="Add Agent"
          addExtraPageTitle="Agent"
          addExtraPageTitlePath="agents"
        />
        <AddAgent />
      </div>
    </>
  );
};

export default AddAgentsPage;
