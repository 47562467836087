import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";
import ViewListPage from "./List/viewList";

const ViewPage = () => {
  return (
    <>
      <HelmetComponent title="Chat-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Viewing"
          home="Dashboard"
          currentPage="Viewing"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <ViewListPage />
      </div>
    </>
  );
};

export default ViewPage;
