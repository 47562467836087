import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import SalesOfferList from "./salesOfferList";
import HelmetComponent from "../../Common/HelmetComponent";

const SalesOfferPage = () => {
  return (
    <>
      <HelmetComponent title="Sales-Offer" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Sales Offer"
          home="Dashbord"
          currentPage="Sales Offer"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <SalesOfferList />
      </div>
    </>
  );
};

export default SalesOfferPage;
