import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import AgentList from "./AgentList";
import HelmetComponent from "../../Common/HelmetComponent";

const AgentPage = () => {
  return (
    <>
      <HelmetComponent title="Agent" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Manage Agent"
          home="Dashboard"
          currentPage="Manage Agent"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <AgentList />
      </div>
    </>
  );
};

export default AgentPage;
