import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AllDealsDetailsComponent from "./AllDealsDetails";
import HelmetComponent from "../../../Common/HelmetComponent";

const AllDealsDetails = () => {
  return (
    <>
      <HelmetComponent title="View-All-Deals" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="All Deals Details"
          home="Dashboard"
          currentPage="All Deals Details"
          addExtraPageTitle="All Deals"
          addExtraPageTitlePath="all-deals"
        />
        <AllDealsDetailsComponent />
      </div>
    </>
  );
};

export default AllDealsDetails;
