import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditAllDeals from "./EditAllDeals";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditAllDealsPage = () => {
  return (
    <>
      <HelmetComponent title="Edit-All-Deals" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit All Deals"
          home="Dashboard"
          currentPage="Edit All Deals"
          addExtraPageTitle="All Deals"
          addExtraPageTitlePath="all-deals"
        />
        <EditAllDeals />
      </div>
    </>
  );
};

export default EditAllDealsPage;
