import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import OldDealsDetails from "./OldDealsDetails";
import HelmetComponent from "../../../Common/HelmetComponent";

const OldDealsDetailsPage = () => {
  return (
    <>
      <HelmetComponent title="View-Old-Deals" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Old Deals Details"
          home="Dashboard"
          currentPage="Old Deals Details"
          addExtraPageTitle="Old Deals"
          addExtraPageTitlePath="old-deals"
        />
        <OldDealsDetails />
      </div>
    </>
  );
};

export default OldDealsDetailsPage;
