import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../../Common/HelmetComponent";
import CopyToRentalListPage from "./List/copyToRentalList";

const CopyToNewPropertyRental = () => {
  return (
    <>
      <HelmetComponent title="Copt To Rental" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Manage New Rental Property"
          home="Dashboard"
          currentPage="New Property Rental"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <CopyToRentalListPage />
      </div>
    </>
  );
};

export default CopyToNewPropertyRental;
