import React from "react";
import Login from "./login";
import HelmetComponent from "../../Common/HelmetComponent";

const LoginPage = () => {
  return (
    <>
      <HelmetComponent title="Login" />
      <Login />
    </>
  );
};

export default LoginPage;
