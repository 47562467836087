import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import CalendarList from "./CalendarList";
import HelmetComponent from "../../Common/HelmetComponent";

const Calendars = () => {
  return (
    <>
      <HelmetComponent title="Calendar" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Calendar"
          home="Dashboard"
          currentPage="Calendar"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
        />
        <CalendarList />
      </div>
    </>
  );
};

export default Calendars;
