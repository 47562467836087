import React, { useState } from "react";
import Modal from "../Model/ModelCommon";

const CommonStatusButton = ({ role, changeActive, moduleName }) => {
  const [showModal, setShowModal] = useState(false);

  const handleStatusChange = async () => {
    const postObj = {
      id: role._id,
      is_active: !role.is_active,
    };
    try {
      await changeActive(postObj);
      alert("Status change successfully.");
    } catch (err) {
      if (typeof err.response?.data?.message !== "undefined") {
        console.log("Server Error!!");
      } else {
        console.log("Server Error!!");
      }
    }
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="">
      {role?.is_active === false && (
        <button
          className="btn btn-sm btn-block btn-outline-danger"
          type="button"
          style={{ width: "100px" }}
          onClick={() => openModal()}
        >
          Inactivate
        </button>
      )}
      {role?.is_active === true && (
        <button
          className="btn btn-sm btn-block btn-outline-success"
          type="button"
          style={{ width: "100px" }}
          onClick={() => openModal()}
        >
          Active
        </button>
      )}

      {showModal && (
        <div className="common-status-button-containner">
          <Modal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
          >
            <div className="sdvds">
              <img
                className="commonstatusbutton-img"
                src="/svg/warning-animation.gif"
                alt="user"
              />
              <h5 className="mb-5">
                Are you sure you want to activate <br /> the {moduleName} ?
              </h5>

              <div className="modal-footer w-100 px-0">
                <button
                  className="modal-footer-cancel-btn"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="modal-footer-confirm-btn"
                  onClick={handleStatusChange}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default CommonStatusButton;
