import React from "react";
import CallLeadList from "./CallLeadList";
import "../../../asset/css/pages/callLead.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const CallLeads = () => {
  return (
    <>
      <HelmetComponent title="Call-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Call Lead"
          home="Dashboard"
          currentPage="Call Lead"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <CallLeadList />
      </div>
    </>
  );
};

export default CallLeads;
