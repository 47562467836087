import React from "react";
import DocumentListPage from "./List/documentList";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../../Common/HelmetComponent";

const DocumentPage = () => {
  return (
    <>
      <HelmetComponent title="Document" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Manage Document"
          home="Dashboard"
          currentPage="Document"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <DocumentListPage />
      </div>
    </>
  );
};

export default DocumentPage;
