import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import ListAgentPermission from "./ListAgentPermission";
import HelmetComponent from "../../../Common/HelmetComponent";

const AgentPermission = () => {
  return (
    <>
      <HelmetComponent title="Agent-Permission" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Agent Permission"
          home="Dashboard"
          currentPage="Agent Permission"
          addExtraPageTitle="Agent"
          addExtraPageTitlePath="agents"
        />
        <ListAgentPermission />
      </div>
    </>
  );
};

export default AgentPermission;
