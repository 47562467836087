import React from "react";
import "../../../../asset/css/pages/tenants.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikDateField,
  FormikInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const AddOwner = () => {
  const options = [
    {
      is_approved: [
        {
          id: 1,
          option: "Yes",
        },
        {
          id: 2,
          option: "Partially",
        },
      ],
      is_approved_document: [
        {
          id: 1,
          option: "No",
        },
        {
          id: 2,
          option: "Yes",
        },
      ],
    },
  ];

  const initialValues = {
    owner_id: "",
    owner_type: "",
    email: "",
    password: "",
    emirates_id_ront: "",
    emirates_id_back: "",
    passport: "",
    visa: "",
    primary_no: "",
    secondary_no: "",
    nationality: "",
    owner_document: "",
    owner_phone_no: "",
    is_approved: "",
    date_of_birth: "",
    passport_expiry_date: "",
    visa_expiry_date: "",
    emirates_id_expiry_date: "",
  };

  const validationSchema = Yup.object()
    .shape({
      owner_id: Yup.string().required("Field is required."),
      owner_type: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
      emirates_id_ront: Yup.string().required("Field is required."),
      emirates_id_back: Yup.string().required("Field is required."),
      passport: Yup.string().required("Field is required."),
      visa: Yup.string().required("Field is required."),
      primary_no: Yup.string().required("Field is required."),
      secondary_no: Yup.string().required("Field is required."),
      nationality: Yup.string().required("Field is required."),
      owner_document: Yup.string().required("Field is required."),
      owner_phone_no: Yup.string().required("Field is required."),
      is_approved: Yup.string().required("Field is required."),
      date_of_birth: Yup.date().nullable().required("Date is required"),
      passport_expiry_date: Yup.date().nullable().required("Date is required"),
      visa_expiry_date: Yup.date().nullable().required("Date is required"),
      emirates_id_expiry_date: Yup.date()
        .nullable()
        .required("Date is required"),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="mb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row my-3">
                    <FormikInputField
                      label="Owner Id"
                      name="owner_id"
                      placeholder="Enter Owner Id"
                    />
                    <FormikInputField
                      label="Owner Type"
                      name="owner_type"
                      placeholder="Enter Owner Type"
                    />
                    <FormikInputField
                      label="Email"
                      name="email"
                      placeholder="Enter Email"
                      type="email"
                    />
                    <FormikInputField
                      label="Password"
                      name="password"
                      placeholder="Enter Password"
                      type="password"
                    />
                    <FormikInputField
                      label="Emirates ID Front"
                      name="emirates_id_ront"
                      placeholder="Enter Emirates ID Front"
                    />
                    <FormikInputField
                      label="Emirates ID Back"
                      name="emirates_id_back"
                      placeholder="Enter Emirates ID Back"
                    />
                    <FormikInputField
                      label="Passport"
                      name="passport"
                      placeholder="Enter Passport"
                      type="file"
                    />
                    <FormikInputField
                      label="Visa"
                      name="visa"
                      placeholder="Enter Visa"
                      type="file"
                    />
                    <FormikInputField
                      label="Primary mobile number"
                      name="primary_no"
                      placeholder="Enter Primary mobile number"
                    />
                    <FormikInputField
                      label="Secondary mobile number"
                      name="primary_no"
                      placeholder="Enter Secondary mobile number"
                    />
                    <FormikInputField
                      label="Nationality"
                      name="nationality"
                      placeholder="Enter Nationality"
                    />
                    <FormikSelectField
                      label="Is Document to be shown to the Owner ?"
                      name="owner_document"
                      options={options[0]?.is_approved}
                    />
                    <FormikSelectField
                      label="Is Phone Number to be shown to the Owners ?"
                      name="owner_phone_no"
                      options={options[0]?.is_approved_document}
                    />
                    <FormikSelectField
                      label=" Is Approved ?"
                      name="is_approved"
                      options={options[0]?.is_approved_document}
                    />
                    <FormikDateField
                      label="Date Of Birth"
                      name="date_of_birth"
                    />
                    <FormikDateField
                      label="Passport Expiry Date"
                      name="passport_expiry_date"
                    />
                    <FormikDateField
                      label="Visa Expiry Date"
                      name="visa_expiry_date"
                    />
                    <FormikDateField
                      label="Emirates ID Expiry Date"
                      name="emirates_id_expiry_date"
                    />
                  </div>
                  <div className="text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/owners" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddOwner;
