import React, { useState } from "react";
import { DubizzleLeadsData } from "../../../asset/StastucJsonData/DubizzleLeadsJson";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";

const ListDubizzleLeads = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = DubizzleLeadsData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Sr",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Property Id",
      selector: (row) => row?.property_id,
      center: "true",
      wrap: true,
      // width: "200px",
    },
    {
      name: "Property Reference",
      selector: (row) => row?.property_refrance,
      center: "true",
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      center: "true",
      wrap: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.mobile,
      center: "true",
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      center: "true",
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row?.date,
      center: "true",
      wrap: true,
      width: "150px",
    },
  ];

  return (
    <>
      <div className="my-5">
        <div className="data-table-single-search-input-box-container">
          <input
            type="text"
            placeholder="Search..."
            className=""
            value={searchQuery}
            onChange={handleSearch}
          />
          <i className="fa fa-search"></i>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : DubizzleLeadsData}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default ListDubizzleLeads;
