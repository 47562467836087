import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const AddRole = () => {
  const initialValues = {
    agent: "",
    email: "",
    mobile_no: "",
    user_name: "",
    password: "",
    dob: "",
    team_leader: "",
    work_area: "",
  };

  const validationSchema = Yup.object()
    .shape({
      agent: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      mobile_no: Yup.string().required("Field is required."),
      user_name: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
      dob: Yup.string().required("Field is required."),
      team_leader: Yup.string().required("Field is required."),
      work_area: Yup.string().required("Field is required."),
    })
    .defined();

  const handleubmitForm = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleubmitForm}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row">
                    <FormikInputField
                      label="Role Name"
                      name="agent"
                      placeholder="Enter Role Name"
                    />
                    <FormikInputField
                      label="Description"
                      name="description"
                      placeholder="Enter Role Name"
                    />

                    <FormikSelectField
                      label="Status"
                      name="is_activate"
                      options={[]}
                      placeholder=" - Select status - "
                    />
                  </div>

                  <div className="text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/role" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddRole;
