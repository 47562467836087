import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import PublicFolderList from "./publicFolderList";
import HelmetComponent from "../../Common/HelmetComponent";

const PublicFolderPage = () => {
  return (
    <>
      <HelmetComponent title="Public-Folder" />
      <div className="container page-containners-main set-default-height-min">
        <CustomBreadcrumb
          title="Manage Public Folder"
          home="Dashbord"
          currentPage="Public Folder"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <PublicFolderList />
      </div>
    </>
  );
};

export default PublicFolderPage;
