import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FormikSubmitButton } from "../../../../Common/FormikInputFields/CommonFromikFields";
import { DragAndDropComponent } from "../../../../Common/DragAndDropSingleImage";
import CustomSelect from "../../../../Common/Select2Box/customSelectbox";
import Modal from "../../../../Common/Model/ModelCommon";
import { editDocumentvalidationSchema } from "../../constant";

const AddDocument = ({
  showAddModel,
  handleCloseAddModel,
  setShowAddModel,
}) => {
  const initialValues = {
    title: "",
    document_image: null,
    is_active: "",
  };

  const handleSubmitForm = (e) => {
    console.log("data", e);
    if (e) {
      setShowAddModel(false);
    }
  };

  return (
    <>
      {/* Add model */}
      <div className="model_inner">
        <Modal show={showAddModel} handleClose={handleCloseAddModel}>
          <div className="sdvds">
            <h5 className="mb-5 text-center">Add Document</h5>
            <Formik
              initialValues={initialValues}
              validationSchema={editDocumentvalidationSchema}
              validateOnChange
              onSubmit={handleSubmitForm}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="add-agent-form-containner">
                    <div className="row w-100 m-0 p-0">
                      <div className="col-12">
                        <p className="m-0">
                          Title<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter Title"
                          name="title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 ">
                        <p className="m-0">
                          Status <span className="text-danger">*</span>
                        </p>
                        <Field name="is_active">
                          {() => (
                            <CustomSelect
                              options={[
                                { _id: "1", option: "Yes" },
                                { _id: "2", option: "No" },
                              ]}
                              onChange={(name, value) =>
                                setFieldValue("is_active", value)
                              }
                              name="is_active"
                              placeholder="- Select  -"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="is_active"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 ">
                        <DragAndDropComponent name="document_image" />
                      </div>
                    </div>
                  </div>

                  <div className="text-center my-3">
                    <FormikSubmitButton />
                    <button
                      type="button"
                      className="mx-2 cancel-btn"
                      onClick={handleCloseAddModel}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddDocument;
