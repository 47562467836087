import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditAllLead from "./EditAllLead";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditAllLeadPage = () => {
  return (
    <>
      <HelmetComponent title="Edit-Leads" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Edit All Lead"
          home="Dashboard"
          currentPage="Edit All Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <EditAllLead />
      </div>
    </>
  );
};

export default EditAllLeadPage;
