import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  FormikInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
  FormikDateField,
} from "../../../../Common/FormikInputFields/CommonFromikFields";
import TinyMCEEditor from "../../../../Common/CKEditorComponent/index.jsx";
import { DragAndDropComponent } from "../../../../Common/DragAndDropSingleImage/index.jsx";
import CustomFormikSelect from "../../../../Common/CommonSelect2/index.jsx";
import MultipleImageUploader from "../../../../Common/DragAndDropMultipleImages/index.jsx";
import {
  editAllListingInitialValues,
  editAllListingValidationSchema,
} from "../../constant.js";
import { featuresData } from "../../../../../asset/StastucJsonData/allListingJson.jsx";

const CopyToSaleListPage = () => {
  const options = [
    { value: "option1", label: "Owner 1" },
    { value: "option2", label: "Owner 2" },
    { value: "option3", label: "Owner 3" },
    { value: "option4", label: "Owner 4" },
    { value: "option5", label: "Owner 5" },
    { value: "option6", label: "Owner 6" },
    { value: "option7", label: "Owner 7" },
    { value: "option8", label: "Owner 8" },
    { value: "option9", label: "Owner 9" },
    { value: "option10", label: "Owner 10" },
  ];

  const handleSubmitForm = (e) => {
    console.log("data", e);
  };

  return (
    <>
      <div>
        <div className="mb-4 border-bottom-1">
          Fieds Marked with (<span className="text-danger"> * </span>) are
          Mandatory
        </div>

        <Formik
          enableReinitialize
          initialValues={editAllListingInitialValues}
          validationSchema={editAllListingValidationSchema}
          validateOnChange
          onSubmit={handleSubmitForm}
        >
          {({ errors, touched }) => {
            return (
              <>
                <Form>
                  <div className="row">
                    <FormikSelectField
                      label={"Agent"}
                      name="agent"
                      options={[]}
                      placeholder={"Select Agent"}
                    />

                    <FormikSelectField
                      label={"Team Leader"}
                      placeholder="Select Team Leader"
                      name="team_leader"
                      options={[]}
                    />

                    <FormikSelectField
                      label="Associated Agente"
                      name="associated_agente"
                      options={[]}
                      placeholder={"Select Associated Agent"}
                    />

                    <FormikSelectField
                      label="Associated Team Leader"
                      name="associated_team_leader"
                      options={[]}
                      placeholder="Select Associated Team Leader"
                    />

                    <FormikSelectField
                      label="Off Plan"
                      name="off_plan"
                      options={[]}
                      placeholder="Select Off Plan"
                    />
                    <FormikSelectField
                      label="Completion Status"
                      name="completion_status"
                      options={[]}
                      placeholder="Select Completion Status"
                    />

                    <FormikSelectField
                      label="Offering Type"
                      name="offering_type"
                      options={[]}
                      placeholder="Select Offering Type"
                    />

                    <FormikSelectField
                      label="Category"
                      name="category"
                      options={[]}
                      placeholder="Select Category "
                    />
                    <FormikSelectField
                      label="Beds"
                      name="beds"
                      options={[]}
                      placeholder="Select Beds "
                    />
                    <FormikSelectField
                      label="Baths"
                      name="baths"
                      options={[]}
                      placeholder="Select Baths"
                    />

                    <FormikInputField
                      label="Transaction Number"
                      placeholder="Enter Transaction Number"
                      name="transaction_number"
                    />
                    <FormikInputField
                      label="Deed No"
                      placeholder="Enter Deed No"
                      name="deed_number"
                    />

                    <FormikSelectField
                      label="Emirates"
                      name="emirates"
                      options={[]}
                      placeholder="Select Emirates"
                    />

                    <FormikInputField
                      label="Project"
                      placeholder="Enter Project"
                      name="project"
                    />

                    <FormikSelectField
                      label="Location"
                      name="location"
                      options={[]}
                      placeholder="Select Location"
                    />

                    <FormikSelectField
                      label="Sublocation"
                      name="sub_location"
                      options={[]}
                      placeholder="Select Sublocation"
                    />
                    <FormikSelectField
                      label="Tenure"
                      name="tenure"
                      options={[]}
                      placeholder="Select Tenure"
                    />

                    <FormikSelectField
                      label="Is furnished ?"
                      name="is_furnished"
                      options={[]}
                      placeholder="Select Is furnished "
                    />
                    <FormikInputField
                      label="Unit No"
                      placeholder="Enter Unit No"
                      name="unit_no"
                    />
                    <FormikInputField
                      label="Type"
                      placeholder="Enter Type"
                      name="type"
                    />
                    <FormikInputField
                      label="Street"
                      placeholder="Enter Street"
                      name="street"
                    />
                    <FormikInputField
                      label="Floor"
                      placeholder="Enter Floor"
                      name="floor"
                    />
                    <FormikInputField
                      label="Area"
                      placeholder="Enter Area"
                      name="area"
                    />
                    <FormikInputField
                      label="Plot Area"
                      placeholder="Enter Plot Area"
                      name="plot_area"
                    />
                    <FormikInputField
                      label="View"
                      placeholder="Enter View"
                      name="view"
                    />
                    <FormikInputField
                      label="Parking"
                      placeholder="Enter Parking"
                      name="parking"
                    />
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                      <label>Owner</label>
                      <Field
                        name="owner"
                        component={CustomFormikSelect}
                        options={options}
                        isMulti={true}
                        isClearable={true}
                      />
                      <ErrorMessage
                        name="owner"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <FormikInputField
                      label="Property Title"
                      placeholder="Enter Property Title"
                      name="property_title"
                    />

                    <FormikInputField
                      label="Price"
                      placeholder="Enter Price"
                      name="price"
                    />
                    <FormikInputField
                      label="Commission"
                      name="commission"
                      placeholder="Enter Commission"
                    />

                    <FormikInputField
                      label="Deposit"
                      name="deposit"
                      placeholder="Enter Deposit"
                    />

                    <FormikSelectField
                      label="Property status"
                      name="property_status"
                      options={[]}
                      placeholder="Select Property status"
                    />
                    <FormikSelectField
                      label="Source of listing"
                      name="source_listing"
                      options={[]}
                      placeholder="Select Source of listing"
                    />

                    <FormikDateField
                      label="Expiration Date "
                      name="expiration_date"
                    />
                    <FormikSelectField
                      label="Featured"
                      name="featured"
                      placeholder="Enter Featured"
                      options={[]}
                    />
                    <FormikInputField
                      label="DEWA Number"
                      name="dewa_number"
                      placeholder="Enter DEWA Number"
                    />
                    <FormikInputField
                      label="STR"
                      name="str"
                      placeholder="Enter STR"
                    />
                    <FormikDateField
                      label="Next Available Date"
                      name="available_date"
                    />
                    <FormikSelectField
                      label="Remind"
                      name="remind"
                      placeholder="Enter Remind"
                      options={[]}
                    />
                    <FormikInputField
                      label="Key Location"
                      name="key_locations"
                      placeholder="Enter Key Location"
                    />
                    <FormikSelectField
                      label="Property Tenanted"
                      name="property_tenanted"
                      placeholder="Enter Property Tenanted"
                      options={[]}
                    />
                    <FormikInputField
                      label="Rented at"
                      name="rented_at"
                      placeholder="Enter Rented at"
                    />
                    <FormikDateField label="Rented Until" name="rented_until" />

                    <FormikInputField
                      label="Maintance Fee"
                      name="maintance_fee"
                      placeholder="Enter Maintance Fee"
                    />
                    <FormikSelectField
                      label="Managed"
                      name="managed"
                      placeholder="Enter Managed"
                      options={[]}
                    />
                    <FormikSelectField
                      label="Exclusive"
                      name="exclusive"
                      placeholder="Enter Exclusive"
                      options={[]}
                    />
                    <FormikSelectField
                      label="Invite"
                      name="invite"
                      placeholder="Enter Invite"
                      options={[]}
                    />
                    <FormikSelectField
                      label="POA"
                      name="poa"
                      placeholder="Enter POA"
                      options={[]}
                    />

                    <FormikSelectField
                      label="Status"
                      name="status"
                      placeholder="Enter Status"
                      options={[]}
                    />
                    <div className="col-lg-12 mt-4 mb-4">
                      <p className="m-0">Description</p>
                      <Field name="description" component={TinyMCEEditor} />
                    </div>
                    <div className="col-12 mb-2 border border-gray">
                      <p className="m-0 fw-bold">Multiple Photos</p>
                      <Field
                        name="photo_image"
                        component={MultipleImageUploader}
                      />
                      <ErrorMessage
                        name="photo_image"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-12 mb-2 border border-gray">
                      <p className="m-0 fw-bold"> Photos</p>
                      <Field
                        name="photo_image"
                        component={MultipleImageUploader}
                      />
                      <ErrorMessage
                        name="photo_image"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-12 mb-2 border border-gray mt-3">
                      <p className="m-0 fw-bold">Select Features :</p>
                      <div className="row w-100 py-2 px-5 feature__containner">
                        {/* Add map loop... */}
                        {featuresData.map((feature, index) => (
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 my-3"
                            key={index}
                          >
                            <div className="feature_main_containner ">
                              <Field
                                className="feature_chekboxs"
                                type="checkbox"
                                name="features"
                                value={feature?.value}
                              />
                              <p className="m-0">{feature?.label}</p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <ErrorMessage
                        name="features"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-12 mb-2 border border-gray mt-3">
                      <p className="m-0 fw-bold">Select Portals :</p>
                      <div className="row w-100 py-2 px-5 feature__containner">
                        {/* Add map loop... */}
                        <div className="col-lg-3 col-md-3 col-sm-6 my-3 ">
                          <div className="feature_main_containner ">
                            <Field
                              className="feature_chekboxs"
                              type="checkbox"
                              name="portals"
                              value="Property"
                            />
                            <p className="m-0">Property</p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 my-3 ">
                          <div className="feature_main_containner ">
                            <Field
                              className="feature_chekboxs"
                              type="checkbox"
                              name="portals"
                              value="Bayut & Dubizzle"
                            />
                            <p className="m-0">Bayut & Dubizzle</p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 my-3 ">
                          <div className="feature_main_containner ">
                            <Field
                              className="feature_chekboxs"
                              type="checkbox"
                              name="portals"
                              value="Binayah Website"
                            />
                            <p className="m-0">Binayah Website</p>
                          </div>
                        </div>
                      </div>

                      <ErrorMessage
                        name="portals"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                      <DragAndDropComponent
                        label="Floor Plans"
                        name="floor_plans"
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 com-sm-12 mb-2">
                      <div className="d-flex gap-2 align-items-center">
                        <p className="m-0">Watermark : </p>
                        <Field
                          className="feature_chekboxs"
                          type="checkbox"
                          placeholder="Email"
                          name="watermark"
                        />
                      </div>

                      <ErrorMessage
                        name="watermark"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-12">
                      <p className="fw-bold">Other Media :</p>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                          <p className="">Youtube Link</p>
                          <Field
                            className="form-control rounded-0"
                            type="text"
                            name="youtube_link"
                            placeholder="Youtube Link"
                          />
                          <ErrorMessage
                            name="youtube_link"
                            className="text-danger"
                            component="div"
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                          <DragAndDropComponent name="floor_plans" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center my-5">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/all-listing" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CopyToSaleListPage;
