import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomSelect from "../../../Common/Select2Box/customSelectbox.jsx";
import { customStyles } from "../../../Common/DataTableStyle/dataTableStyle.jsx";
import { filterListdata } from "../../../../asset/StastucJsonData/allListingJson.jsx";
import { dataTableListData } from "../../../../asset/StastucJsonData/allListingJson.jsx";
import CommonDateRangePicker from "../../../Common/DateRangePicker/index.jsx";
import { SuccessToast } from "../../../Common/SweetToastAlert/index.jsx";
import OtpModal from "../../../Common/OtpModel/index.jsx";

const ListAllListing = () => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [sendOtpModel, setSendOtpModel] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [loading, setLoading] = useState(true);

  const filterListdatas = filterListdata[0] || [];

  const handleSelectChange = (e) => {
    setSelectedValue(e);
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = dataTableListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Status",
      width: "80px",
      cell: (row) => (
        <div className="text-center">
          <i className="fa fa-circle-check text-success" title="Published"></i>
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Portal Status",
      width: "110px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px"} src="/svg/pf_active.png" alt="img" />
          <img width={"20px"} src="/svg/dubzille_active.png" alt="img" />
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Updated at",
      selector: (row) => row?.updated_at,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Ref. No.",
      width: "100px",
      selector: (row) => row?.ref_no,
      center: "true",
      wrap: true,
    },
    {
      name: "Unit",
      width: "100px",
      selector: (row) => row?.unit,
      center: "true",
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Location",
      selector: (row) => row?.location,
      center: "true",
      wrap: true,
    },
    {
      name: "S-Location",
      selector: (row) => row?.s_location,
      center: "true",
      wrap: true,
    },
    {
      name: "Beds",
      width: "80px",
      selector: (row) => row?.beds,
      center: "true",
      wrap: true,
    },
    {
      name: "Price",
      width: "100px",
      selector: (row) => row?.price,
      center: "true",
      wrap: true,
    },
    {
      name: "Agent",
      selector: (row) => row?.agent,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "A.E. Status",
      width: "100px",
      cell: (row) => (
        <div className="text-center">
          <img width={"20px"} src="/svg/approve.png" alt="img" />
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "D&C",
      width: "100px",
      cell: (row) => (
        <div className="text-center">
          <Link to={`/all-listing/document/${row?.user_id}`}>
            <img className="mr-2" width={"20px"} src="/svg/doc.png" alt="img" />
          </Link>
          <Link to={`/all-listing/cheque/${row?.user_id}`}>
            <img width={"20px"} src="/svg/cheque.png" alt="img" />
          </Link>
        </div>
      ),
      center: "true",
      wrap: true,
    },

    {
      name: "Duplicate",
      width: "90px",
      cell: (row) => (
        <div className="text-center">
          <Link to={`/all-listing/rental/${row?.user_id}`}>
            <img
              className="mr-2"
              width={"20px"}
              src="/svg/rental.png"
              alt="img"
            />
          </Link>
          <Link to={`/all-listing/sale/${row?.user_id}`}>
            <img width={"20px"} src="/svg/sale.png" alt="img" />
          </Link>
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "PDF&JPG",
      width: "80px",
      cell: (row) => (
        <div className="text-center">
          <Link to={`/pdf/${row.user_id}`}>
            <img
              className="mr-2 cursor-pointer"
              width={"20px"}
              src="/svg/file.png"
              alt="img"
            />
          </Link>
          <img width={"20px"} src="/svg/jpg.png" alt="img" />
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Edit",
      width: "50px",
      cell: (row) => (
        <>
          <Link to={`/edit-all-listing/${row?.user_id}`}>
            <div className="text-center cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="img" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
    },
  ];

  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  const handleOtpCloseModel = () => {
    setSendOtpModel(false);
  };

  const handleOtpSubmitOtpForm = (otp) => {
    if (otp === "1234") {
      console.log("OTP Verified");
      setSendOtpModel(false);
    } else {
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  const handleResendOtp = () => {
    console.log("OTP Resent");
  };

  useEffect(() => {
    if (loading) {
      SuccessToast("All listing page");
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  console.log("selectedDates", selectedDates);

  return (
    <>
      <div className="all-list-main-containner">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CommonDateRangePicker onApply={handleDateRangeApply} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_agent}
              placeholder={"Filter By Agent"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_status}
              placeholder={"Filter By Status"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_portal}
              placeholder={"Filter By Portal"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_property}
              placeholder={"Filter By Property"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_bedrooms}
              placeholder={"Filter By Bedrooms"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_emitares}
              placeholder={"Filter By Emirates"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_location}
              placeholder={"Filter By Location"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_sublocation}
              placeholder={"Filter By Sublocation"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_project}
              placeholder={"Filter By Project"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_price_range}
              placeholder={"Filter By Price Range"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_ref_no}
              placeholder={"Filter By Ref. No."}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.filter_by_category}
              placeholder={"Filter By Category"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <input
              type="number"
              id="propunitno"
              name="propunitno"
              placeholder="Search Unit No."
              className="custom-select unit-number-list"
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <input
              type="number"
              id="searchtrans"
              name="searchtrans"
              placeholder="Search Transaction No."
              className="custom-select unit-number-list"
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.select_agent}
              placeholder={"Select Agent"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={filterListdatas.select_status}
              placeholder={"Select Status"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <button
              type="button"
              className="btn filter_button listing_button custom-select unit-number-list rounded-0"
            >
              <img
                className="mx-2"
                src="/svg/update_field_icon.png"
                width="25px"
                alt="images"
              />
              Update Fields
            </button>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2 ">
            <button
              type="button"
              className="btn filter_button listing_button custom-select unit-number-list rounded-0"
              onClick={() => setSendOtpModel(true)}
            >
              <img
                className="mx-2"
                src="/svg/csv_button_icon.png"
                width="25px"
                alt="images"
              />
              Export To CSV
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex datatable-header-main">
        <Link to="/add-all-listing">
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="20px"
              alt="images"
            />
            Add Listing
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>
      <div className="datatable-containner">
        <DataTable
          data={filteredData ? filteredData : dataTableListData}
          noDataComponent="No data available"
          customStyles={customStyles}
          highlightOnHover
          noHeader={false}
          persistTableHead
          columns={column}
          pagination
          searchable
          responsive
          fixedHeader={true}
        />
      </div>

      <OtpModal
        isOpen={sendOtpModel}
        onClose={handleOtpCloseModel}
        onSubmit={handleOtpSubmitOtpForm}
        resendOtp={handleResendOtp}
      />
      {otpError && <p className="text-danger">{otpError}</p>}
    </>
  );
};

export default ListAllListing;
