import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { developerListData } from "../../../asset/StastucJsonData/developerLogoJsonData";
import Modal from "../../Common/Model/ModelCommon";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { DragAndDropComponent } from "../../Common/DragAndDropSingleImage";

const ProjectList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getIdWiseEditData, setGetIdWiseEditData] = useState([]);
  const [getId, setId] = useState();

  const initialValues = {
    title: "",
    img: "",
  };

  const initialValuesEdit = {
    title: getIdWiseEditData?.title || "",
    img: getIdWiseEditData?.image_url || "",
  };

  const validationSchema = Yup.object()
    .shape({
      title: Yup.string().required("Field is required."),
      img: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log(e);
    setShowAddModel(false);
  };

  const handleSubmitEditForm = (e) => {
    console.log(e);
    setShowEditModel(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = developerListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  const getIdWiseData = (id) => {
    const findData = developerListData.find((item) => id === item?._id);
    setGetIdWiseEditData(findData);
  };

  useEffect(() => {
    getIdWiseData(getId);
  }, [getId]);

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "title",
      selector: (row) => row.title || " - ",
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Image",
      cell: (row) => (
        <>
          <div className="p-3">
            <img
              width={"100px"}
              height={"100px"}
              src={row?.image_url}
              alt="imgs"
              className="rounded-circle"
            />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      minWidth: "150px",
      width: "200px",
    },
    // {
    //   name: "Unit Model",
    //   cell: (row) => (
    //     <>
    //       <Link to={`/unit-model/${row?._id}`}>
    //         <div className="cursor-pointer">
    //           <img width={"40px;"} src="/svg/units.png" alt="img-pen" />
    //         </div>
    //       </Link>
    //     </>
    //   ),
    //   center: "true",
    //   wrap: true,
    //   width: "150px",
    // },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <div
            className="cursor-pointer"
            onClick={() => {
              setId(row?._id);
              setShowEditModel(true);
            }}
          >
            <img width={"20px;"} src="/svg/pencil.png" alt="img-pen" />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <img
            className="mx-2"
            src="/svg/pluse_icon_button.png"
            width="20px"
            alt="images"
          />
          Add Project
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <div className="data-table-cotainner-project">
        <DataTable
          data={filteredData ? filteredData : developerListData}
          customStyles={customStyles}
          columns={column}
          noHeader={false}
          highlightOnHover
          persistTableHead
          pagination
          searchable
          responsive
          noDataComponent="No data available"
        />
      </div>

      {/* Add project model */}

      <div className="model_inner">
        <Modal show={showAddModel} handleClose={handleCloseAddModel}>
          <div className="sdvds">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >
              {() => {
                return (
                  <>
                    <Form>
                      <h5 className="mb-5">Add Project</h5>
                      <div className="row">
                        <div className="col-12 w-100 mb-2">
                          <p className="m-0 fw-bold">Title </p>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="title"
                          />
                          <ErrorMessage
                            name="title"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 w-100 mb-2">
                          <DragAndDropComponent name="img" />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-secondary">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal>
      </div>

      {/* Edit project model */}

      <div className="model_inner">
        <Modal show={showEditModel} handleClose={handleCloseEditModel}>
          <div className="sdvds">
            <Formik
              initialValues={initialValuesEdit}
              validationSchema={validationSchema}
              onSubmit={handleSubmitEditForm}
            >
              {() => {
                return (
                  <>
                    <Form>
                      <h5 className="mb-5">Edit Developer Logo</h5>
                      <div className="row">
                        <div className="col-12 w-100 mb-1">
                          <p className="m-0 fw-bold">Title </p>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="title"
                          />
                          <ErrorMessage
                            name="title"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>

                        <div className="col-12 w-100 mb-2">
                          <DragAndDropComponent name="img" />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-secondary">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ProjectList;
