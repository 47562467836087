import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { tenantsListData } from "../../../asset/StastucJsonData/TenantsJsonData";
import { Link } from "react-router-dom";
import CommonDateRangePicker from "../../Common/DateRangePicker";
import OtpModal from "../../Common/OtpModel";

const OwnerList = () => {
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAgentLeadId, setSelectAgentLeadId] = useState("1");
  const [perPage, setPerPage] = useState(10);
  const [sendOtpModel, setSendOtpModel] = useState(false);
  const [otpError, setOtpError] = useState("");

  var rowsPerPage = 10;

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = tenantsListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleRefNoClick = (row) => {
    setSelectAgentLeadId(row._id);
  };

  console.log(selectAgentLeadId);

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  // Handle change page for data table functionality...

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const pageRows = tenantsListData.slice(
      (page - 1) * perPage,
      page * perPage
    );
    const allPageRowsSelected = pageRows.every((row) =>
      selectedRowIds.includes(row._id)
    );
    setSelectAll(allPageRowsSelected);
  };

  const handleRowCheckboxChange = (rowId) => {
    const isSelected = selectedRowIds.includes(rowId);
    const newSelectedRowIds = isSelected
      ? selectedRowIds.filter((id) => id !== rowId)
      : [...selectedRowIds, rowId];

    setSelectedRowIds(newSelectedRowIds);
    setSelectAll(newSelectedRowIds.length === tenantsListData.length);
  };

  const handleSelectAllChange = () => {
    const pageRows = tenantsListData.slice(
      (currentPage - 1) * perPage,
      currentPage * perPage
    );
    const pageRowIds = pageRows.map((row) => row._id);

    if (selectAll) {
      setSelectedRowIds(
        selectedRowIds.filter((id) => !pageRowIds.includes(id))
      );
      setSelectAll(false);
    } else {
      setSelectedRowIds([
        ...selectedRowIds,
        ...pageRowIds.filter((id) => !selectedRowIds.includes(id)),
      ]);
      setSelectAll(true);
    }
  };

  const handleOtpCloseModel = () => {
    setSendOtpModel(false);
  };

  const handleOtpSubmitOtpForm = (otp) => {
    if (otp === "1234") {
      console.log("OTP Verified");
      setSendOtpModel(false);
    } else {
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  const handleResendOtp = () => {
    console.log("OTP Resent");
  };

  const column = [
    {
      name: (
        <div className="text-center">
          {/* <span>Sr.</span> */}
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      cell: (row) => (
        <div className="text-center">
          <input
            type="checkbox"
            checked={selectedRowIds.includes(row._id)}
            onChange={() => handleRowCheckboxChange(row._id)}
          />
        </div>
      ),
      width: "100px",
      center: "true",
      wrap: true,
    },
    {
      name: "Owner Id",
      selector: (row) => row?.Reference,
      center: "true",
      wrap: true,
    },
    {
      name: "Owner Name",
      selector: (row) => row?.["Tenant/Buyer_Name"],
      center: "true",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.["Tenant/Buyer_Email"],
      center: "true",
      wrap: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.["Tenant/Buyer_Mobile"],
      center: "true",
      wrap: true,
    },
    {
      name: "Nationality",
      selector: (row) => row?.["Tenant/Buyer_Nationality"],
      center: "true",
      wrap: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row?.Agent1,
      center: "true",
      wrap: true,
    },
    {
      name: "Propertys",
      cell: (row) => (
        <>
          <Link to={`/owners-show-property/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"25px"} src="/svg/house.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/edit-owners/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
    },
  ];

  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  console.log("selectedDatesaaaaa", selectedDates);

  return (
    <>
      <div className="all-list-main-containner my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CommonDateRangePicker onApply={handleDateRangeApply} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <button
              type="button"
              className="btn filter_button listing_button px-2 custom-select rounded-0"
              style={{ height: "36px" }}
              onClick={() => setSendOtpModel(true)}
            >
              <img
                className="mx-2"
                src="/svg/csv_button_icon.png"
                width="25px"
                alt="images"
              />
              Export To CSV
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex datatable-header-main">
        <Link to="/add-owners">
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="20px"
              alt="images"
            />
            Add New Owner
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <div className="mt-2 mb-2">
        <DataTable
          data={filteredData ? filteredData : tenantsListData}
          noDataComponent="No data available"
          customStyles={customStyles}
          onRowClicked={handleRefNoClick}
          highlightOnHover
          noHeader={false}
          persistTableHead
          columns={column}
          pagination
          searchable
          responsive
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={filteredData.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationPerPage={(perPage, rowsPerPage)}
        />
      </div>
      <OtpModal
        isOpen={sendOtpModel}
        onClose={handleOtpCloseModel}
        onSubmit={handleOtpSubmitOtpForm}
        resendOtp={handleResendOtp}
      />
      {otpError && <p className="text-danger">{otpError}</p>}
    </>
  );
};

export default OwnerList;
