import React from "react";
import "../../../asset/css/pages/tableComponent.css";

const TableComponent = () => {
  return (
    <>
      {/* <div className="container-fluid p-0 mb-3 mt-4">
        <div className="row">
          <div className="col-lg-4 col-sm-6 align-items-center d-flex my-2">
            <select
              className="form-select  select_box_main"
              aria-label="Default select "
            >
              <option value="lead PipeLIne">Leads Pipeline</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="col-lg-8 col-sm-12 my-2">
            <div className="row">
              <div className="d-flex justify-content-end column-gap-2 dashbord-date-filter-btns">
                <div className="wrapper">
                  <label htmlFor="datepicker" className="d-flex px-1">
                    <img
                      width="25px"
                      className="me-1"
                      src="/svg/calendar.png"
                      alt="img"
                    />
                    <input type="date" id="datepicker" autoComplete="off" />
                  </label>
                </div>
                <div>
                  <button type="button" className="btn filter_button">
                    <i className="fa fa-filter me-2"></i>
                    Filters
                  </button>
                </div>
                <div>
                  <button type="button" className="btn filter_button">
                    <i className="fa fa-file-pdf me-2"></i>
                    Generate PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid p-0 mb-5">
        <div className="row dashbord-list-pipeline">
          <div className="col">
            <div className="task-list firest_notconect_main">
              <div className="card_header firest_notconect">
                <h6>Not yet Contacted</h6>
              </div>
              <div className="list">
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg not_conected_box"></div>
                      <div className="ag-courses-item_title">
                        <span>Open - Not Yet Contacted</span> <br />{" "}
                        <span>Source - Bayut Deena</span>
                        <br /> <span>29th July 2024</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg not_conected_box"></div>
                      <div className="ag-courses-item_title">
                        <span>Open - Not Yet Contacted</span> <br />
                        <span>Source - Propertyfinder </span>
                        <br />
                        <span>28th July 2024</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg not_conected_box"></div>
                      <div className="ag-courses-item_title">
                        <span>Open - Not Yet Contacted</span>
                        <br />
                        <span>Source - Dubizzle Zarek </span>
                        <br />
                        <span>28th July 2024</span>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg not_conected_box"></div>
                      <div className="ag-courses-item_title">
                        <span> Open - Not Yet Contacted </span>
                        <br />
                        <span> Source - Bayut Catalin</span>
                        <br />
                        <span>28th July 2024</span>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="task-list notcontacted_main">
              <div className="card_header notcontacted">
                <h6>In Contact</h6>
              </div>
              <div className="list">
                <div className="ag-format-container">
                  <div className="ag-courses_box">
                    <div className="ag-courses_item">
                      <div className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>
                        <div className="ag-courses-item_title text-center change-text-color-yellow">
                          No Leads Record
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="task-list mian_color_box">
              <div className="card_header">
                <h6>Viewings/ Meetings</h6>
              </div>
              <div className="list">
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg meetings_box"></div>
                      <div className="ag-courses-item_title text-center change-text-color-green">
                        No Leads Record
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="task-list closed_Won_main">
              <div className="card_header closed_Won">
                <h6>Closed Won</h6>
              </div>
              <div className="list">
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg won"></div>
                      <div className="ag-courses-item_title">
                        <span>Open - Successful</span>
                        <br />
                        <span>Source - Other Online</span>
                        <br />
                        <span>Trisha De Guzman </span>
                        <br />
                        <span>26th April 2024 </span>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="task-list closed_lost_main">
              <div className="card_header closed_lost">
                <h6>Closed Lost</h6>
              </div>
              <div className="list">
                <div className="ag-courses_box">
                  <div className="ag-courses_item">
                    <div className="ag-courses-item_link">
                      <div className="ag-courses-item_bg lost"></div>
                      <div className="ag-courses-item_title text-center change-text-color-red">
                        No Leads Record
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableComponent;
