import React, { useEffect, useRef, useState } from "react";
import "../../../asset/css/common/customeSelectbox.css";

const CustomSelect = ({ options, placeholder, value, onChange, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedOptions, setDisplayedOptions] = useState(10);
  const [loadingState, setLoadingState] = useState(false);
  const selectRef = useRef(null);
  const scrollRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(name, option);
    setIsOpen(false);
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setDisplayedOptions(10);
  };

  const filteredOptions = options
    ?.filter((option) =>
      option?.option.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
    ?.slice(0, displayedOptions);

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target) && isOpen) {
      setIsOpen(false);
    }
  };

  const handleClearSelection = () => {
    setSearchTerm("");
  };

  const handleClearSelectionSelected = (e) => {
    e.stopPropagation();
    setSelectedOption(null);
    onChange(name, null);
    setSearchTerm("");
    setIsOpen(false);
  };

  const loadMoreItems = () => {
    if (loadingState) return;
    setLoadingState(true);
    setTimeout(() => {
      setDisplayedOptions((prev) => prev + 10);
      setLoadingState(false);
    }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        scrollRef.current &&
        scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
          scrollRef.current.scrollHeight - 20 &&
        displayedOptions < options.length
      ) {
        loadMoreItems();
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadingState, displayedOptions, options.length]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="custom-select col-sm-6 col-md-6 col-lg-2 mb-2"
      ref={selectRef}
    >
      <div
        className={`select-header d-flex align-items-center justify-content-between ${
          selectedOption ? "" : "text-secondary"
        }`}
        onClick={toggleDropdown}
      >
        {selectedOption || placeholder}

        {selectedOption ? (
          <i
            className="fa fa-close is-clean-select-box-selected"
            onClick={handleClearSelectionSelected}
          ></i>
        ) : (
          ""
        )}

        {isOpen ? (
          <i className="fa fa-angle-up clear-icon"></i>
        ) : (
          <i className="fa fa-angle-down clear-icon"></i>
        )}
      </div>
      <div
        className={`select-body ${isOpen ? "open" : ""}`}
        ref={scrollRef}
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        <div className="show-search-box-clear-icon d-flex align-items-center">
          {searchTerm && (
            <i
              className="fa fa-close is-clean-select-box"
              onClick={handleClearSelection}
            ></i>
          )}
          <input
            type="text"
            className="select-search form-control"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <ul className="select-options border-none all-lead-add-select-box-style select_box_main list-unstyled">
          {filteredOptions?.length ? (
            filteredOptions?.map((option, index) => (
              <li
                key={index}
                className="select-option select-option-value"
                onClick={() => handleOptionClick(option?.option)}
              >
                {option?.option}
              </li>
            ))
          ) : (
            <li className="select-options select-option-value-no-data-option">
              No options available
            </li>
          )}
        </ul>
        {loadingState && <p className="loading">loading More Options...</p>}
      </div>
    </div>
  );
};

export default CustomSelect;
