export const viewListData = [
  {
    property_refernce: "BY-R-13522",
    lead_refernce: "BY-R-135AS",
    location: "Mudon",
    sublocation: "Mudon Al Ranim 1",
    unitno: "A7-35",
    owners: "Prajeesh",
    client_name: "shee",
    client_email: "0522754928",
    client_mobile: "admin1@binayah.com",
    viewing_status: "happy",
    agent_comment: "happy",
    created_by: "65361a74ebbf469f5eb9450d",
    _id: "670e7716818d18ecc77ca2ff",
    created_at: "2024-10-15T14:07:18.431Z",
    __v: 0,
    createdBy: "Prajeesh",
  },
  {
    property_refernce: "BY-R-13904",
    lead_refernce: "BY-R-135AS",
    location: "Al Bithnah",
    sublocation: "Al Falaj",
    unitno: "220",
    owners: "Trisha",
    client_name: "Trisha",
    client_email: "+917510608234",
    client_mobile: "pm@binayah.com",
    viewing_status: "happy",
    agent_comment: "Test happy",
    created_by: "5f84497666cad61f436d934b",
    _id: "670cdc0a6aeb187c060f9820",
    created_at: "2024-10-14T08:53:30.683Z",
    __v: 0,
    createdBy: "Trisha",
  },
  {
    property_refernce: "BY-R-13867",
    lead_refernce: "BY-R-135AS",
    location: "Business Bay",
    sublocation: "The Prism",
    unitno: "1508",
    owners: "Junaid Jawed Mirza",
    client_name: "Sheena",
    client_email: "+971505146584",
    client_mobile: "info@binayah.com",
    viewing_status: "happy",
    agent_comment: "Done viewing, the client is happy.",
    created_by: "6475978cd101050fd17837ee",
    _id: "670e6d67818d18ecc778a22d",
    created_at: "2024-10-15T13:25:59.131Z",
    __v: 0,
    createdBy: "Junaid Jawed Mirza",
  },
  {
    property_refernce: "BY-R-13522",
    lead_refernce: "BY-R-135AS",
    location: "Mudon",
    sublocation: "Mudon Al Ranim 1",
    unitno: "A7-35",
    owners: "Prajeesh",
    client_name: "shee",
    client_email: "0522754928",
    client_mobile: "admin1@binayah.com",
    viewing_status: "happy",
    agent_comment: "happy",
    created_by: "65361a74ebbf469f5eb9450d",
    _id: "670e7716818d18ecc77ca2ff",
    created_at: "2024-10-15T14:07:18.431Z",
    __v: 0,
    createdBy: "Prajeesh",
  },
  {
    property_refernce: "BY-R-13904",
    lead_refernce: "BY-R-135AS",
    location: "Al Bithnah",
    sublocation: "Al Falaj",
    unitno: "220",
    owners: "Trisha",
    client_name: "Trisha",
    client_email: "+917510608234",
    client_mobile: "pm@binayah.com",
    viewing_status: "happy",
    agent_comment: "Test happy",
    created_by: "5f84497666cad61f436d934b",
    _id: "670cdc0a6aeb187c060f9820",
    created_at: "2024-10-14T08:53:30.683Z",
    __v: 0,
    createdBy: "Trisha",
  },
  {
    property_refernce: "BY-R-13867",
    lead_refernce: "BY-R-135AS",
    location: "Business Bay",
    sublocation: "The Prism",
    unitno: "1508",
    owners: "Junaid Jawed Mirza",
    client_name: "Sheena",
    client_email: "+971505146584",
    client_mobile: "info@binayah.com",
    viewing_status: "happy",
    agent_comment: "Done viewing, the client is happy.",
    created_by: "6475978cd101050fd17837ee",
    _id: "670e6d67818d18ecc778a22d",
    created_at: "2024-10-15T13:25:59.131Z",
    __v: 0,
    createdBy: "Junaid Jawed Mirza",
  },
  {
    property_refernce: "BY-R-13522",
    lead_refernce: "BY-R-135AS",
    location: "Mudon",
    sublocation: "Mudon Al Ranim 1",
    unitno: "A7-35",
    owners: "Prajeesh",
    client_name: "shee",
    client_email: "0522754928",
    client_mobile: "admin1@binayah.com",
    viewing_status: "happy",
    agent_comment: "happy",
    created_by: "65361a74ebbf469f5eb9450d",
    _id: "670e7716818d18ecc77ca2ff",
    created_at: "2024-10-15T14:07:18.431Z",
    __v: 0,
    createdBy: "Prajeesh",
  },
  {
    property_refernce: "BY-R-13904",
    lead_refernce: "BY-R-135AS",
    location: "Al Bithnah",
    sublocation: "Al Falaj",
    unitno: "220",
    owners: "Trisha",
    client_name: "Trisha",
    client_email: "+917510608234",
    client_mobile: "pm@binayah.com",
    viewing_status: "happy",
    agent_comment: "Test happy",
    created_by: "5f84497666cad61f436d934b",
    _id: "670cdc0a6aeb187c060f9820",
    created_at: "2024-10-14T08:53:30.683Z",
    __v: 0,
    createdBy: "Trisha",
  },
  {
    property_refernce: "BY-R-13867",
    lead_refernce: "BY-R-135AS",
    location: "Business Bay",
    sublocation: "The Prism",
    unitno: "1508",
    owners: "Junaid Jawed Mirza",
    client_name: "Sheena",
    client_email: "+971505146584",
    client_mobile: "info@binayah.com",
    viewing_status: "happy",
    agent_comment: "Done viewing, the client is happy.",
    created_by: "6475978cd101050fd17837ee",
    _id: "670e6d67818d18ecc778a22d",
    created_at: "2024-10-15T13:25:59.131Z",
    __v: 0,
    createdBy: "Junaid Jawed Mirza",
  },
];
