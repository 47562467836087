import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddAllListing from "./AddAllListing";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddAllListingPage = () => {
  return (
    <>
      <HelmetComponent title="Add-Listing" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add All Listing"
          home="Dashboard"
          currentPage="Add All Listing"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <AddAllListing />
      </div>
    </>
  );
};

export default AddAllListingPage;
