// import React, { useEffect, useRef, useState } from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import { useNavigate, useParams } from "react-router-dom";
// import Loader from "../CommonLoader";

// const PDFPage = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const contentRef = useRef(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     generatePDF(id);
//   }, [id]);

//   const rowData = {
//     user_id: id,
//     ref_no: "BY-S-13834",
//     unit: "1 bed apartment",
//     location: "La Residence, Jumeirah Village Triangle, Dubai",
//     builtUpArea: "600.73",
//     price: "AED 800,000",
//     furnished: "Fully Furnished",
//     status: "Vacant",
//     bedrooms: "1 Bedroom",
//     amenities: "Nice Location",
//     images: [
//       "/svg/The_Spirit_9.jpg",
//       "/svg/The_Spirit_11.jpg",
//       "/svg/hole_img_1.jpeg",
//       "/svg/hole_img_2.jpeg",
//       "/svg/hole_img_3.jpeg",
//       "/svg/hole_img_4.jpeg",
//       "/svg/hole_img_5.jpeg",
//     ],
//   };

//   const generatePDF = async (id) => {
//     const doc = new jsPDF({
//       orientation: "portrait",
//       unit: "mm",
//       format: "a4",
//     });

//     // Capture the content as a canvas
//     const canvas = await html2canvas(contentRef.current);
//     const imgData = canvas.toDataURL("image/png");

//     const pageHeight = doc.internal.pageSize.height;
//     const imgHeight = (canvas.height * 190) / canvas.width;
//     let heightLeft = imgHeight; // Total height of the image
//     let position = 40; // Start position after header -

//     // Add the first page with the header and image
//     addHeader(doc);
//     doc.addImage(imgData, "PNG", 10, position, 190, imgHeight);
//     addFooter(doc); // Add footer to the first page
//     heightLeft -= pageHeight - position; // Reduce the height left

//     // Create additional pages if there's remaining content
//     while (heightLeft > 0) {
//       doc.addPage();
//       addHeader(doc); // Add header to each new page
//       position = 30; // Adjust position for a new page, leaving space for header
//       doc.addImage(imgData, "PNG", 10, position, 190, imgHeight);
//       addFooter(doc); // Add footer to each new page
//       heightLeft -= pageHeight - position; // Decrease height left for next iteration-
//     }

//     // Finalize the PDF and generate a blob
//     const pdfBlob = doc.output("blob");
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     window.open(pdfUrl);

//     // Hide loader after generating the PDF
//     setLoading(false);

//     // Redirect after a short delay
//     setTimeout(() => {
//       navigate("/all-listing");
//     }, 2000);
//   };

//   const addHeader = (doc) => {
//     doc.addImage("/svg/pdflogo.jpg", "JPG", 170, 3, 30, 25);
//     doc.setFontSize(14);
//     doc.setTextColor("#000000");
//     doc.setFont("Helvetica", "bold");
//     doc.text(`${rowData.unit} For Sale - Ref: ${rowData.ref_no}`, 10, 20);
//     doc.setLineWidth(0.5);
//     doc.setDrawColor(51, 122, 183);
//     doc.line(10, 30, 200, 30); // Add a line as a separator
//   };

//   const addFooter = (doc) => {
//     const footerText = `Call Binayah Support on +97144475431 or email crm3@binayah.com to view this property.\nBinayah Properties L.L.C | www.binayah.com | info@binayah.com | 04 447 5431`;
//     const pageCount = doc.internal.getNumberOfPages();
//     const yPosition = 290; // Position for the footer at the bottom

//     for (let i = 1; i <= pageCount; i++) {
//       doc.setPage(i);
//       doc.setFillColor("#3070b6");
//       doc.rect(0, yPosition - 5, 210, 20, "F");
//       doc.setFontSize(10);
//       doc.setTextColor(255, 255, 255);
//       doc.setFont("Helvetica", "normal");
//       doc.text(footerText, 10, yPosition, { lineHeightFactor: 1.5 });
//     }
//   };

//   return (
//     <div>
//       {loading && <Loader />}

//       <div ref={contentRef} style={{ padding: "10px", fontFamily: "Arial" }}>
//         <div
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             width: "100%",
//             margin: "10px 0 30px 0",
//             color: "#3070b6",
//           }}
//         >
//           <div style={{ fontSize: "24px", flex: "1 1 60%" }}>
//             <h3>{rowData.location}</h3>
//             <h3>Built-up area: {rowData.builtUpArea}</h3>
//           </div>
//           <div style={{ textAlign: "right", flex: "1 1 40%" }}>
//             <h3>{rowData.price}</h3>
//           </div>
//         </div>

//         <div
//           style={{
//             fontSize: "26px",
//             margin: "10px 0 30px 0",
//             fontWeight: "600",
//           }}
//         >
//           <span>{rowData.furnished} | </span>
//           <span>{rowData.status} | </span>
//           <span>{rowData.amenities}</span>
//         </div>

//         <div
//           style={{
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             gap: "50px",
//           }}
//         >
//           {rowData.images.map((image, index) => (
//             <img
//               key={index}
//               src={image}
//               alt={`Property ${index + 1}`}
//               style={{ width: "100%", maxWidth: "100%", height: "auto" }}
//             />
//           ))}
//         </div>
//       </div>

//       <div style={{ textAlign: "center", marginTop: "20px" }}>
//         Generating PDF for ID: {id}...
//       </div>
//     </div>
//   );
// };

// export default PDFPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../CommonLoader";

const PdfPage = () => {
  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const rowData = {
    user_id: id,
    ref_no: "BY-S-13834",
    unit: "1 bed apartment",
    location: "La Residence, Jumeirah Village Triangle, Dubai",
    builtUpArea: "600.73",
    price: "AED 800,000",
    furnished: "Fully Furnished",
    status: "Vacant",
    amenities: "Nice Location",
  };

  useEffect(() => {
    generatePdf();
  }, [id]);

  const generatePdf = async () => {
    const pdfContent = document.getElementById("pdf-content");

    if (!pdfContent) {
      console.error("PDF content not found");
      return;
    }

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const marginTop = 20; // Space for header
    const marginBottom = 20; // Space for footer
    let currentHeight = marginTop + 30; // Start below the header

    const drawHeader = () => {
      pdf.setFontSize(14);
      pdf.setTextColor("#000000");
      pdf.setFont("Helvetica", "bold");
      pdf.text(`${rowData.unit} For Sale - Ref: ${rowData.ref_no}`, 10, 20);

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(51, 122, 183);
      pdf.line(10, 29, pageWidth - 10, 29);

      pdf.addImage("/svg/pdflogo.jpg", "JPG", pageWidth - 40, 3, 30, 25);

      pdf.setFontSize(10);
      pdf.setTextColor("#3070b6");
      pdf.text(`${rowData.location}`, 10, 36);
      pdf.text(`Built-up area: ${rowData.builtUpArea} sq ft`, 10, 42);

      const priceX = pageWidth - 10 - pdf.getTextWidth(rowData.price);
      pdf.text(`${rowData.price}`, priceX, 36);
    };

    const footerText = `Call Binayah Support on +97144475431 or email crm3@binayah.com to view this property.\nBinayah Properties L.L.C | www.binayah.com | info@binayah.com | 04 447 5431`;
    const drawFooter = (pageNum) => {
      pdf.setFontSize(10);
      pdf.text(`Page ${pageNum}`, pageWidth - 20, pageHeight - 15);
      pdf.setFillColor("#3070b6");
      pdf.rect(0, pageHeight - 20, 210, 20, "F");
      pdf.setTextColor(255, 255, 255);
      pdf.setFont("Helvetica", "normal");
      pdf.text(footerText, 10, pageHeight - 10, { lineHeightFactor: 1.5 });
    };

    drawHeader();

    const canvas = await html2canvas(pdfContent);
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = pageWidth - 20;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    if (currentHeight + imgHeight + marginBottom > pageHeight) {
      drawFooter(pdf.internal.getNumberOfPages());
      pdf.addPage();
      drawHeader();
      currentHeight = marginTop + 30;
    }

    pdf.addImage(imgData, "PNG", 10, currentHeight, imgWidth, imgHeight);
    currentHeight += imgHeight + 10;

    drawFooter(pdf.internal.getNumberOfPages());

    const pdfBlob = pdf.output("blob");
    const pdfURL = URL.createObjectURL(pdfBlob);
    window.open(pdfURL, "_blank");

    setLoader(false);
    navigate("/all-listing");
  };

  return (
    <>
      <div>
        {loader && <Loader />}
        <div id="pdf-content" style={{ padding: 20 }}>
          <div className="pdf-block" style={{ marginBottom: "20px" }}>
            <div style={{ margin: "10px 0 30px 0", fontWeight: "600" }}>
              <span>{rowData.furnished} | </span>
              <span>{rowData.status} | </span>
              <span>{rowData.amenities}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfPage;
