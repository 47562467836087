import React from "react";
import "../../../asset/css/pages/page404.css";
import { Link } from "react-router-dom";
import HelmetComponent from "../../Common/HelmetComponent";

const Page404 = () => {
  return (
    <>
      <HelmetComponent title="404-Page" />
      <div className="main-page-404-containner">
        <section className="page_404">
          <div className="w-100">
            <div className="">
              <div className="">
                <div className="image-parant-404">
                  <div className="four_zero_four_bg">
                    <h1 className="text-center ">404</h1>
                  </div>

                  <div className="contant_box_404 text-center">
                    <h3 className="h2 ">Look like you're lost</h3>

                    <p>the page you are looking for not avaible!</p>

                    <Link to={"/"} className="link_404">
                      Go to Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Page404;
