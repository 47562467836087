import React from "react";
import "../../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddUnitModel from "./addUnitModel";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddUnitModelPage = () => {
  return (
    <>
      <HelmetComponent title="Add-Unit-Model" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add Unit Model"
          home="Dashbord"
          currentPage="Add Unit Model"
          addExtraPageTitle="Project"
          addExtraPageTitlePath="create-project"
        />
        <AddUnitModel />
      </div>
    </>
  );
};

export default AddUnitModelPage;
