import React, { useEffect } from "react";
import "../../../asset/css/pages/login.css";
import { useState } from "react";
import headerLogo from "../../../asset/images/headerlogo.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
const Login = () => {
  const [activeTab, setActiveTab] = useState("admin");
  const [showTooltip, setShowTooltip] = useState(false);
  const [hideShowPassword, setHideShowPassword] = useState(false);
  const [forgotPasswordTab, setForgotPasswordTab] = useState("");
  const [emailTab, setEmailTab] = useState("");
  const [otpTab, setOtpTab] = useState("");
  const [confirmhideShowPassword, setConfirmHideShowPassword] = useState("");
  const [animation, setAnimation] = useState(false);

  const handleTabChange = (tab) => {
    setAnimation(true);
    setShowTooltip(false);
    setHideShowPassword(false);
    setTimeout(() => {
      setActiveTab(tab);
      // setAnimation(false);
    }, 300);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [activeTab]);

  const handleSubmitLoginForm = (e) => {
    console.log(e);
  };
  const handleSubmitEmailVarificationForm = (e) => {
    console.log(e);
    setEmailTab("");
    setOtpTab(emailTab);
  };

  const handleSubmitOtpVarificationForm = (e) => {
    console.log(e);
    setForgotPasswordTab(otpTab);
    setOtpTab("");
  };
  const handleSubmitForgotPasswordForm = (e) => {
    console.log(e);
    setForgotPasswordTab("");
    setActiveTab("admin");
  };

  return (
    <>
      {(activeTab === "admin" ||
        activeTab === "agent" ||
        activeTab === "teamleader" ||
        activeTab === "propertymanager" ||
        activeTab === "user") && (
        <>
          <div className="login-page  page-containners-main">
            <div className="container">
              <div className="forms-container">
                <div className="signin-signup ">
                  <div
                    className={
                      activeTab === "user-sign-up"
                        ? "d-none"
                        : "tab-containner pt-2"
                    }
                  >
                    <a
                      className={`py-1 px-2 m-1 ${
                        activeTab === "admin" ? "active-tab d-block" : ""
                      }`}
                      onClick={() => {
                        handleTabChange("admin");
                      }}
                    >
                      <img
                        width={"30px"}
                        src="/svg/admin-icon.png"
                        alt="Admin"
                      />
                      <span
                        className={`tooltip-text ${
                          showTooltip ? "show-tooltip" : ""
                        }`}
                      >
                        Admin
                      </span>
                    </a>

                    <a
                      className={`py-1 px-2 m-1 ${
                        activeTab === "agent" ? "active-tab d-block" : ""
                      }`}
                      onClick={() => {
                        handleTabChange("agent");
                      }}
                    >
                      <img
                        width={"30px"}
                        src="/svg/agent-icon.png"
                        alt="Admin"
                      />
                      <span className="tooltip-text">Agent</span>
                    </a>
                    <a
                      className={`py-1 px-2 m-1 ${
                        activeTab === "teamleader" ? "active-tab d-block" : ""
                      }`}
                      onClick={() => {
                        handleTabChange("teamleader");
                      }}
                    >
                      <img
                        width={"30px"}
                        src="/svg/team-leader-icon.png"
                        alt="Admin"
                      />

                      <span className="tooltip-text">Team Leader</span>
                    </a>
                    <a
                      className={`py-1 px-2 m-1 ${
                        activeTab === "propertymanager"
                          ? "active-tab d-block"
                          : ""
                      }`}
                      onClick={() => {
                        handleTabChange("propertymanager");
                      }}
                    >
                      <img
                        width={"25px"}
                        src="/svg/property-manager-icon.png"
                        alt="Admin"
                      />
                      <span className="tooltip-text">P-Manager</span>
                    </a>
                    <a
                      className={`py-1 px-2 m-1 ${
                        activeTab === "user" ? "active-tab d-block" : ""
                      }`}
                      onClick={() => {
                        handleTabChange("user");
                      }}
                    >
                      <img width={"30px"} src="/svg/user.svg" alt="Admin" />
                      <span className="tooltip-text">User</span>
                    </a>
                  </div>
                  <Formik
                    initialValues={{
                      user_name: "",
                      password: "",
                    }}
                    validationSchema={Yup.object()
                      .shape({
                        user_name: Yup.string()
                          .min(2)
                          .required("User name is required."),
                        password: Yup.string()
                          .required("Please enter your password.")
                          .min(8, "Your password is too short."),
                      })
                      .defined()}
                    onSubmit={handleSubmitLoginForm}
                  >
                    {() => (
                      <Form
                        className={`sign-in-form ${
                          animation ? "sign-in-form-animation" : ""
                        }`}
                      >
                        <h2 className="title">
                          Login{" "}
                          {(activeTab === "admin" && "Admin") ||
                            (activeTab === "agent" && "Agent") ||
                            (activeTab === "teamleader" && "Teamleader") ||
                            (activeTab === "propertymanager" && "Manager") ||
                            (activeTab === "user" && "User")}
                        </h2>
                        <div className="input-field">
                          <img
                            width="20px"
                            src="/svg/user_name_login_icon.png"
                            className=""
                            alt="images"
                          />
                          <Field
                            type="text"
                            placeholder="Username"
                            name="user_name"
                          />
                        </div>
                        <ErrorMessage
                          name="user_name"
                          component="div"
                          className="text-danger text-center"
                        />
                        <div className="input-field">
                          <img
                            width="20px"
                            src="/svg/login_pass_icon.png"
                            className=""
                            alt="images"
                          />
                          <Field
                            type={hideShowPassword ? "text" : "password"}
                            placeholder="Password"
                            autoComplete="new-password"
                            name="password"
                          />
                          <img
                            width="20px"
                            src={
                              hideShowPassword
                                ? "/svg/password_view.png"
                                : "/svg/password_hide.png"
                            }
                            className="password-icon-hide-show"
                            alt="images"
                            onClick={() => {
                              setHideShowPassword(!hideShowPassword);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger text-center"
                        />
                        <div className="forget-password-login-page">
                          <p
                            onClick={() => {
                              setEmailTab(activeTab);
                              setActiveTab("");
                            }}
                            className="cursor-pointer"
                          >
                            Forgot Password ?
                          </p>
                        </div>
                        <input
                          type="submit"
                          value="Login"
                          className="btn solid sign-in-btn"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              {/* Panels Section */}
              <div className="panels-container">
                <div className="panel left-panel">
                  <div className="content"></div>

                  <div className="login-page-main-logo-icon">
                    <img
                      src={headerLogo}
                      className="main-logo-crm"
                      alt="logo"
                    />
                  </div>

                  <img
                    src="/svg/new-login-page-gif.gif"
                    className="image"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Forgot password... */}
      {activeTab === "" && (
        <div className="login-page  page-containners-main">
          <div className="container">
            <div className="forms-container">
              <div className="signin-signup ">
                <>
                  {(emailTab === "admin" ||
                    emailTab === "agent" ||
                    emailTab === "teamleader" ||
                    emailTab === "propertymanager" ||
                    emailTab === "user") && (
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={Yup.object()
                        .shape({
                          email: Yup.string()
                            .email("Invalid email format.")
                            .required("Email is required."),
                        })
                        .defined()}
                      onSubmit={handleSubmitEmailVarificationForm}
                    >
                      {() => (
                        <Form className="sign-in-form">
                          <h2 className="title forget-password-title">
                            Verify Email
                          </h2>
                          <div className="input-field mt-7 mb-4">
                            <img
                              width="20px"
                              src="/svg/inbox.png"
                              className=""
                              alt="images"
                            />
                            <Field
                              type="email"
                              name="email"
                              placeholder="Enter email"
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger text-center"
                          />
                          <input
                            type="submit"
                            value="Submit"
                            className="btn solid sign-in-btn mt-7 mb-7"
                          />
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
                <>
                  {(otpTab === "admin" ||
                    otpTab === "agent" ||
                    otpTab === "teamleader" ||
                    otpTab === "propertymanager" ||
                    otpTab === "user") && (
                    <Formik
                      initialValues={{
                        otp: "",
                      }}
                      validationSchema={Yup.object()
                        .shape({
                          otp: Yup.string()
                            .min(4)
                            .max(6)
                            .required("Otp Is required field."),
                        })
                        .defined()}
                      onSubmit={handleSubmitOtpVarificationForm}
                    >
                      {() => (
                        <Form className="sign-in-form">
                          <h2 className="title forget-password-title">
                            Verify Otp
                          </h2>
                          <div className="input-field mt-7  mb-4">
                            <img
                              width="20px"
                              src="/svg/otp_icon.png"
                              className=""
                              alt="images"
                              required
                            />

                            <Field
                              type="text"
                              placeholder="Enter otp"
                              name="otp"
                            />
                            <button className="send-otp-btn">Resend Otp</button>
                          </div>
                          <ErrorMessage
                            name="otp"
                            component="div"
                            className="text-danger text-center"
                          />
                          <input
                            type="submit"
                            value="Submit"
                            className="btn solid sign-in-btn mt-7 mb-7"
                          />
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
                <>
                  {(forgotPasswordTab === "admin" ||
                    forgotPasswordTab === "agent" ||
                    forgotPasswordTab === "teamleader" ||
                    forgotPasswordTab === "propertymanager" ||
                    forgotPasswordTab === "user") && (
                    <Formik
                      initialValues={{
                        password: "",
                        conferm_password: "",
                      }}
                      validationSchema={Yup.object()
                        .shape({
                          password: Yup.string()
                            .required("Please enter your password.")
                            .min(8, "Your password is too short."),
                          conferm_password: Yup.string()
                            .required("Please retype your password.")
                            .oneOf(
                              [Yup.ref("password")],
                              "Your passwords do not match."
                            ),
                        })
                        .defined()}
                      onSubmit={handleSubmitForgotPasswordForm}
                    >
                      {() => (
                        <Form className="sign-in-form">
                          <h2 className="title forget-password-title">
                            Forget Password
                          </h2>
                          <div className="input-field mt-7 mb-4">
                            <img
                              width="20px"
                              src="/svg/login_pass_icon.png"
                              className=""
                              alt="images"
                            />
                            <Field
                              type={hideShowPassword ? "text" : "password"}
                              placeholder="New Password"
                              autoComplete="new-password"
                              name="password"
                            />
                            <img
                              width="20px"
                              src={
                                hideShowPassword
                                  ? "/svg/password_view.png"
                                  : "/svg/password_hide.png"
                              }
                              className="password-icon-hide-show"
                              alt="images"
                              onClick={() => {
                                setHideShowPassword(!hideShowPassword);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger text-center"
                          />
                          <div className="input-field  mb-4">
                            <img
                              width="20px"
                              src="/svg/login_pass_icon.png"
                              className=""
                              alt="images"
                            />

                            <Field
                              type={
                                confirmhideShowPassword ? "text" : "password"
                              }
                              placeholder="Confirm Password"
                              autoComplete="new-password"
                              name="conferm_password"
                            />
                            <img
                              width="20px"
                              src={
                                confirmhideShowPassword
                                  ? "/svg/password_view.png"
                                  : "/svg/password_hide.png"
                              }
                              className="password-icon-hide-show"
                              alt="images"
                              onClick={() => {
                                setConfirmHideShowPassword(
                                  !confirmhideShowPassword
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="conferm_password"
                            component="div"
                            className="text-danger text-center"
                          />
                          <input
                            type="submit"
                            value="Submit"
                            className="btn solid sign-in-btn mt-7 mb-7"
                          />
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              </div>
            </div>

            <div className="panels-container">
              <div className="panel left-panel">
                <div className="content"></div>

                <div className="login-page-main-logo-icon">
                  <img src={headerLogo} className="main-logo-crm" alt="logo" />
                </div>

                <img
                  src="/svg/new-login-page-gif.gif"
                  className="image"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
