import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddDealComponent from "./AddDeal";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddAllDeals = () => {
  return (
    <>
      <HelmetComponent title="Add-All-Deals" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Add All Deals"
          home="Dashboard"
          currentPage="Add All Deals"
          addExtraPageTitle="All Deals"
          addExtraPageTitlePath="all-deals"
        />
        <AddDealComponent />
      </div>
    </>
  );
};

export default AddAllDeals;
