import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { WebsiteLeads } from "../../../asset/StastucJsonData/WebsiteLeadsJsonData";
import CommonDateRangePicker from "../../Common/DateRangePicker";
import OtpModal from "../../Common/OtpModel";

const WebsiteLeadsList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sendOtpModel, setSendOtpModel] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = WebsiteLeads.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleOtpCloseModel = () => {
    setSendOtpModel(false);
  };

  const handleOtpSubmitOtpForm = (otp) => {
    if (otp === "1234") {
      console.log("OTP Verified");
      setSendOtpModel(false);
    } else {
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  const handleResendOtp = () => {
    console.log("OTP Resent");
  };

  const column = [
    {
      name: "Sr",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "70px",
    },
    {
      name: "Name",

      selector: (row) => row?.name,
      center: "true",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: "true",
      wrap: true,
      width: "200px",
    },

    {
      name: "Mobile",
      selector: (row) => row?.mobile,
      center: "true",
      wrap: true,
    },
    {
      name: "Channel",
      selector: (row) => row?.chanel,
      center: "true",
      wrap: true,
    },
    {
      name: "Message",
      cell: (row) => (
        <div className="text-start pl-2 lh-lg">{row?.message}</div>
      ),
      center: "true",
      wrap: true,
      width: "300px",
    },
    {
      name: "Project",
      selector: (row) => row?.project_name,
      center: "true",
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row?.Date_Added,
      center: "true",
      wrap: true,
    },
  ];

  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  console.log("selectedDatesaaaaa", selectedDates);

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <CommonDateRangePicker onApply={handleDateRangeApply} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <button
              type="button"
              className="btn filter_button listing_button px-2 custom-select rounded-0"
              style={{ height: "36px" }}
              onClick={() => setSendOtpModel(true)}
            >
              <img
                className="mx-2"
                src="/svg/csv_button_icon.png"
                width="25px"
                alt="images"
              />
              Export To CSV
            </button>
          </div>
        </div>
      </div>

      <div className="my-3">
        <div className="data-table-single-search-input-box-container">
          <input
            type="text"
            placeholder="Search..."
            className=""
            value={searchQuery}
            onChange={handleSearch}
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : WebsiteLeads}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />

      <OtpModal
        isOpen={sendOtpModel}
        onClose={handleOtpCloseModel}
        onSubmit={handleOtpSubmitOtpForm}
        resendOtp={handleResendOtp}
      />
      {otpError && <p className="text-danger">{otpError}</p>}
    </>
  );
};

export default WebsiteLeadsList;
