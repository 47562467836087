import * as Yup from "yup";

export const editAllListingInitialValues = {
  rental_id: "",
  agent: "",
  team_leader: "",
  create_by: "",
  associated_agente: "",
  associated_team_leader: "",
  off_plan: "",
  offering_type: "",
  category: "",
  beds: "",
  baths: "",
  emirates: "",
  transaction_number: "",
  project: "",
  location: "",
  sub_location: "",
  is_furnished: "",
  unit_no: "",
  type: "",
  street: "",
  floor: "",
  area: "",
  plot_area: "",
  view: "",
  parking: "",
  owner: "",
  property_title: "",
  description: "",
  price: "",
  freequency: "",
  cheques: "",
  commission: "",
  deposit: "",
  watermark: "",
  photo_image: "",
  floor_plans: "",
  property_status: "",
  source_listing: "",
  expiration_date: "",
  featured: "",
  dewa_number: "",
  str: "",
  available_date: "",
  remind: "",
  key_locations: "",
  rented_at: "",
  rented_until: "",
  maintance_fee: "",
  managed: "",
  exclusive: "",
  invite: "",
  poa: "",
  status: "",
  features: [],
  portals: [],
};

export const editAllListingValidationSchema = Yup.object()
  .shape({
    rental_id: Yup.string().required("Rental id is required."),
    agent: Yup.string().required("Agent id is required."),
    team_leader: Yup.string().required("Team leader id is required."),
    create_by: Yup.string().required("Create by is required."),
    associated_agente: Yup.string().required("Associated Agente is required."),
    associated_team_leader: Yup.string().required(
      "Associated team leader is required."
    ),
    off_plan: Yup.string().required("Off plan is required."),
    offering_type: Yup.string().required("Offering plan is required."),
    category: Yup.string().required("Category is required."),
    beds: Yup.string().required("Beds is required."),
    baths: Yup.string().required("Baths is required."),
    emirates: Yup.string().required("Emirates is required."),
    transaction_number: Yup.string().required(
      "Transaction number is required."
    ),
    project: Yup.string().required("Project is required."),
    location: Yup.string().required("Location is required."),
    sub_location: Yup.string().required("Sub location is required."),
    is_furnished: Yup.string().required("Is furnished is required."),
    unit_no: Yup.string().required("Unit no is required."),
    type: Yup.string().required("Type is required."),
    street: Yup.string().required("Street is required."),
    floor: Yup.string().required("Floor is required."),
    area: Yup.string().required("Area is required."),
    plot_area: Yup.string().required("Plot area is required."),
    view: Yup.string().required("View is required."),
    parking: Yup.string().required("Parking is required."),
    owner: Yup.array()
      .min(1, "At least 1 owner select")
      .of(Yup.string().required())
      .required("This field is required"),
    features: Yup.array()
      .min(1, "At least 1 feature select")
      .of(Yup.string().required())
      .required("This field is required"),
    portals: Yup.array()
      .min(1, "At least 1 portals select")
      .of(Yup.string().required())
      .required("This field is required"),
    property_title: Yup.string().required("Property title is required."),
    description: Yup.string().required("Description is required."),
    price: Yup.string().required("Price is required."),
    freequency: Yup.string().required("Freequency is required."),
    cheques: Yup.string().required("Cheques is required."),
    commission: Yup.string().required("Commission is required."),
    deposit: Yup.string().required("Deposit is required."),
    watermark: Yup.string().required("Watermark is required."),
    photo_image: Yup.mixed().required("Image is required."),
    floor_plans: Yup.string().required("Image is required."),
    property_status: Yup.string().required("Property status is required."),
    source_listing: Yup.string().required("Source listing area is required."),
    expiration_date: Yup.string().required("Expiration date area is required."),
    featured: Yup.string().required("Featured area is required."),
    dewa_number: Yup.string().required("DEWA number area is required."),
    str: Yup.string().required("STR area is required."),
    available_date: Yup.string().required("Available date area is required."),
    remind: Yup.string().required("remind area is required."),
    key_locations: Yup.string().required("Key locations is required."),
    property_tenanted: Yup.string().required("Property tenanted is required."),
    rented_at: Yup.string().required("Rented at is required."),
    rented_until: Yup.string().required("Rented until is required."),
    maintance_fee: Yup.string().required("Maintance fee is required."),
    managed: Yup.string().required("Managed fee is required."),
    exclusive: Yup.string().required("Exclusive fee is required."),
    invite: Yup.string().required("Invite fee is required."),
    poa: Yup.string().required("POA is required."),
    status: Yup.string().required("Status is required."),
  })
  .defined();

export const addAllListingInitialValues = {
  add_property: "",
  property_number: "",
  name: "",
  create_by: "",
  lead_type: "",
  source: "",
  agent: "",
  team_lead: "",
  owner: "",
  email: "",
  phone: "",
  proprty: "",
  status: "",
  sub_status: "",
  priority: "",
  hot_lead: "",
  type: "",
  category: "",
  unit_no: "",
  price: "",
  bedrooms: "",
  emirates: "",
  project: "",
  location: "",
  sub_locations: "",
  country: "",
  transcript: "",
  lead_notes: "",
};

export const addAllListingValidationSchema = Yup.object()
  .shape({
    add_property: Yup.string().required("Property is required."),
    property_number: Yup.string().required("Property number is required."),
    name: Yup.string().required("Name is required."),
    create_by: Yup.string().required("Create by is required."),
    lead_type: Yup.string().required("Lead type is required."),
    source: Yup.string().required("Source is required."),
    agent: Yup.string().required("agent is required."),
    team_lead: Yup.string().required("Team lead is required."),
    owner: Yup.array()
      .min(1, "At least 1 owner Select")
      .of(Yup.string().required())
      .required("This field is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string()
      .matches(/[0-9]/, "Telephone must be a numbers only.")
      .required("Phone number is required."),
    proprty: Yup.string().required("Proprty is required"),
    status: Yup.string().required("status is required"),
    sub_status: Yup.string().required("Sub Status is required"),
    priority: Yup.string().required("Priority is required"),
    hot_lead: Yup.string().required("Priority is required"),
    type: Yup.string().required("Type is required"),
    category: Yup.string().required("Category is required"),
    unit_no: Yup.string().required("Category is required"),
    price: Yup.string().required("Price is required"),
    bedrooms: Yup.string().required("Bedrooms is required"),
    emirates: Yup.string().required("Emirates is required"),
    project: Yup.string().required("Project is required"),
    location: Yup.string().required("Location is required"),
    sub_locations: Yup.string().required("Sub locations is required"),
    country: Yup.string().required("Country is required"),
    transcript: Yup.string().required("Transcript is required"),
    lead_notes: Yup.string().required("Lead notes is required"),
  })
  .defined();

export const addChequeInitialValues = {
  title: "",
  cheque_number: "",
  details: "",
  date: "",
  type: "",
  cheque_image: "",
};

export const addChequeValidationSchema = Yup.object()
  .shape({
    title: Yup.string().required("Please title is requred."),
    cheque_number: Yup.string().required("Please cheque Number is requred."),
    details: Yup.string().required("Please details is requred."),
    date: Yup.string().required("Please date is requred."),
    type: Yup.string().required("Please type is requred."),
    cheque_image: Yup.mixed().required("Please cheque image is requred."),
  })
  .defined();

export const editDocumentvalidationSchema = Yup.object()
  .shape({
    title: Yup.string().required("Field is required."),
    is_active: Yup.string().required("Field is required."),
    document_image: Yup.mixed()
      .required("Image is required")
      .test("fileFormat", "Unsupported Format", (value) =>
        value ? ["image/jpeg", "image/png"].includes(value.type) : false
      ),
  })
  .defined();
