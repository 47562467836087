import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikCanselButton,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";
import { DragAndDropComponent } from "../../../Common/DragAndDropSingleImage";
import CustomSelect from "../../../Common/Select2Box/customSelectbox";
import CustomDatePicker from "../../../Common/DatePicker/DatePickerCommon";
import { addDealsOptionsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";

const AddAgent = () => {
  const getDealsOptionsData = addDealsOptionsData[0] || [];
  const initialValues = {
    agent: "",
    email: "",
    mobile_no: "",
    user_name: "",
    password: "",
    dob: "",
    team_leader: "",
    work_area: "",
    agent_image: null,
  };

  const validationSchema = Yup.object()
    .shape({
      agent: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      mobile_no: Yup.string().required("Field is required."),
      user_name: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
      dob: Yup.string().required("Field is required."),
      team_leader: Yup.string().required("Field is required."),
      work_area: Yup.string().required("Field is required."),
      agent_image: Yup.mixed()
        .required("Image is required")
        .test("fileFormat", "Unsupported Format", (value) =>
          value ? ["image/jpeg", "image/png"].includes(value.type) : false
        ),
    })
    .defined();

  const handleubmitForm = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleubmitForm}
        >
          {({ setFieldValue }) => {
            return (
              <>
                <Form>
                  <div className="add-agent-form-containner">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Agent Name<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter Agent Name"
                          name="agent"
                        />
                        <ErrorMessage
                          name="agent"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Email<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter Email"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Mobile No<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter Mobile No"
                          name="mobile_no"
                        />
                        <ErrorMessage
                          name="mobile_no"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          User Name<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter User Name"
                          name="user_name"
                        />
                        <ErrorMessage
                          name="user_name"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Password<span className="text-danger">*</span>
                        </p>
                        <Field
                          className="form-control rounded-0"
                          type="text"
                          placeholder="Enter Password"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component={"div"}
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Status <span className="text-danger">*</span>
                        </p>
                        <Field name="is_activate">
                          {() => (
                            <CustomSelect
                              options={getDealsOptionsData?.select_by_type}
                              placeholder=" - Select status - "
                              name="is_activate"
                              onChange={(value) => {
                                setFieldValue("is_activate", value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="is_activate"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Date of Birth<span className="text-danger">*</span>
                        </p>
                        <Field name="dob">
                          {({ field, form }) => (
                            <CustomDatePicker
                              startDate={field.value}
                              src={"/svg/calendar.png"}
                              setStartDate={(date) =>
                                form.setFieldValue("dob", date)
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Teem Leader <span className="text-danger">*</span>
                        </p>
                        <Field name="team_leader">
                          {() => (
                            <CustomSelect
                              options={getDealsOptionsData?.select_by_type}
                              placeholder=" - Select Team Leader - "
                              name="team_leader"
                              onChange={(value) => {
                                setFieldValue("team_leader", value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="team_leader"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <p className="m-0">
                          Work Area<span className="text-danger">*</span>
                        </p>
                        <Field name="work_area">
                          {() => (
                            <CustomSelect
                              options={getDealsOptionsData?.select_by_type}
                              placeholder=" - Select Work Area - "
                              name="work_area"
                              onChange={(value) => {
                                setFieldValue("work_area", value);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="work_area"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <DragAndDropComponent name="agent_image" />
                      </div>
                    </div>
                  </div>

                  <div className="cansel-submit-note-btn text-center mt-7 mb-2">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/agents" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddAgent;
