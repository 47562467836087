import React from "react";
import ListPropertyFinderLeads from "./ListPropertyFinderLeads";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const PropertyFinderLeads = () => {
  return (
    <>
      <HelmetComponent title="Property-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Property Finder Leads"
          home="Dashboard"
          currentPage="Property Finder Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <ListPropertyFinderLeads />
      </div>
    </>
  );
};

export default PropertyFinderLeads;
