import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import CustomDatePicker from "../DatePicker/DatePickerCommon";
import CustomSelect from "../Select2Box/customSelectbox";
import { Link } from "react-router-dom";

export const FormikInputField = ({
  label,
  name,
  placeholder,
  type = "text",
}) => {
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
        <p className="m-0">
          {label} <span className="text-danger">*</span>
        </p>
        <Field
          className="form-control rounded-0"
          type={type}
          placeholder={placeholder}
          name={name}
        />
        <ErrorMessage name={name} component={"div"} className="text-danger" />
      </div>
    </>
  );
};
export const FormikDateField = ({ label, name }) => {
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
        <p className="m-0">
          {label} <span className="text-danger">*</span>
        </p>
        <Field name={name}>
          {({ field, form }) => (
            <CustomDatePicker
              startDate={field.value}
              setStartDate={(date) => form.setFieldValue(name, date)}
              src={"/svg/calendar.png"}
            />
          )}
        </Field>
        <ErrorMessage name={name} component={"div"} className="text-danger" />
      </div>
    </>
  );
};

export const FormikSelectField = ({
  label,
  name,
  options,
  placeholder = " - Select - ",
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
      <p className="m-0">
        {label} <span className="text-danger">*</span>
      </p>
      <Field name={name}>
        {() => (
          <CustomSelect
            options={options}
            placeholder={placeholder}
            onChange={(name, value) => setFieldValue(name, value)}
            name={name}
          />
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
};
export const FormikSubmitButton = ({ label = "Submit" }) => {
  return (
    <>
      <button type="submit" className="success-btn mx-2">
        {label}
      </button>
    </>
  );
};
export const FormikCanselButton = ({ path, label = "Cancel" }) => {
  return (
    <>
      <Link to={path}>
        <button type="button" className="mx-2 cancel-btn">
          {label}
        </button>
      </Link>
    </>
  );
};
