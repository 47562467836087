// TEAM LEADER STATIC LIST DATA...

export const teamLeaderListData = [
  {
    _id: "6455e5c8205059516716f7ef",
    team_leader: "Shahbaz",
    email: "sk@binayah.com",
    mobile_no: +971545157171,
    user_name: "shahbaz",
    password: "Binayah2023#@",
  },
  {
    _id: "64758263cd4ef4ee33cf2201",
    team_leader: "Subhan Ahmed ",
    email: "sss@binayah.ae",
    mobile_no: +971525295393,
    user_name: "subhan",
    password: "Binayah2023#@",
  },
  {
    _id: "64759493d101050fd1783648",
    team_leader: "Talal Ahmad",
    email: "ta@binayah.com",
    mobile_no: +971559907032,
    user_name: "talal",
    password: "Binayah2023#@",
  },
  {
    _id: "647594d0d101050fd1783692",
    team_leader: "Sheena Mae",
    email: "info@binayah.com",
    mobile_no: +971555099157,
    user_name: "reception",
    password: "Binayah2023#@",
  },
  {
    _id: "6475952fd101050fd17836e8",
    team_leader: "Binayah Support",
    email: "crm2@binayah.com",
    mobile_no: +97144475431,
    user_name: "crm2",
    password: "Binayah2023#@",
  },
  {
    _id: "647595e2d101050fd178373e",
    team_leader: "Mahammad Masood Karminadi",
    email: "propertymanager@binayah.com",
    mobile_no: +971523198488,
    user_name: "masood",
    password: "Binayah2023#@",
  },
  {
    _id: "64759622d101050fd178376c",
    team_leader: "Hardip Gandara",
    email: "hg@binayah.com",
    mobile_no: +97144475431,
    user_name: "hardip",
    password: "Binayah2023#@",
  },
  {
    _id: "6475972fd101050fd17837bd",
    team_leader: "Usama Kalyar",
    email: "uk@binayah.com",
    mobile_no: +971529994111,
    user_name: "usamak",
    password: "Binayah2023#@",
  },
  {
    _id: "665738927c2683ca8ad97f19",
    team_leader: "Aswin Prasaad",
    email: "asp@binayah.com",
    mobile_no: +971585903445,
    user_name: "aswin",
    password: "Binayah2023#@",
  },
  {
    _id: "665d99e800a8a1ad95cbf397",
    team_leader: "Yasser Ahmed",
    email: "yahm@binayah.com",
    mobile_no: +971585517045,
    user_name: "yasser",
    password: "Binayah2023#@",
  },
];
