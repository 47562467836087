import React, { useEffect, useState } from "react";
import "../../../asset/css/common/loader.css";

// const Loader = () => {
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     window.addEventListener("load", handleLoad);
//     return () => {
//       window.removeEventListener("load", handleLoad);
//     };
//   }, []);

//   const handleLoad = () => {
//     setIsLoaded(true);
//   };

//   return (
//     <>
//       {!isLoaded && (
//         <div id="loader">
//           <video id="loader-video" autoPlay muted loop>
//             <source src="/svg/loader_video.mp4" type="video/mp4" />
//           </video>
//         </div>
//       )}
//       <div id="content" style={{ display: isLoaded ? "block" : "none" }}></div>
//     </>
//   );
// };

// export default Loader;

// ----------------------------------------------------

const Loader = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <>
      {!isLoaded && (
        <div id="loader-overlay" style={{ display: "flex" }}>
          {/* <video width="320" height="240" autoPlay loop muted>
            <source src="/svg/property_loader.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <img src="/svg/property_loader.gif" alt="gif"></img>
          <svg viewBox="0 0 1320 300">
            <text x="50%" y="50%" dy=".35em" textAnchor="middle">
              Binayah
            </text>
          </svg>
        </div>
      )}
      <div id="content" style={{ display: isLoaded ? "block" : "none" }}></div>
    </>
  );
};

export default Loader;
