import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import { oldContactsData } from "../../../asset/StastucJsonData/ContactsJsonData";
import DetailComponent from "../../Common/DetailsPageComponent/detailComponent";
import HelmetComponent from "../../Common/HelmetComponent";

const OldContactDetails = () => {
  const [selectData, setSelectData] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const finData = oldContactsData.find((userID) => id === userID._id);
    setSelectData(finData);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <HelmetComponent title="View-OldContact" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Old Contacts Details"
          home="Dashboard"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
          currentPage="Old Contacts Details"
        />

        <DetailComponent
          title="Contact Ref No. :"
          title1="Gender :"
          title2="Title :"
          title3="First_Name :"
          data={selectData?.Ref}
          data1={selectData?.Gender}
          data2={selectData?.Title}
          data3={selectData?.First_Name}
        />
        <DetailComponent
          title="Last_Name :"
          title1="Company :"
          title2="Home Address1 :"
          title3="Home Address2 :"
          data={selectData?.Last_Name}
          data1={selectData?.Gender}
          data2={selectData?.Home_Address1}
          data3={selectData?.Home_Address2}
        />
        <DetailComponent
          title="Home Country :"
          title1="Home State :"
          title2="Home ZipCode :"
          title3="Personal Phone :"
          data={selectData?.Home_Country}
          data1={selectData?.Home_State}
          data2={selectData?.Home_ZipCode}
          data3={selectData?.Personal_Phone}
        />
        <DetailComponent
          title="Work Phone :"
          title1="Home Fax :"
          title2="Home PO_Box :"
          title3="Personal Mobile :"
          data={selectData?.Work_Phone}
          data1={selectData?.Home_Fax}
          data2={selectData?.Home_PO_Box}
          data3={selectData?.Personal_Mobile}
        />
        <DetailComponent
          title="Personal Email :"
          title1="Work Email :"
          title2="Date Of Birth :"
          title3="Designation :"
          data={selectData?.Personal_Email}
          data1={selectData?.Work_Email}
          data2={selectData?.Date_Of_Birth}
          data3={selectData?.Designation}
        />
        <DetailComponent
          title="Nationality :"
          title1="Religion :"
          title2="Work Mobile :"
          title3="Assigned_To :"
          data={selectData?.Nationality}
          data1={selectData?.Religion}
          data2={selectData?.Work_Mobile}
          data3={selectData?.Assigned_To}
        />
        <DetailComponent
          title="Updated :"
          title1="Other Phone :"
          title2="Other Mobile :"
          title3="Work Fax :"
          data={selectData?.Updated}
          data1={selectData?.Other_Phone}
          data2={selectData?.Other_Phone}
          data3={selectData?.Work_Fax}
        />
        <DetailComponent
          title="Other Fax :"
          title1="Other Email :"
          title2="Company_PO_Box :"
          title3="Company Address 1 :"
          data={selectData?.Other_Fax}
          data1={selectData?.Other_Email}
          data2={selectData?.Company_PO_Box}
          data3={selectData?.Company_Address1}
        />
        <DetailComponent
          title="Company Address 2 :"
          title1="Company City :"
          title2="Company State :"
          title3="Company Country :"
          data={selectData?.Company_Address2}
          data1={selectData?.Company_city}
          data2={selectData?.Company_State}
          data3={selectData?.Company_Country}
        />
        <DetailComponent
          title="Company ZipCode :"
          title1="Native Language :"
          title2="Second_Language :"
          title3="Contact Source :"
          data={selectData?.Company_ZipCode}
          data1={selectData?.Native_Language}
          data2={selectData?.Second_Language}
          data3={selectData?.Contact_Source}
        />
        <DetailComponent
          title="Contact Type  :"
          title1="Created Date :"
          title2="Created By :"
          title3="Unscribed Marketing Email :"
          data={selectData?.Contact_Type}
          data1={selectData?.Created_Date}
          data2={selectData?.Created_By}
          data3={selectData?.Subscribed_Marketing_Email}
        />
        <DetailComponent
          title="Notes  :"
          title1=""
          title2=""
          title3=""
          data={selectData?.Notes}
          data1=""
          data2=""
          data3=""
        />
      </div>
    </>
  );
};

export default OldContactDetails;
