import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import NotificationPageList from "./NotificationList";
import HelmetComponent from "../../Common/HelmetComponent/index";

const NotificationPage = () => {
  return (
    <>
      <HelmetComponent title="Notification" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Notification"
          home="Dashboard"
          currentPage="Notification"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <NotificationPageList />
      </div>
    </>
  );
};

export default NotificationPage;
