import React from "react";
import WAChatBotLeadsList from "./WAChatBotLeadsList";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const WebsiteLeads = () => {
  return (
    <>
      <HelmetComponent title="Chat-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage WA Chat Bot Leads"
          home="Dashboard"
          currentPage="WAChatBot Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <WAChatBotLeadsList />
      </div>
    </>
  );
};

export default WebsiteLeads;
