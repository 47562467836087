import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import TeamLeaderList from "./TeamLeaderList";
import HelmetComponent from "../../Common/HelmetComponent";

const TeamLeaderPage = () => {
  return (
    <>
      <HelmetComponent title="Team-Leader" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Team Leader"
          home="Dashboard"
          currentPage="Team Leader"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <TeamLeaderList />
      </div>
    </>
  );
};

export default TeamLeaderPage;
