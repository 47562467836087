import React, { useState, useEffect } from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-autoplay.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import "../../../../asset/css/common/multipleUploadImage.css";
import Modal from "../../../Common/Model/ModelCommon";
import { Link } from "react-router-dom";

const PublicFolderAddImages = () => {
  const [files, setFiles] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    addFiles(selectedFiles);
  };

  const handleDropFiles = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    addFiles(droppedFiles);
  };

  const handleDragOverFiles = (e) => {
    e.preventDefault();
  };

  const addFiles = (selectedFiles) => {
    const uniqueFiles = selectedFiles.filter(
      (newFile) =>
        !files.some(
          (existingFile) =>
            existingFile.name === newFile.name &&
            existingFile.lastModified === newFile.lastModified
        )
    );
    setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
  };

  const handleButtonClick = () => {
    document.getElementById("file-upload").click();
  };

  const removeImage = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    setShowDeleteModal(false);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnter = (index) => {
    const newFiles = [...files];
    const draggedItem = newFiles[draggedIndex];

    newFiles.splice(draggedIndex, 1);
    newFiles.splice(index, 0, draggedItem);

    setDraggedIndex(index);
    setFiles(newFiles);
  };

  const handleDrop = () => {
    setDraggedIndex(null);
  };

  const removeAllImages = () => {
    setFiles([]);
  };

  useEffect(() => {
    return () => {
      files.map((file) => {
        URL.revokeObjectURL(file);
      });
    };
  }, [files]);

  return (
    <>
      <div className="multiple-image-uploader-containner">
        <div
          onDragOver={handleDragOverFiles}
          onDrop={handleDropFiles}
          className="upload-images-button-containner"
        >
          <button
            className="pulse-btn pulse-active"
            onClick={handleButtonClick}
          >
            <img
              src="/svg/upload_img.gif"
              alt="upload_img"
              className="upload_image_"
              width="100px"
            />
          </button>

          <input
            id="file-upload"
            type="file"
            accept=".jpg,.gif,.png"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="drag-drop-area text-center">
          <p>Drag & drop your images here, or click to select files</p>
        </div>

        <>
          {files.length > 0 && (
            <>
              <div>
                <button
                  className="btn border border-gray bg-danger text-light"
                  onClick={() => setShowDeleteAllModal(true)}
                  type="button"
                >
                  Delete All Images
                </button>
              </div>
            </>
          )}
        </>

        <div className="row image-patrant mt-3">
          {files.length > 0 && (
            <LightGallery
              speed={1000}
              plugins={[lgThumbnail, lgFullscreen, lgZoom, lgAutoplay]}
              elementClassNames="row"
              appendSubHtmlTo=".lg-item"
            >
              {files.map((file, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-sm-12 krajee-default"
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragEnter={() => handleDragEnter(index)}
                  onDragEnd={handleDrop}
                  data-src={URL.createObjectURL(file)}
                >
                  <span className="file-preview-link">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="preview"
                      className="file-preview-image"
                    />
                  </span>
                  <div className="">
                    <button
                      className="kv-file-remove"
                      onClick={() => {
                        setDeleteId(index);
                        setShowDeleteModal(true);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              ))}
            </LightGallery>
          )}
        </div>
        {files.length > 0 && (
          <>
            <div className="text-center">
              <button type="submit" className="success-btn mx-2">
                Upload Images
              </button>
              <Link to="/public-folder">
                <button className="mx-2 cancel-btn">Cancel</button>
              </Link>
            </div>
          </>
        )}
      </div>

      {showDeleteModal && (
        <div className="common-status-button-containner">
          <Modal
            show={showDeleteModal}
            handleClose={() => {
              setShowDeleteModal(false);
            }}
          >
            <div className="sdvds text-center">
              <img
                className="commonstatusbutton-img"
                src="/svg/warning-animation.gif"
                alt="user"
              />
              <h5 className="mb-5">
                Are you sure you want to delete <br /> this image?
              </h5>

              <div className="modal-footer w-100 px-0">
                <button
                  className="modal-footer-cancel-btn"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="modal-footer-confirm-btn"
                  onClick={() => removeImage(deleteId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {showDeleteAllModal && (
        <div className="common-status-button-containner">
          <Modal
            show={showDeleteAllModal}
            handleClose={() => {
              setShowDeleteAllModal(false);
            }}
          >
            <div className="sdvds text-center">
              <img
                className="commonstatusbutton-img"
                src="/svg/warning-animation.gif"
                alt="user"
              />
              <h5 className="mb-5">
                Are you sure you want to delete <br />
                All image?
              </h5>

              <div className="modal-footer w-100 px-0">
                <button
                  className="modal-footer-cancel-btn"
                  onClick={() => setShowDeleteAllModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="modal-footer-confirm-btn"
                  onClick={() => {
                    setShowDeleteAllModal(false);
                    removeAllImages();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PublicFolderAddImages;
