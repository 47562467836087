export const noHeaderFooterRoutes = [
  "/",
  "/all-listing",
  "/add-all-listing",
  "/edit-all-listing/:id",
  "/all-leads",
  "/add-all-leads",
  "/edit-all-leads/:id",
  "/callleads",
  "/propertyfinder-leads",
  "/bayut-leads",
  "/dubizzle-leads",
  "/website-leads",
  "/wa-chatbot-leads",
  "/live-chat-leads",
  "/paid-leads1",
  "/paid-leads2",
  "/old-leads",
  "/old-leads/old-lead-details/:id",
  "/old-leads/edit-old-lead/:id",
  "/leads-pool",
  "/leads-settings",
  "/contacts",
  "/old-contact",
  "/old-contact-details/:id",
  "/calendar",
  "/calendar/:id",
  "/all-deals",
  "/add-all-deals",
  "/all-deals-details/:id",
  "/all-deals-edit/:id",
  "/old-deals",
  "/old-deals-details/:id",
  "/viewings",
  "/profile",
  "/hrm",
  "/location",
  "/sub-location",
  "/projects",
  "/tenants",
  "/add-tenants",
  "/edit-tenants/:id",
  "/tenants-show-property/:id",
  "/tenants-edit-property/:id",
  "/owners",
  "/add-owners",
  "/edit-owners/:id",
  "/owners-show-property/:id",
  "/owners-edit-property/:id",
  "/features",
  "/developer-logo",
  "/create-project",
  "/unit-model/:id",
  "/add-unit-model",
  "/edit-unit-model/:id",
  "/sales-offer",
  "/public-folder",
  "/add-image-public-folder",
  "/portals",
  "/agents",
  "/add-agents",
  "/edit-agents/:id",
  "/agents-property/:id",
  "/edit-agents-property/:id",
  "/agents-permission/:id",
  "/team-leaders",
  "/add-team-leaders",
  "/edit-team-leaders/:id",
  "/permission-team-leaders/:id",
  "/role",
  "/add-role",
  "/edit-role/:id",
  "/role-permission/:id",
  "/notification",
  "/all-listing/document/:id",
  "/all-listing/cheque/:id",
  "/all-listing/cheque/add/add-cheque/:id",
  "/all-listing/sale/:id",
  "/all-listing/rental/:id",
];
