import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditTenantsList from "./EditTenants";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditTenantsPage = () => {
  return (
    <>
      <HelmetComponent title="Edit-Tenants" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit Tenants"
          home="Dashboard"
          currentPage="Edit Tenants"
          addExtraPageTitle="Tenants"
          addExtraPageTitlePath="tenants"
        />
        <EditTenantsList />
      </div>
    </>
  );
};

export default EditTenantsPage;
