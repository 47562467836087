import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikInputField,
  FormikCanselButton,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const AddTeamLeader = () => {
  const initialValues = {
    team_leader: "",
    email: "",
    mobile_no: "",
    user_name: "",
    password: "",
    img: "",
  };

  const validationSchema = Yup.object()
    .shape({
      team_leader: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      mobile_no: Yup.string().required("Field is required."),
      user_name: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
    })
    .defined();

  const handleubmitForm = (e) => {
    console.log(e);
  };

  return (
    <>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleubmitForm}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row">
                    <FormikInputField
                      label="Team Leader"
                      name="team_leader"
                      placeholder="Enter Team Leader"
                    />
                    <FormikInputField label="Image" name="img" type="file" />
                    <FormikInputField
                      label="Email"
                      name="email"
                      placeholder="Enter Email"
                    />
                    <FormikInputField
                      label="Mobile No"
                      name="mobile_no"
                      placeholder="Enter Mobile No"
                    />
                    <FormikInputField
                      label="User Name"
                      name="user_name"
                      placeholder="Enter User Name"
                    />
                    <FormikInputField
                      label="Password"
                      name="password"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="text-center mb-10 mt-5">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/team-leaders" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddTeamLeader;
