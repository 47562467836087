import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import DeveloperLogoList from "./DeveloperLogoList";
import HelmetComponent from "../../Common/HelmetComponent";

const DeveloperLogoPage = () => {
  return (
    <>
      <HelmetComponent title="Developer-Logo" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Developer Logo"
          home="Dashbord"
          currentPage="Developer Logo"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <DeveloperLogoList />
      </div>
    </>
  );
};

export default DeveloperLogoPage;
