import React, { useState, useEffect, useRef } from "react";
import "../../../asset/css/common/model.css";

const Modal = ({ show, handleClose, children }) => {
  const [isVisible, setIsVisible] = useState(show);
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
    }

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, handleClose]);

  const handleAnimationEnd = () => {
    if (!show) {
      setIsVisible(false);
    }
  };

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return isVisible ? (
    <div className={showHideClassName} onAnimationEnd={handleAnimationEnd}>
      <section className="modal-main" ref={modalRef}>
        <button className="modal-close-button" onClick={handleClose}>
          &times;
        </button>
        {children}
      </section>
    </div>
  ) : null;
};

export default Modal;
