import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomSelect from "../../Common/Select2Box/customSelectbox";
import { listStaticSelectOptions } from "../../../asset/StastucJsonData/allLeadJsonData";
import { allDealsData } from "../../../asset/StastucJsonData/AllDealsJsonData";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { Link } from "react-router-dom";
import CommonDateRangePicker from "../../Common/DateRangePicker";
import Modal from "../../Common/Model/ModelCommon";

const AllDealsList = () => {
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedValue, setSelectedValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [listData, setListData] = useState(allDealsData || []);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const listStaticSelectOptionsData = listStaticSelectOptions[0] || [];

  const handleSelectChange = (e) => {
    setSelectedValue(e);
  };

  // Hendle search functionality...
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Heldle filtered data...
  const filteredData = listData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const deleteDeals = (item) => {
    const data = listData.filter((i) => i._id !== item._id);
    setListData(data);
  };

  useEffect(() => {
    deleteDeals(listData);
  }, [listData]);

  const column = [
    {
      name: "Ref No.",
      selector: (row) => row.Reference || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Created By",
      selector: (row) => row.Created_By || " - ",
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Unit No.",
      selector: (row) => row.Listings_UnitNo || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Project",
      selector: (row) => row.Project || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Deal Price",
      selector: (row) => row.Price || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Deposit",
      selector: (row) => row.Deposit || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Agent",
      selector: (row) => row.Agent1 || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Agency Fee Tenant",
      selector: (row) => row.Agency_fee_tenant || " - ",
      width: "130px",
      center: "true",
      wrap: true,
    },
    {
      name: "Deal Date",
      selector: (row) => row.Deal_Estimated_Date || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row.Listings_Category || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Beds",
      selector: (row) => row.Listings_Beds || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row.Area_Location || " - ",
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Sub Location",
      selector: (row) => row.Sub_Area_Location || " - ",
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Street No.",
      selector: (row) => row.Street_no || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Tanancy Renewal date",
      selector: (row) => row.Tenancy_Renewal_Date || " - ",
      width: "140px",
      center: "true",
      wrap: true,
    },
    {
      name: "View",
      cell: (row, index) => (
        <>
          <Link to={`/all-deals-details/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px;"} src="/svg/eye.png" alt="img-eye" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/all-deals-edit/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px;"} src="/svg/pencil.png" alt="img-pen" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <>
          <div
            onClick={() => {
              setShowDeleteModel(true);
              setDeleteId(row);
            }}
          >
            <img
              width={"20px;"}
              className="trash-image cursor-pointer"
              src="/svg/trash_images.png"
              alt="img-trash"
            />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
    },
  ];

  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  console.log("selectedDatesaaaaa", selectedDates);

  return (
    <>
      <div className="all-list-main-containner">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CommonDateRangePicker onApply={handleDateRangeApply} />
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_lead_type}
              placeholder={"Filter By Category"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_agent}
              placeholder={"Select Agent"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <CustomSelect
              options={listStaticSelectOptionsData.filter_by_sublocation}
              placeholder={"Select Status"}
              value={selectedValue}
              onChange={handleSelectChange}
            />
          </div>
        </div>
      </div>

      <div className="d-flex datatable-header-main">
        <Link to="/add-all-deals">
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="20px"
              alt="images"
            />
            Add New Deals
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : listData}
        customStyles={customStyles}
        columns={column}
        noHeader={false}
        highlightOnHover
        pagination
        searchable
        responsive
        noDataComponent="No data available"
      />

      {/* Delete Model... */}
      <div className="common-status-button-containner">
        <Modal
          show={showDeleteModel}
          handleClose={() => setShowDeleteModel(false)}
        >
          <div className="sdvds">
            <div className="text-center">
              <img
                className="commonstatusbutton-img "
                src="/svg/warning-animation.gif"
                alt="user"
              />
            </div>
            <h5 className="mb-5 text-center">
              Are you sure you want to delete lead ?
            </h5>

            <div className="modal-footer w-100 px-0">
              <button
                className="modal-footer-cancel-btn"
                onClick={() => setShowDeleteModel(false)}
              >
                Cancel
              </button>
              <button
                className="modal-footer-confirm-btn"
                onClick={() => {
                  deleteDeals(deleteId);
                  setShowDeleteModel(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AllDealsList;
