import React, { useState } from "react";
import { teamLeaderListData } from "../../../asset/StastucJsonData/teamLeaderJsonData";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { Link } from "react-router-dom";

const TeamLeaderList = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = teamLeaderListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Team Leader",
      selector: (row) => row?.team_leader,
      wrap: true,
    },
    {
      name: "Permition",
      cell: (row) => (
        <>
          <Link to={`/permission-team-leaders/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"30px"} src="/svg/permition-key.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/edit-team-leaders/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <Link to="/add-team-leaders">
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="20px"
              alt="images"
            />
            Add New Tenats
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>
      <div className="mt-2 mb-2">
        <DataTable
          data={filteredData ? filteredData : teamLeaderListData}
          noDataComponent="No data available"
          customStyles={customStyles}
          highlightOnHover
          noHeader={false}
          persistTableHead
          columns={column}
          pagination
          searchable
          responsive
        />
      </div>
    </>
  );
};

export default TeamLeaderList;
