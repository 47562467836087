import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { agentListData } from "../../../../asset/StastucJsonData/LocationJsonData";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  FormikInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const EditRole = () => {
  const [data, setData] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const findData = agentListData.find((item) => id === item?._id);
    setData(findData);
  };

  const initialValues = {
    role: data?.agent_name || "",
    email: data?.email || "",
    mobile_no: data?.mobile_no || "",
    user_name: data?.user_name || "",
    password: data?.password || "",
    dob: data?.dob || "",
    team_leader: "",
    work_area: "",
    permissions: data?.permissions || [
      {
        id: 1,
        name: "Rentals",
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        id: 2,
        name: "Sales",
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        id: 3,
        name: "Leads",
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        id: 4,
        name: "Old Leads",
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        id: 5,
        name: "Deals",
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
    ],
  };

  const validationSchema = Yup.object()
    .shape({
      role: Yup.string().required("Field is required."),
      email: Yup.string().required("Field is required."),
      mobile_no: Yup.string().required("Field is required."),
      user_name: Yup.string().required("Field is required."),
      password: Yup.string().required("Field is required."),
      dob: Yup.string().required("Field is required."),
      team_leader: Yup.string().required("Field is required."),
      work_area: Yup.string().required("Field is required."),
      permissions: Yup.array().of(
        Yup.object().shape({
          view: Yup.boolean(),
          add: Yup.boolean(),
          edit: Yup.boolean(),
          delete: Yup.boolean(),
        })
      ),
    })
    .defined();

  const handleubmitForm = (e) => {
    console.log(e);
  };
  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleubmitForm}
          enableReinitialize={true}
        >
          {({ values }) => {
            return (
              <>
                <Form>
                  <div className="row">
                    <FormikInputField
                      label="Role Name"
                      name="role"
                      placeholder="Enter Role Name"
                    />
                    <FormikInputField
                      label="Description"
                      name="description"
                      placeholder="Enter Description "
                    />

                    <FormikSelectField
                      label="Status"
                      name="is_activate"
                      options={[]}
                      placeholder=" - Select status - "
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover align-middle agent-permition-table-main-containner">
                      <thead>
                        <tr>
                          <th className="text-center set-permition-column-with">
                            Sr No.
                          </th>
                          <th className="text-center">Module</th>
                          <th className="text-center set-permition-column-with">
                            View
                          </th>
                          <th className="text-center set-permition-column-with">
                            Add
                          </th>
                          <th className="text-center set-permition-column-with">
                            Edit
                          </th>
                          <th className="text-center set-permition-column-with">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.permissions.map((module, index) => (
                          <tr key={module.id}>
                            <td>{index + 1}</td>
                            <td>{module.name}</td>
                            <td className="text-center">
                              <Field
                                type="checkbox"
                                name={`permissions.${index}.view`}
                                checked={values.permissions[index].view}
                              />
                            </td>
                            <td className="text-center">
                              <Field
                                type="checkbox"
                                name={`permissions.${index}.add`}
                                checked={values.permissions[index].add}
                              />
                            </td>
                            <td className="text-center">
                              <Field
                                type="checkbox"
                                name={`permissions.${index}.edit`}
                                checked={values.permissions[index].edit}
                              />
                            </td>
                            <td className="text-center">
                              <Field
                                type="checkbox"
                                name={`permissions.${index}.delete`}
                                checked={values.permissions[index].delete}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="cansel-submit-note-btn text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/role" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditRole;
