import { Form, Formik } from "formik";
import React from "react";
import {
  FormikCanselButton,
  FormikDateField,
  FormikInputField,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../../Common/FormikInputFields/CommonFromikFields";
import { useNavigate, useParams } from "react-router-dom";
import { DragAndDropComponent } from "../../../../Common/DragAndDropSingleImage";
import {
  addChequeInitialValues,
  addChequeValidationSchema,
} from "../../constant";

const AddCheque = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const handleSubmitForm = (e) => {
    console.info(e);
    if (e) navigate(`/all-listing/cheque/${id}`);
  };
  return (
    <>
      <div className="">
        <Formik
          initialValues={addChequeInitialValues}
          validationSchema={addChequeValidationSchema}
          onSubmit={handleSubmitForm}
        >
          {() => {
            return (
              <>
                <Form>
                  <div className="row">
                    <FormikInputField
                      label="Title"
                      placeholder="Enter Title"
                      name="title"
                    />
                    <FormikInputField
                      label="Cheque Number"
                      placeholder="Enter Cheque Number"
                      name="cheque_number"
                      type="number"
                    />
                    <FormikInputField
                      label="Details"
                      placeholder="Enter Details"
                      name="details"
                    />
                    <FormikDateField label="Date" name="date" />
                    <FormikSelectField
                      label="Type"
                      name="type"
                      options={[
                        { _id: "1", option: "Cheque" },
                        { _id: "2", option: "Case" },
                        { _id: "3", option: "EFT" },
                      ]}
                      placeholder="Select Type"
                    />
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                      <DragAndDropComponent name="cheque_image" />
                    </div>
                  </div>
                  <div className="text-center mt-7 mb-5">
                    <FormikSubmitButton />
                    <FormikCanselButton path={`/all-listing/cheque/${id}`} />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddCheque;
