import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../../Common/DataTableStyle/dataTableStyle";
import { developerListData } from "../../../../../asset/StastucJsonData/developerLogoJsonData";
import { useParams } from "react-router-dom";
import AddDocument from "../AddAndEditComponent/addDocument";
import EditDocument from "../AddAndEditComponent/editDocument";

const DocumentListPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState();
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [editId, setEditId] = useState("");
  const { id } = useParams();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = developerListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const getIdWiseData = (id) => {
    const findData = developerListData.find((item) => id === item?._id);
    setData(findData);
  };

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
    },
    {
      name: "title",
      selector: (row) => row.title || " - ",
      wrap: true,
      minWidht: "200px",
    },

    {
      name: "Image",
      selector: (row) => {
        return (
          <>
            <div className="p-3">
              <img
                width="100px"
                height="100px"
                src={row?.image_url}
                alt="imgs"
                className="rounded-circle"
              />
            </div>
          </>
        );
      },
      center: "true",
      wrap: true,
    },
    {
      name: "Show",
      selector: (row) => {
        return (
          <>
            <div className="p-3">
              <img width="100px" height="100px" src={row?.show} alt="imgs" />
            </div>
          </>
        );
      },
      center: "true",
      wrap: true,
    },
    {
      name: "Edit",
      selector: (row) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowEditModel(true);
                setEditId(row?._id);
              }}
            >
              <img width={"20px;"} src="/svg/pencil.png" alt="img-pen" />
            </div>
          </>
        );
      },
      center: "true",
      wrap: true,
      minWidth: "100px",
      width: "15%",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_button rounded-0 "
          onClick={() => {
            setShowAddModel(true);
          }}
        >
          <img
            className="mx-2"
            src="/svg/pluse_icon_button.png"
            width="20px"
            alt="images"
          />
          Add Document
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : developerListData}
        customStyles={customStyles}
        columns={column}
        noHeader={false}
        highlightOnHover
        persistTableHead
        pagination
        searchable
        responsive
        noDataComponent="No data available"
      />

      {showAddModel && (
        <AddDocument
          showAddModel={showAddModel}
          handleCloseAddModel={handleCloseAddModel}
          setShowAddModel={setShowAddModel}
        />
      )}
      {showEditModel && (
        <EditDocument
          editId={editId}
          showEditModel={showEditModel}
          handleCloseEditModel={handleCloseEditModel}
          setShowEditModel={setShowEditModel}
        />
      )}
    </>
  );
};

export default DocumentListPage;
