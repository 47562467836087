import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../Common/DataTableStyle/dataTableStyle";
import { viewListData } from "../../../../asset/StastucJsonData/ViewJsonData";

const ViewListPage = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = viewListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Property Refernce",
      selector: (row) => row?.property_refernce,
      center: "true",
      wrap: true,
    },
    {
      name: "Lead Refernce",
      selector: (row) => row?.lead_refernce,
      center: "true",
      wrap: true,
    },
    {
      name: "location",
      selector: (row) => row?.location,
      center: "true",
      wrap: true,
    },
    {
      name: "sublocation",
      selector: (row) => row?.sublocation,
      center: "true",
      wrap: true,
    },
    {
      name: "unitno",
      selector: (row) => row?.unitno,
      center: "true",
      wrap: true,
    },

    {
      name: "owners",
      selector: (row) => row?.owners,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Client Name",
      selector: (row) => row?.client_name,
      center: "true",
      wrap: true,
    },
    {
      name: "Client Email",
      selector: (row) => row?.client_mobile,
      center: "true",
      wrap: true,
      width: "200px",
    },
    {
      name: "Client Mobile",
      selector: (row) => row?.client_email,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Viewing Status",
      selector: (row) => row?.viewing_status,
      center: "true",
      wrap: true,
    },
    {
      name: "Agent Comment",
      selector: (row) => row?.agent_comment,
      center: "true",
      wrap: true,
      width: "200px",
    },
    {
      name: "Created By",
      selector: (row) => row?.createdBy,
      center: "true",
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => row?.created_at,
      center: "true",
      wrap: true,
      width: "200px",
    },
  ];

  return (
    <>
      <div className="my-5">
        <div className="data-table-single-search-input-box-container">
          <input
            type="text"
            placeholder="Search..."
            className=""
            value={searchQuery}
            onChange={handleSearch}
          />
          <i className="fa fa-search"></i>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : viewListData}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default ViewListPage;
