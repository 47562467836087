// NOTIFICATION STATIC LIST DATA...

export const notificationListData = [
  {
    _id: "1",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "2",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "3",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "4",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "5",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "6",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "7",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "8",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "9",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
  {
    _id: "10",
    title: "Lead Response Notification",
    description: "Prajeesh has been updated the Lead Ref. No. BY-L-06887 .",
    create_at: "5-Mar-2024",
  },
];
