import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const SuccessToast = (message) =>
  Toast.fire({
    icon: "success",
    title: message,
  });

export const ErrorToast = (message) =>
  Toast.fire({
    icon: "error",
    title: message,
  });

export const WarningToast = (message) =>
  Toast.fire({
    icon: "warning",
    title: message,
  });

export const InfoToast = (message) =>
  Toast.fire({
    icon: "info",
    title: message,
  });
