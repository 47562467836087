import React from "react";
import ChequeListPage from "./List/chequeList";
import HelmetComponent from "../../../Common/HelmetComponent";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";

const ChequePage = () => {
  return (
    <>
      <HelmetComponent title="Cheque" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Manage Cheque"
          home="Dashboard"
          currentPage="Cheque"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <ChequeListPage />
      </div>
    </>
  );
};

export default ChequePage;
