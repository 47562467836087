import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";

const CommonDateRangePicker = ({ onApply }) => {
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleApply = (e, picker) => {
    const startDate = picker.startDate
      ? picker.startDate.format("YYYY-MM-DD")
      : "";
    const endDate = picker.endDate ? picker.endDate.format("YYYY-MM-DD") : "";
    setDates({ startDate, endDate });
    onApply(startDate, endDate);
  };

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const displayValue =
    dates.startDate && dates.endDate
      ? `${dates.startDate} to ${dates.endDate}`
      : "";

  return (
    <>
      <div className="date_range_picker_containner">
        <DateRangePicker
          onApply={handleApply}
          initialSettings={{
            startDate: moment(),
            endDate: moment(),
            ranges: ranges,
            autoUpdateInput: false,
          }}
        >
          <input
            type="text"
            value={displayValue}
            placeholder="yyyy-mm-dd"
            className="form-control"
            readOnly
          />
        </DateRangePicker>
        <img
          src="/svg/calendar.png"
          className="calendar-icon"
          onClick={() =>
            (document.querySelector(".daterangepicker").style.display = "block")
          }
        />
      </div>
    </>
  );
};

export default CommonDateRangePicker;
