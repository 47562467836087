import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  countryOptionData,
  locationListData,
} from "../../../asset/StastucJsonData/LocationJsonData";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import Modal from "../../Common/Model/ModelCommon";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../Common/Select2Box/customSelectbox";

const ProjectList = () => {
  // declare state...
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getDataIdWise, setGetDataIdWise] = useState([]);
  const [selectId, setSelectId] = useState();

  // validation...
  const validationSchema = Yup.object({
    location: Yup.string().required("Please location is required."),
    sublocation: Yup.string().required("Please sublocation is required."),
    project: Yup.string().required("Please project is required."),
    country: Yup.string().required("Please country is required."),
  });
  // Handle Submit Add...
  const handleAddProject = (e) => {
    console.log("add data", e);
    setShowAddModel(false);
  };
  // Hendle search functionality...
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = locationListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Add and Edit location model close functionality....

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };
  const handleChangeCountry = (e) => {
    console.info(e);
  };

  // Get data id wise...

  const getIdWiseData = (id) => {
    const findData = locationListData.find((user_id) => id === user_id?._id);
    setGetDataIdWise(findData);
  };

  // Use Effect...
  useEffect(() => {
    getIdWiseData(selectId);
  }, [selectId]);

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      width: "100px",
      center: "true",
      wrap: true,
    },
    {
      name: "Project",
      cell: (row) => <div className="pl-2">{row?.project}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Sub Location",
      cell: (row) => <div className="pl-2">{row?.sub_location}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Location",
      cell: (row) => <div className="pl-2">{row?.location}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Country",
      cell: (row) => <div className="pl-2">{row?.country}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowEditModel(true);
            setSelectId(row?._id);
          }}
        >
          <img width={"20px"} src="/svg/pencil.png" alt="img" />
        </div>
      ),
      width: "100px",
      center: "true",
      wrap: true,
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <img
            className="mx-2"
            src="/svg/pluse_icon_button.png"
            width="20px"
            alt="images"
          />
          Add New Project
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        columns={column}
        data={filteredData ? filteredData : locationListData}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />

      {/* Add model */}
      <div className="model_inner">
        <Modal show={showAddModel} handleClose={handleCloseAddModel}>
          <div className="sdvds">
            <h5 className="mb-5 text-center">Add Project</h5>
            <Formik
              initialValues={{
                location: "",
                sublocation: "",
                project: "",
                country: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleAddProject}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Country</p>
                    <CustomSelect
                      options={countryOptionData}
                      placeholder={"Select TeamLead"}
                      value=""
                      onChange={handleChangeCountry}
                      name="country"
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Location</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="location"
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Sub Location</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="sublocation"
                    />
                    <ErrorMessage
                      name="sublocation"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Project</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="project"
                    />
                    <ErrorMessage
                      name="project"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-secondary">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
      {/* Edit Model */}
      <div className="model_inner">
        <Modal show={showEditModel} handleClose={handleCloseEditModel}>
          <div className="sdvds">
            <h5 className="mb-5 text-center">Edit Project</h5>
            <Formik
              initialValues={{
                location: getDataIdWise?.location || "",
                sublocation: getDataIdWise?.sub_location || "",
                project: getDataIdWise?.project || "",
                country: "Dubai",
              }}
              validationSchema={validationSchema}
              onSubmit={handleAddProject}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Country</p>
                    <CustomSelect
                      options={countryOptionData}
                      placeholder={"Select TeamLead"}
                      value=""
                      onChange={handleChangeCountry}
                      name="country"
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Location</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="location"
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Sub Location</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="sublocation"
                    />
                    <ErrorMessage
                      name="sublocation"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Project</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="project"
                    />
                    <ErrorMessage
                      name="project"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-secondary">
                      {isSubmitting ? "Submiting..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ProjectList;
