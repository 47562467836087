import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import Modal from "../../Common/Model/ModelCommon";
import { salesOfferListData } from "../../../asset/StastucJsonData/salesOfferJsonData";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikInputField,
  FormikSelectField,
  FormikSubmitButton,
} from "../../Common/FormikInputFields/CommonFromikFields";

const SalesOfferList = () => {
  // declare state...
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getDataIdWise, setGetDataIdWise] = useState([]);
  const [selectId, setSelectId] = useState();

  const initialValues = {
    project_logo: "",
    developer_logo: "",
    ref_no: "",
    unit_no: "",
    policy_type: "",
    account_name: "",
    bank: "",
    account_no: "",
    swift_code: "",
  };

  const validationSchema = Yup.object()
    .shape({
      project_logo: Yup.string().required("Field is required."),
      developer_logo: Yup.string().required("Field is required."),
      ref_no: Yup.string().required("Field is required."),
      unit_no: Yup.string().required("Field is required."),
      policy_type: Yup.string().required("Field is required."),
      account_name: Yup.string().required("Field is required."),
      bank: Yup.string().required("Field is required."),
      account_no: Yup.string().required("Field is required."),
      swift_code: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmitAddForm = (e) => {
    console.log(e);
    setShowAddModel(false);
  };

  // Hendle search functionality...

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = salesOfferListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Add and Edit location model close functionality....

  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  // Get data id wise...

  const getIdWiseData = (id) => {
    const findData = salesOfferListData.find((user_id) => id === user_id?._id);
    setGetDataIdWise(findData);
  };

  console.log(getDataIdWise);

  // Use Effect...
  useEffect(() => {
    getIdWiseData(selectId);
  }, [selectId]);

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Ref No.",
      cell: (row) => <div className="pl-2">{row?.ref_no}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Unit Number",
      cell: (row) => <div className="pl-2">{row?.unit_no}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <div
            onClick={() => {
              setShowEditModel(true);
              setSelectId(row?._id);
            }}
            className="cursor-pointer"
          >
            <img width={"20px;"} src="/svg/pencil.png" alt="img" />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "View PDF",
      cell: (row) => (
        <>
          <div className="cursor-pointer">
            <img width={"20px;"} src="/svg/file.png" alt="img" />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <img
            className="mx-2"
            src="/svg/pluse_icon_button.png"
            width="20px"
            alt="images"
          />
          Add Sales Offer
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        columns={column}
        data={filteredData ? filteredData : salesOfferListData}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />

      {/* Add model */}

      <Modal show={showAddModel} handleClose={handleCloseAddModel}>
        <div className="sdvds">
          <h5 className="mb-5 text-center fw-bold">Add Sales Offer</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitAddForm}
          >
            {() => {
              return (
                <>
                  <Form>
                    <div className="row">
                      <FormikSelectField
                        label="Project Logo"
                        name="project_logo"
                        options={[]}
                      />
                      <FormikSelectField
                        label="Developer Logo"
                        name="developer_logo"
                        options={[]}
                      />
                      <FormikInputField label="Ref No." name="ref_no" />
                      <FormikSelectField
                        label=" Unit No."
                        name="unit_no"
                        options={[]}
                      />
                      <FormikInputField
                        label="Policy Type"
                        name="policy_type"
                      />
                      <FormikInputField
                        label="Account Name"
                        name="account_name"
                      />
                      <FormikInputField label="Bank" name="bank" />
                      <FormikInputField label="Account No." name="account_no" />
                      <FormikInputField label="Swift Code" name="swift_code" />
                    </div>
                    <div className="text-center mt-5 mb-5">
                      <FormikSubmitButton />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>

      {/* Edit Model */}

      <Modal show={showEditModel} handleClose={handleCloseEditModel}>
        <div className="sdvds">
          <h5 className="mb-5 text-center fw-bold">Edit Sales Offer</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitAddForm}
          >
            {() => {
              return (
                <>
                  <Form>
                    <div className="row">
                      <FormikSelectField
                        label="Project Logo"
                        name="project_logo"
                        options={[]}
                      />
                      <FormikSelectField
                        label="Developer Logo"
                        name="developer_logo"
                        options={[]}
                      />
                      <FormikInputField label="Ref No." name="ref_no" />
                      <FormikSelectField
                        label=" Unit No."
                        name="unit_no"
                        options={[]}
                      />
                      <FormikInputField
                        label="Policy Type"
                        name="policy_type"
                      />
                      <FormikInputField
                        label="Account Name"
                        name="account_name"
                      />
                      <FormikInputField label="Bank" name="bank" />
                      <FormikInputField label="Account No." name="account_no" />
                      <FormikInputField label="Swift Code" name="swift_code" />
                    </div>
                    <div className="text-center mt-5 mb-5">
                      <FormikSubmitButton />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default SalesOfferList;
