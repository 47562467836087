import React, { useEffect, useState } from "react";
import { oldDealsListData } from "../../../../asset/StastucJsonData/OldDealsJsonData";
import { useParams } from "react-router-dom";
import DetailComponent from "../../../Common/DetailsPageComponent/detailComponent";

const OldDealsDetails = () => {
  const [getIdWiseData, setGetIdWiseData] = useState([]);

  let { id } = useParams();

  const getIdWiseDatas = (id) => {
    const findData = oldDealsListData.find((item) => id === item?._id);
    setGetIdWiseData(findData);
  };

  useEffect(() => {
    getIdWiseDatas(id);
  }, [id]);

  return (
    <>
      <div className="container">
        <DetailComponent
          title="Created By :"
          title1="Unit No. :"
          title2="Project :"
          title3="Deal Price :"
          data={getIdWiseData?.Created_By}
          data1={getIdWiseData?.Listings_UnitNo}
          data2={getIdWiseData?.Project}
          data3={getIdWiseData?.Price}
        />
        <DetailComponent
          title="Agent :"
          title1="Deal Date :"
          title2="Category :"
          title3="Beds :"
          data={getIdWiseData?.Agent1}
          data1={getIdWiseData?.Actual_Deal_Date}
          data2={getIdWiseData?.Listings_Category}
          data3={getIdWiseData?.Listings_Beds}
        />
        <DetailComponent
          title="Location :"
          title1="Sub Location :"
          title2="Tenancy Renewal Date :"
          title3=""
          data={getIdWiseData?.Area_Location}
          data1={getIdWiseData?.Sub_Area_Location}
          data2={getIdWiseData?.Tenancy_Renewal_Date}
          data3=""
        />
      </div>
    </>
  );
};

export default OldDealsDetails;
