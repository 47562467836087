// static data for show table data...

export const getOwnnerData = [
  {
    user_id: "1",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Ajim",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "2",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "3",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "4",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "5",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "6",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "7",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "8",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "9",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "10",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "11",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "azim",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
  {
    user_id: "12",
    sr_status: false,
    ref_no: "BY-L-13432",
    property_ref_no: "BY-L-13432",
    date_time: "11-Jul-2024",
    status: "open",
    s_status: "Not Yet Contacted",
    project: "ELO by DAMAC",
    name: "Yamen",
    mobile: "+971566198581",
    email: "stephantjiook@hotmail.com",
    category: "Retail",
    location: "Not Available",
    s_location: "Not Available",
    agent: "Nirmal Nair",
    teem_leader: "Wajiha Aftab",
    souece: "Bayut",
    unit_no: "546352342",
    price: "999",
    country: "india",
    transcript: "xaybcz",
    notes: "Hi every one...",
  },
];

// Static data for show list page...

export const listStaticSelectOptions = [
  {
    filter_by_agent3: [
      {
        id: 1,
        option: "Ahmed",
      },
      {
        id: 2,
        option: "Ahmed A.",
      },
      {
        id: 3,
        option: "Ahmmed M",
      },
      {
        id: 4,
        option: "Anastasiia Savaryna",
      },
      {
        id: 5,
        option: "Angel",
      },
      {
        id: 6,
        option: "Ashwin Anil",
      },
      {
        id: 7,
        option: "Aswin Prasaad",
      },
    ],
    filter_by_team_lead1: [
      {
        id: 1,
        option: "Aswin Prasaad",
      },
      {
        id: 2,
        option: "Binayah Support",
      },
      {
        id: 3,
        option: "Hardip Gandara",
      },
      {
        id: 4,
        option: "Masood Ahmad",
      },
      {
        id: 5,
        option: "Michel",
      },
    ],

    filter_by_team_lead: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "Property Finder",
      },
      {
        id: 3,
        option: "Bayut",
      },
      {
        id: 4,
        option: "Bayut - Call",
      },
      {
        id: 5,
        option: "Bayut - Email",
      },
      {
        id: 6,
        option: "Bayut - SMS",
      },
    ],
    filter_by_status: [
      {
        id: 1,
        option: "Open",
      },
      {
        id: 2,
        option: "Closed",
      },
      {
        id: 3,
        option: "Not Specified",
      },
    ],
    filter_by_sub_status: [
      {
        id: 1,
        option: "Inprogress",
      },
      {
        id: 2,
        option: "Successful",
      },
      {
        id: 3,
        option: "Unsuccessful",
      },
      {
        id: 4,
        option: "Called No Reply",
      },
      {
        id: 5,
        option: "Follow UP",
      },
      {
        id: 6,
        option: "Viewing arranged",
      },
      {
        id: 7,
        option: "Not Specified",
      },
      {
        id: 8,
        option: "Offer Made",
      },
      {
        id: 9,
        option: "Needs More Info",
      },
      {
        id: 10,
        option: "Budge Differs",
      },
    ],
    filter_by_lead_type: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "Tenant",
      },
      {
        id: 3,
        option: "Buyer",
      },
      {
        id: 4,
        option: "Landlord",
      },
      {
        id: 5,
        option: "Seller",
      },
      {
        id: 6,
        option: "Landlord+Seller",
      },
      {
        id: 7,
        option: "Investor",
      },
      {
        id: 8,
        option: "Not Specified",
      },
    ],
    filter_by_lead_channel: [
      { id: 1, option: "Bayut Leads" },
      { id: 2, option: "WA Chat Bot Lead" },
      { id: 3, option: "Website Leads" },
      { id: 4, option: "Live Chat Leads" },
      { id: 5, option: "PropertyFinder Leads" },
    ],
    filter_by_lead_badrooms: [
      { id: 1, option: "Studio" },
      { id: 2, option: "1 Bed" },
      { id: 3, option: "2 Bed" },
      { id: 4, option: "3 Bed" },
      { id: 5, option: "4 Bed" },
      { id: 6, option: "5 Bed" },
      { id: 7, option: "6 Bed" },
      { id: 8, option: "7 Bed" },
    ],
    filter_by_lead_emirates: [
      { id: 1, option: "Dubai" },
      { id: 2, option: "Abu Dhabi" },
      { id: 3, option: "Sharjah" },
      { id: 4, option: "Ajman" },
      { id: 5, option: "Umm Al Quwain" },
      { id: 6, option: "Ras Al Khaimah" },
      { id: 7, option: "Fujairah" },
      { id: 8, option: "Al Ain" },
    ],
    filter_by_lead_location: [
      { id: 1, option: "Abu Dhabi Gate City" },
      { id: 2, option: "Abu Shagara" },
      { id: 3, option: "Academic City" },
      { id: 4, option: "Afnan District" },
      { id: 5, option: "Ain Ajman" },
      { id: 6, option: "Ain Al Faydah" },
    ],
    filter_by_sublocation: [
      { id: 1, option: "1 JBR" },
      { id: 2, option: "10C Building" },
      { id: 3, option: "15 Northside" },
      { id: 4, option: "151 Residence" },
      { id: 5, option: "17 Icon Bay" },
      { id: 6, option: "18 Villas Complex" },
      { id: 7, option: "2 Bedroom Villas" },
    ],
    filter_by_price_range: [
      { id: 1, option: "AED 30-50k" },
      { id: 2, option: "AED 50-100k" },
      { id: 3, option: "AED 100-200" },
      { id: 4, option: "AED 200-500k" },
    ],
    filter_by_category: [
      { id: 1, option: "Villa (CR)" },
      { id: 2, option: "Penthouse (RR)" },
      { id: 3, option: "Duplex (RR)" },
      { id: 4, option: "Office (RR)" },
      { id: 5, option: "Retail (CR)" },
      { id: 6, option: "Hotel Apartment (CS)" },
    ],
    filter_by_agent: [
      { id: 1, option: "Ahmed" },
      { id: 2, option: "Ahmed A." },
      { id: 3, option: "Ahmmed M" },
      { id: 4, option: "Anastasiia Savaryna" },
      { id: 5, option: "Angel" },
      { id: 6, option: "Aswin Prasaad" },
    ],
    filter_by_team_lead2: [
      { id: 1, option: "Aswin Prasaad" },
      { id: 2, option: "Binayah Support" },
      { id: 3, option: "Hardip Gandara" },
      { id: 4, option: "Masood Ahmad" },
      { id: 5, option: "Michel" },
    ],
    filter_by_open: [
      { id: 0, option: "Open" },
      { id: 1, option: "Close" },
      { id: 2, option: "No Spacified" },
    ],
    filter_by_substatus: [
      {
        id: 1,
        option: "Inprogress",
      },
      {
        id: 2,
        option: "Successful",
      },
      {
        id: 3,
        option: "Unsuccessful",
      },
      {
        id: 4,
        option: "Called No Reply",
      },
      {
        id: 5,
        option: "Follow UP",
      },
      {
        id: 6,
        option: "Viewing arranged",
      },
      {
        id: 7,
        option: "Not Specified",
      },
    ],
  },
];

// Static data for show stastic options in add and edit component select box....

export const getSelectBoxOptionsData = [
  {
    property_refference_number: [
      {
        id: 1,
        option: "BY-R-13849",
      },
      {
        id: 2,
        option: "BY-R-13847",
      },
      {
        id: 3,
        option: "BY-R-13846",
      },
    ],
    created_by: [
      {
        id: 1,
        option: "Sheena",
      },
      {
        id: 2,
        option: "Von",
      },
    ],
    lead_type: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "Tenant",
      },
      {
        id: 3,
        option: "Buyer",
      },
      {
        id: 4,
        option: "Landlord",
      },
      {
        id: 5,
        option: "Seller",
      },
      {
        id: 6,
        option: "Landlord+Seller",
      },
      {
        id: 7,
        option: "Investor",
      },
      {
        id: 8,
        option: "Not Specified",
      },
    ],
    source_options: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "PropertyFinder",
      },
      {
        id: 3,
        option: "Buyut",
      },
      {
        id: 4,
        option: "Dubizzle",
      },
      {
        id: 5,
        option: "Call",
      },
      {
        id: 6,
        option: "Email",
      },
      {
        id: 7,
        option: "Call",
      },
      {
        id: 8,
        option: "WA Chat Boot",
      },
      {
        id: 9,
        option: "Facebook",
      },
      {
        id: 10,
        option: "Website",
      },
      {
        id: 11,
        option: "Instagram",
      },
      {
        id: 12,
        option: "Reference",
      },
      {
        id: 13,
        option: "Walk in",
      },
      {
        id: 14,
        option: "Live Chat",
      },
      {
        id: 15,
        option: "Other Online",
      },
    ],
    agent_options: [
      { id: 1, option: "Agent" },
      { id: 2, option: "Tenant" },
      { id: 3, option: "Buyer" },
      { id: 4, option: "Landlord" },
      { id: 5, option: "Seller" },
      { id: 6, option: "Landlord+Seller" },
      { id: 7, option: "Investor" },
      { id: 8, option: "Not Specified" },
    ],
    team_leader: [
      { id: 1, option: "Shahbaz" },
      { id: 2, option: "Shubhan Ahamad" },
      { id: 3, option: "Talal Ahamad" },
      { id: 4, option: "Sheena Mae" },
      { id: 5, option: "Binayah Support" },
      { id: 6, option: "Masood Ahamad" },
      { id: 7, option: "Hardip Gandara" },
      { id: 8, option: "Usama Kalyar" },
      { id: 9, option: "Ashvin Prashad" },
      { id: 10, option: "Yashir Ahamad" },
      { id: 11, option: "Ryan Gifford" },
      { id: 12, option: "Wajiha Aftab" },
      { id: 13, option: "Wajiha Aftab" },
      { id: 14, option: "Michel" },
    ],
    ownner_option: [
      {
        id: 1,
        option: "Agent",
      },
      {
        id: 2,
        option: "Tenant",
      },
      {
        id: 3,
        option: "Buyer",
      },
      {
        id: 4,
        option: "Landlord",
      },
      {
        id: 5,
        option: "Seller",
      },
      {
        id: 6,
        option: "Landlord+Seller",
      },
      {
        id: 7,
        option: "Investor",
      },
      {
        id: 8,
        option: "Not Specified",
      },
    ],
    status_option: [
      {
        id: 1,
        option: "Open",
      },
      {
        id: 2,
        option: "Close",
      },
      {
        id: 3,
        option: "No Specified",
      },
    ],
    sub_status_option: [
      {
        id: 1,
        option: "Inprogress",
      },
      {
        id: 2,
        option: "Successful",
      },
      {
        id: 3,
        option: "Unsuccessful",
      },
      {
        id: 4,
        option: "Not yet Contacted",
      },
    ],

    priority_option: [
      {
        id: 1,
        option: "Urgent",
      },
      {
        id: 2,
        option: "High",
      },
      {
        id: 3,
        option: "Low",
      },
      {
        id: 4,
        option: "Normal",
      },
    ],
    hot_load_option: [
      {
        id: 1,
        option: "Yes",
      },
      {
        id: 2,
        option: "No",
      },
    ],
    type_option: [
      {
        id: 1,
        option: "Rent",
      },
      {
        id: 2,
        option: "Sale",
      },
    ],
    category_option: [
      { id: 1, option: "Villa (CR)" },
      { id: 2, option: "Penthouse (RR)" },
      { id: 3, option: "Duplex (RR)" },
      { id: 4, option: "Office (RR)" },
      { id: 5, option: "Retail (CR)" },
      { id: 6, option: "Hotel Apartment (CS)" },
    ],

    bed_rooms_option: [
      {
        id: 1,
        option: "1 Bed",
      },
      {
        id: 2,
        option: "2 Beds",
      },
      {
        id: 3,
        option: "3 Beds",
      },
      {
        id: 4,
        option: "4 Beds",
      },
      {
        id: 5,
        option: "5 Beds",
      },
      {
        id: 6,
        option: "6 Beds",
      },
      {
        id: 7,
        option: "7 Beds",
      },
      {
        id: 8,
        option: "8 Beds",
      },
      {
        id: 9,
        option: "9 Beds",
      },
      {
        id: 10,
        option: "10 Beds",
      },
      {
        id: 11,
        option: "11 Beds",
      },
      {
        id: 12,
        option: "12 Beds",
      },
      {
        id: 13,
        option: "13 Beds",
      },
      {
        id: 14,
        option: "14 Beds",
      },
      {
        id: 15,
        option: "15 Beds",
      },
    ],

    emirates_options: [
      {
        id: 1,
        option: "Dubai",
      },
      {
        id: 2,
        option: "Abu Dhabi",
      },
      {
        id: 3,
        option: "Sharjah",
      },
      {
        id: 4,
        option: "Ajman",
      },
      {
        id: 5,
        option: "Umm Al Quwain",
      },
      {
        id: 6,
        option: "Ras Al Khaimah",
      },
      {
        id: 7,
        option: "Fujairah",
      },
      {
        id: 8,
        option: "Al Ain",
      },
    ],
    project_option: [
      {
        id: 1,
        option: "1 Residences",
      },
      {
        id: 2,
        option: "2 Residences",
      },
      {
        id: 3,
        option: "3 Residences",
      },
      {
        id: 4,
        option: "4 Residences",
      },
      {
        id: 5,
        option: "5 Residences",
      },
      {
        id: 6,
        option: "6 Residences",
      },
      {
        id: 7,
        option: "7 Residences",
      },
      {
        id: 8,
        option: "8 Residences",
      },
      {
        id: 9,
        option: "9 Residences",
      },
      {
        id: 10,
        option: "10 Residences",
      },
    ],

    location_option: [
      { id: 1, option: "Dubai" },
      { id: 2, option: "Abu Dhabi" },
      { id: 3, option: "Sharjah" },
      { id: 4, option: "Ajman" },
      { id: 5, option: "Umm Al Quwain" },
      { id: 6, option: "Ras Al Khaimah" },
      { id: 7, option: "Fujairah" },
      { id: 8, option: "Al Ain" },
    ],

    sub_location_option: [
      { id: 1, option: "Dubai" },
      { id: 2, option: "Abu Dhabi" },
      { id: 3, option: "Sharjah" },
      { id: 4, option: "Ajman" },
      { id: 5, option: "Umm Al Quwain" },
      { id: 6, option: "Ras Al Khaimah" },
      { id: 7, option: "Fujairah" },
      { id: 8, option: "Al Ain" },
    ],
  },
];

// Static data for show Note History...

export const noteHistoryDate = [
  {
    user: "Ibrahim",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Hamza",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ahad",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ashd",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "ahmed",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Gani",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },

  {
    user: "Ibrahim",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Hamza",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ahad",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ashd",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "ahmed",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Gani",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },

  {
    user: "Ibrahim",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Hamza",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ahad",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ashd",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "ahmed",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Gani",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },

  {
    user: "Ibrahim",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Hamza",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ahad",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Ashd",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "ahmed",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
  {
    user: "Gani",
    screen: "Leads",
    field: "--",
    value: "--",
    action: "insert",
    date: "25/07/24 10:11:10 AM",
  },
];
