import React, { useState, useRef } from "react";
import useTimer from "../../../Hooks/otpTimer.js";

const OtpForm = ({ onSubmit, resendOtp }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isResending, setIsResending] = useState(false);

  const { timeLeft, isActive, startTimer } = useTimer(30);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 4) {
      onSubmit(otpValue);
    }
    console.log("otpValue", otpValue);
  };

  const handleResend = async () => {
    setIsResending(true);
    await resendOtp();
    setIsResending(false);
    startTimer(30);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <form className="otp-form" onSubmit={handleSubmit}>
      <p className="show_send_email">Enter the OTP send to admin@example.com</p>
      <div className="otp-inputs">
        {otp.map((value, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className="otp-input"
            required
          />
        ))}
      </div>
      <p className="show_resend_otp_btns_timer">
        Didn't receive the code?{" "}
        <button
          className="resend_otp_btns"
          onClick={handleResend}
          disabled={timeLeft}
          type="button"
        >
          {isResending
            ? "Resending..."
            : isActive
            ? `Resend OTP in ${timeLeft}s`
            : "Resend OTP"}
        </button>
      </p>
      <div className="otp_btn_conatinner">
        <button type="submit" className="submit-btn">
          Submit OTP
        </button>
      </div>
    </form>
  );
};

export default OtpForm;
