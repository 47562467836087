import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import PermissionTeamLead from "./PermissionTeamLead";
import HelmetComponent from "../../../Common/HelmetComponent";

const PermissionTeamLeader = () => {
  return (
    <>
      <HelmetComponent title="Team-Leader-Permission" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Permission Team Leader"
          home="Dashboard"
          currentPage="Permission Team Leader"
          addExtraPageTitle="Team Leader"
          addExtraPageTitlePath="team-leaders"
        />
        <PermissionTeamLead />
      </div>
    </>
  );
};

export default PermissionTeamLeader;
