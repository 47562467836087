import React from "react";
import { Helmet } from "react-helmet";

const HelmetComponent = ({ title }) => {
  const appName = process.env.APP_NAME || "Binayah";
  return (
    <>
      <Helmet>
        <title>{`${appName} | ${title}`}</title>
      </Helmet>
    </>
  );
};

export default HelmetComponent;
