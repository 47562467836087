import React from "react";
import { addDealsOptionsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikCanselButton,
  FormikDateField,
  FormikInputField,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const AddUnitModel = () => {
  const getDealsOptionsData = addDealsOptionsData[0] || [];

  const initialValues = {
    project: "",
    estimated_completion_date: "",
    type: "",
    property_status: "",
    totle_area: "",
    selling_price: "",
    instalments: [
      {
        installment: "",
        percentage: "",
        date: "",
        amount_aed: "",
      },
    ],
    unit_no: "",
    unit_type: "",
    unit_size: "",
    price_per_ft: "",
    sales_price: "",
    down_payment: "",
    three_month: "",
    six_month: "",
    handover: "",
    three_year_plan: "",
    images: "",
  };

  const validationSchema = Yup.object().shape({
    project: Yup.string().required("Field is required."),
    estimated_completion_date: Yup.date()
      .nullable()
      .required("Date is required."),
    type: Yup.string().required("Field is required."),
    property_status: Yup.string().required("Field is required."),
    totle_area: Yup.string().required("Field is required."),
    selling_price: Yup.string().required("Field is required."),
    instalments: Yup.array()
      .min(1, "At least one instalment is required.")
      .max(5, "A maximum of 5 instalments is allowed.")
      .of(
        Yup.object().shape({
          installment: Yup.string().required("Installment number is required."),
          percentage: Yup.string().required("Percentage is required."),
          date: Yup.string().required("Date is required."),
          amount_aed: Yup.string().required("Amount (AED) is required."),
        })
      ),
    unit_no: Yup.string().required("Field is required."),
    unit_type: Yup.string().required("Field is required."),
    unit_size: Yup.string().required("Field is required."),
    price_per_ft: Yup.string().required("Field is required."),
    sales_price: Yup.string().required("Field is required."),
    down_payment: Yup.string().required("Field is required."),
    three_month: Yup.string().required("Field is required."),
    six_month: Yup.string().required("Field is required."),
    handover: Yup.string().required("Field is required."),
    three_year_plan: Yup.string().required("Field is required."),
    images: Yup.mixed().required("Image is required."),
  });

  return (
    <div className="add-unit-model-containner px-3 py-5">
      <div className="mb-4 border-bottom-1">
        Fields Marked with (<span className="text-danger"> * </span>) are
        Mandatory
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("Form Values: ", values);
        }}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row d-flex mb-3">
              <FormikInputField label="Project" name="project" />
              <FormikDateField
                label="Estimated Completion Date"
                name="estimated_completion_date"
              />
              <FormikSelectField
                label="Type"
                name="type"
                options={getDealsOptionsData?.select_by_type}
              />
              <FormikSelectField
                label="Property status"
                name="property_status"
                options={getDealsOptionsData?.select_by_type}
              />
              <FormikInputField label="Total Area(SQ. FT)" name="totle_area" />
              <FormikInputField
                label="Selling Price (AED)"
                name="selling_price"
              />
            </div>

            <div className="my-1 mb-3 border border-gray border-2 p-2">
              <div className="my-3">
                <h3>Instalments</h3>
              </div>
              <FieldArray name="instalments">
                {({ insert, remove, push }) => (
                  <>
                    {values?.instalments?.length < 5 && (
                      <div className="mt-5 mb-3">
                        <button
                          className="success-btn mx-2"
                          type="button"
                          onClick={() =>
                            push({
                              installment: "",
                              percentage: "",
                              date: "",
                              amount_aed: "",
                            })
                          }
                        >
                          Add Row
                        </button>
                      </div>
                    )}
                    {values?.instalments?.length >= 5 && (
                      <div className="mt-5 mb-3 text-danger">
                        A maximum of 5 instalments is allowed.
                      </div>
                    )}
                    {values?.instalments.map((instalment, index) => (
                      <>
                        <div className="row" key={index}>
                          <FormikInputField
                            label="Installment"
                            name={`instalments.${index}.installment`}
                          />
                          <FormikInputField
                            label="Percentage (%)"
                            name={`instalments.${index}.percentage`}
                          />
                          <FormikDateField
                            label="Date"
                            name={`instalments.${index}.date`}
                          />
                          <FormikInputField
                            label="Amount (AED)"
                            name={`instalments.${index}.amount_aed`}
                          />
                        </div>
                        <button
                          className="my-2 ml-2 btn btn-light border border-danger bg-light"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          Delete Row
                        </button>
                      </>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>

            <div className="my-1 mb-3">
              <div className="my-3">
                <h3>Unit Model</h3>
              </div>
              <div className="row">
                <FormikInputField label="Unit No" name="unit_no" />
                <FormikInputField label="Unit Type" name="unit_type" />
                <FormikInputField label="Unit Size" name="unit_size" />
                <FormikInputField label="Price Per FT" name="price_per_ft" />
                <FormikInputField label="Sales Price" name="sales_price" />
                <FormikInputField label="Down Payment" name="down_payment" />
                <FormikInputField label="3 month" name="three_month" />
                <FormikInputField label="6 month" name="six_month" />
                <FormikInputField label="Handover" name="handover" />
                <FormikInputField label="3 Years Plan" name="three_year_plan" />
                <FormikInputField label="Image" name="images" type="file" />
              </div>
            </div>

            <div className="text-center mt-5 mb-3">
              <FormikSubmitButton />
              <FormikCanselButton path="/create-project" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddUnitModel;
