import React, { useState } from "react";
import { CalendarListData } from "../../../asset/StastucJsonData/CalendarListJsonData";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { Link } from "react-router-dom";

const CalendarList = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = CalendarListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
    },
    {
      name: "Property Ref No.",
      selector: (row) => row?.property_ref_no || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Event",
      selector: (row) => row?.event || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row?.date || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Time",
      selector: (row) => row?.time || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status || " - ",
      center: "true",
      wrap: true,
    },

    {
      name: "Agent",
      selector: (row) => row?.agent || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "View",
      cell: (row) => (
        <>
          <Link to={`/calendar/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px;"} src="/svg/eye.png" alt="img" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
    },
  ];

  return (
    <>
      <div className="my-3">
        <div className="data-table-single-search-input-box-container">
          <input
            type="text"
            placeholder="Search..."
            className=""
            value={searchQuery}
            onChange={handleSearch}
          />
          <i className="fa fa-search"></i>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : CalendarListData}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default CalendarList;
