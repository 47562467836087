// import React, { useState } from "react";
// import { useField, useFormikContext } from "formik";
// import "../../../asset/css/common/dragAndDrop.css";

// export const DragAndDropComponent = ({ name, label = "Upload Image" }) => {
//   const { setFieldValue } = useFormikContext();
//   const [field, meta] = useField(name);
//   const [image, setImage] = useState(field.value || null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(false);

//   const handleFileUpload = (event) => {
//     const files = event.target.files;
//     handleFiles(files);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     e.target.style.borderColor = "#4a90e2";
//   };

//   const handleDragLeave = (e) => {
//     e.preventDefault();
//     e.target.style.borderColor = "#cccccc";
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.target.style.borderColor = "#cccccc";
//     const files = e.dataTransfer.files;
//     handleFiles(files);
//   };

//   const handleFiles = (files) => {
//     if (files.length > 0) {
//       const file = files[0];
//       if (validateFileType(file)) {
//         setLoading(true);
//         setTimeout(() => {
//           displayImage(file);
//           setLoading(false);
//         }, 1000);
//         setError(false);
//         setFieldValue(name, file);
//       } else {
//         setError(true);
//       }
//     }
//   };

//   const validateFileType = (file) => {
//     const validTypes = ["image/jpeg", "image/png"];
//     return validTypes.includes(file.type);
//   };

//   const displayImage = (file) => {
//     const reader = new FileReader();
//     reader.onload = function (e) {
//       setImage(e.target.result);
//     };
//     reader.readAsDataURL(file);
//   };

//   const removeImage = () => {
//     setImage(null);
//     setFieldValue(name, null);
//   };

//   console.log(field);

//   return (
//     <>
//       <p>
//         {label} <span className="text-danger">*</span>
//       </p>
//       <div className="drag_and_drop_image_containner">
//         <div className="main_profile">
//           <div className="bg_profile_img">
//             <div
//               className="upload-container"
//               onDragOver={handleDragOver}
//               onDragLeave={handleDragLeave}
//               onDrop={handleDrop}
//             >
//               {!image && !loading && (
//                 <div className="drag-text">
//                   Drag & Drop
//                   <br /> or <br />
//                   Click to Upload
//                 </div>
//               )}
//               <input type="file" accept="image/*" onChange={handleFileUpload} />
//               {loading && <div className="loader"></div>}
//               {image && !loading && (
//                 <div className="preview">
//                   <img src={image} alt="Uploaded" />
//                   <div className="overlay">
//                     <button className="close-btn" onClick={removeImage}>
//                       {/* &times; */}
//                       <i class="fa fa-trash"></i>
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>

//           {error && (
//             <div className="text-danger  pt-1">
//               Please upload a valid image file JPEG or PNG.
//             </div>
//           )}
//           {meta.touched && meta.error && (
//             <div className="text-danger">{meta.error}</div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import "../../../asset/css/common/dragAndDrop.css";

export const DragAndDropComponent = ({ name, label = "Upload Image" }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  // If there's an initial value, use it as the default image
  const [image, setImage] = useState(field.value || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.style.borderColor = "#4a90e2";
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.target.style.borderColor = "#cccccc";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.target.style.borderColor = "#cccccc";
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];
      if (validateFileType(file)) {
        setLoading(true);
        setTimeout(() => {
          displayImage(file);
          setLoading(false);
        }, 1000);
        setError(false);
        setFieldValue(name, file);
      } else {
        setError(true);
      }
    }
  };

  const validateFileType = (file) => {
    const validTypes = ["image/jpeg", "image/png"];
    return validTypes.includes(file.type);
  };

  const displayImage = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const removeImage = () => {
    setImage(null);
    setFieldValue(name, null);
  };

  return (
    <>
      <p>
        {label} <span className="text-danger">*</span>
      </p>
      <div className="drag_and_drop_image_containner">
        <div className="main_profile">
          <div className="bg_profile_img">
            <div
              className="upload-container"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {!image && !loading && (
                <div className="drag-text">
                  Drag & Drop
                  <br /> or <br />
                  Click to Upload
                </div>
              )}
              <input type="file" accept="image/*" onChange={handleFileUpload} />
              {loading && <div className="loader"></div>}
              {image && !loading && (
                <div className="preview">
                  <img
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    alt="Uploaded"
                  />
                  <div className="overlay">
                    <button className="close-btn" onClick={removeImage}>
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {error && (
            <div className="text-danger pt-1">
              Please upload a valid image file (JPEG or PNG).
            </div>
          )}
          {meta.touched && meta.error && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      </div>
    </>
  );
};
