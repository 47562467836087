import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import TenantsList from "./TenantsList";
import HelmetComponent from "../../Common/HelmetComponent";

const TenantsPage = () => {
  return (
    <>
      <HelmetComponent title="Tenants" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Tenants"
          home="Dashboard"
          currentPage="Tenants"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <TenantsList />
      </div>
    </>
  );
};

export default TenantsPage;
