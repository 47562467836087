import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import OldContactsList from "./OldContactsList";
import HelmetComponent from "../../Common/HelmetComponent";

const OldContacts = () => {
  return (
    <>
      <HelmetComponent title="Old-Contact" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Old Contacts"
          home="Dashboard"
          currentPage="Old Contacts"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
        />
        <OldContactsList />
      </div>
    </>
  );
};

export default OldContacts;
