import React from "react";
import HelmetComponent from "./Common/HelmetComponent";

const Profile = () => {
  return (
    <>
      <HelmetComponent title="Profile" />
      <div className="d-flex w-100 justify-content-center h-100 align-items-center vh-100">
        Profile
      </div>
    </>
  );
};

export default Profile;
