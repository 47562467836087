import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import AllDealsList from "./AllDealsList";
import HelmetComponent from "../../Common/HelmetComponent";

const AllDeals = () => {
  return (
    <>
      <HelmetComponent title="All-Deals" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage All Deals"
          home="Dashboard"
          currentPage="All Deals"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
        />
        <AllDealsList />
      </div>
    </>
  );
};

export default AllDeals;
