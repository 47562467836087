import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import UnitModelList from "./UnitModelList";
import HelmetComponent from "../../Common/HelmetComponent";

const UnitModelPage = () => {
  return (
    <>
      <HelmetComponent title="Unit-Model" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Unit Model"
          home="Dashbord"
          currentPage="Unit Model"
          addExtraPageTitle="Project"
          addExtraPageTitlePath="create-project"
        />
        <UnitModelList />
      </div>
    </>
  );
};

export default UnitModelPage;
