import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../../Common/HelmetComponent";
import CopyToSaleListPage from "./List/copyToSaleList";

const CopyToNewPropertySale = () => {
  return (
    <>
      <HelmetComponent title="Copt To Sale" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Manage New Sale Property"
          home="Dashboard"
          currentPage="New Property Sale"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <CopyToSaleListPage />
      </div>
    </>
  );
};

export default CopyToNewPropertySale;
