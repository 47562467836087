import React, { useState, useEffect } from "react";
import "../../../asset/css/common/multipleUploadImage.css";
import Modal from "../Model/ModelCommon";

const MultipleImageUploader = ({ field, form }) => {
  const [files, setFiles] = useState(field.value || []);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    addFiles(selectedFiles);
  };

  const handleDropFiles = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    addFiles(droppedFiles);
  };

  const handleDragOverFiles = (e) => {
    e.preventDefault();
  };

  const addFiles = (selectedFiles) => {
    const uniqueFiles = selectedFiles.filter(
      (newFile) =>
        !files.some(
          (existingFile) =>
            existingFile.name === newFile.name &&
            existingFile.lastModified === newFile.lastModified
        )
    );
    const updatedFiles = [...files, ...uniqueFiles];
    setFiles(updatedFiles);
    form.setFieldValue(field.name, updatedFiles);
  };

  const handleButtonClick = () => {
    document.getElementById(`${field.name}-file-upload`).click();
  };

  const removeImage = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    form.setFieldValue(field.name, newFiles);
    setShowDeleteModal(false);
  };

  const removeAllImages = () => {
    setFiles([]);
    form.setFieldValue(field.name, []);
  };

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file));
    };
  }, [files]);

  return (
    <>
      <div className="multiple-image-uploader-containner">
        <div
          onDragOver={handleDragOverFiles}
          onDrop={handleDropFiles}
          className="upload-images-button-containner justify-content-center"
        >
          <button
            // className="pulse-btn pulse-active"
            className="pulse-btn rounded-0"
            onClick={handleButtonClick}
            type="button"
          >
            <img
              src="/svg/upload_img.gif"
              alt="upload_img"
              className="upload_image_"
              width="100px"
            />
          </button>
          <input
            id={`${field.name}-file-upload`}
            type="file"
            accept=".jpg,.gif,.png"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="drag-drop-area ">
          <p>Drag & drop your images here, or click to select files</p>
        </div>

        <>
          {files.length > 0 && (
            <>
              <div>
                <button
                  className="btn border border-gray bg-danger text-light"
                  onClick={() => setShowDeleteAllModal(true)}
                  type="button"
                >
                  Delete All Images
                </button>
              </div>
            </>
          )}
        </>

        <div className="row image-patrant mt-3">
          {files.length > 0 &&
            files.map((file, index) => (
              <div
                key={index}
                className="col-lg-3 col-md-6 col-sm-12 krajee-default"
                // className="krajee-default multiple_img_main_default"
              >
                {/* <span className="file-preview-link"> */}
                <img
                  src={URL.createObjectURL(file)}
                  alt="preview"
                  // className="file-preview-image multiple_img_size_set"
                  className="file-preview-image"
                />
                {/* </span> */}
                <div className="">
                  <button
                    className="kv-file-remove"
                    onClick={() => {
                      setDeleteId(index);
                      setShowDeleteModal(true);
                    }}
                  >
                    <i className="fa fa-times"></i>
                    {/* <i className="fa fa-trash"></i> */}
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {showDeleteModal && (
        <div className="common-status-button-containner">
          <Modal
            show={showDeleteModal}
            handleClose={() => {
              setShowDeleteModal(false);
            }}
          >
            <div className="sdvds text-center">
              <img
                className="commonstatusbutton-img"
                src="/svg/warning-animation.gif"
                alt="user"
              />
              <h5 className="mb-5">
                Are you sure you want to delete <br /> this image?
              </h5>

              <div className="modal-footer w-100 px-0">
                <button
                  className="modal-footer-cancel-btn"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="modal-footer-confirm-btn"
                  onClick={() => removeImage(deleteId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
      {showDeleteAllModal && (
        <div className="common-status-button-containner">
          <Modal
            show={showDeleteAllModal}
            handleClose={() => {
              setShowDeleteAllModal(false);
            }}
          >
            <div className="sdvds text-center">
              <img
                className="commonstatusbutton-img"
                src="/svg/warning-animation.gif"
                alt="user"
              />
              <h5 className="mb-5">
                Are you sure you want to delete <br />
                All image?
              </h5>

              <div className="modal-footer w-100 px-0">
                <button
                  className="modal-footer-cancel-btn"
                  onClick={() => setShowDeleteAllModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="modal-footer-confirm-btn"
                  onClick={() => {
                    setShowDeleteAllModal(false);
                    removeAllImages();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default MultipleImageUploader;
