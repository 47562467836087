import React, { useState } from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const LeadsSettings = () => {
  const [status, setStatus] = useState(false);

  const handleChangeStatus = () => {
    setStatus(!status);
  };

  return (
    <>
      <HelmetComponent title="Leads-Setting" />
      <div className="container page-containners-main leads-setting">
        <CustomBreadcrumb
          title="Leads Setting"
          home="Dashboard"
          currentPage="Leads Setting"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <div className="text-center align-items-center justify-content-center d-flex h-50 vh-50 ">
          <button
            className={
              status
                ? "lead-setting-toggle-btn-animate lead-setting-toggle-btn"
                : "lead-setting-toggle-btn"
            }
            onClick={handleChangeStatus}
          >
            {status ? "On" : "Off"}
          </button>
        </div>
      </div>
    </>
  );
};

export default LeadsSettings;
