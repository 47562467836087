import React, { useEffect, useRef, useState } from "react";
import TableComponent from "./TableComponent";
import "../../../asset/css/pages/home.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const [time, setTime] = useState("");
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let radius = canvas.height / 3;
    ctx.translate(radius, radius);
    radius = radius * 0.9;
    const interval = setInterval(() => {
      drawClock(ctx, radius);
      updateTime();
    }, 1);
    return () => clearInterval(interval);
  }, []);

  const updateTime = () => {
    const now = new Date();
    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    setTime(formattedTime);
  };

  const drawClock = (ctx, radius) => {
    drawFace(ctx, radius);
    drawNumbers(ctx, radius);
    drawTicks(ctx, radius);
    drawTime(ctx, radius);
  };

  const drawFace = (ctx, radius) => {
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.fillStyle = "white";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(0, 0, radius * 0.1, 0, 2 * Math.PI);
    ctx.fillStyle = "#121212";
    ctx.fill();
  };

  const drawNumbers = (ctx, radius) => {
    ctx.font = radius * 0.15 + "px 'Crucial'";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillStyle = "#121212";
    for (let num = 1; num < 13; num++) {
      const ang = (num * Math.PI) / 6;
      ctx.rotate(ang);
      ctx.translate(0, -radius * 0.85);
      ctx.rotate(-ang);
      ctx.fillText(num.toString(), 0, 0);
      ctx.rotate(ang);
      ctx.translate(0, radius * 0.85);
      ctx.rotate(-ang);
    }
  };

  const drawTicks = (ctx, radius) => {
    for (let i = 0; i < 60; i++) {
      const ang = (i * Math.PI) / 30;
      ctx.rotate(ang);
      ctx.translate(0, -radius);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, i % 5 === 0 ? radius * 0.08 : radius * 0.04);
      ctx.strokeStyle = "#121212";
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.translate(0, radius);
      ctx.rotate(-ang);
    }
  };

  const drawTime = (ctx, radius) => {
    const now = new Date();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();

    // Hour
    hour = hour % 12;
    hour =
      (hour * Math.PI) / 6 +
      (minute * Math.PI) / (6 * 60) +
      (second * Math.PI) / (360 * 60);
    drawHand(ctx, hour, radius * 0.5, radius * 0.07);

    // Minute
    minute = (minute * Math.PI) / 30 + (second * Math.PI) / (30 * 60);
    drawHand(ctx, minute, radius * 0.75, radius * 0.07);

    // Second
    second = (second * Math.PI) / 30;
    drawHand(ctx, second, radius * 0.9, radius * 0.02, "red");
  };

  const drawHand = (ctx, pos, length, width, color = "#121212") => {
    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.lineCap = "round";
    ctx.strokeStyle = color;
    ctx.moveTo(0, 0);
    ctx.rotate(pos);
    ctx.lineTo(0, -length);
    ctx.stroke();
    ctx.rotate(-pos);
  };

  return (
    <>
      <Helmet>
        <title>Binayah CRM</title>
      </Helmet>
      <div className="container-fluid mt-7 px-5 pb-5 ">
        <div className="row align-items-center">
          <div className="col-xxl-4 col-sm-4 box-col-4 dashbord-animation-container-main">
            <div className="card profile-box">
              <div className="card-body main_card_body">
                <div className="d-flex media media-wrapper justify-content-between">
                  <div className="media-body">
                    <div className="greeting-user">
                      <h4 className="f-w-600 text-left">Welcome Admin!</h4>
                      <p
                        className="text-left pt-2 w-75 custom_text_date"
                        style={{ width: "90%" }}
                      >
                        You have successfully logged in the CRM.
                        <br />
                        Last Login: 28th July 2024, 11.00 AM
                      </p>
                    </div>
                  </div>
                  <div className="whatch-main-containner">
                    <canvas ref={canvasRef}></canvas>
                    <p>{time}</p>
                  </div>
                  {/* <div>
                    <div className="clockbox">
                      <svg
                        id="clock"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 600 600"
                      >
                        <g id="face">
                          <circle
                            className="circle"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                          <path
                            className="hour-marks"
                            d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
                          ></path>
                          <circle
                            className="mid-circle"
                            cx="300"
                            cy="300"
                            r="16.2"
                          ></circle>
                        </g>
                        <g id="hour">
                          <path className="hour-hand" d="M300.5 298V142"></path>
                          <circle
                            className="sizing-box"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                        </g>
                        <g id="minute">
                          <path
                            className="minute-hand"
                            d="M300.5 298V67"
                          ></path>
                          <circle
                            className="sizing-box"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                        </g>
                        <g id="second">
                          <path
                            className="second-hand"
                            d="M300.5 350V55"
                          ></path>
                          <circle
                            className="sizing-box"
                            cx="300"
                            cy="300"
                            r="253.9"
                          ></circle>
                        </g>
                      </svg>
                    </div>
                    <div className="badge f-10 p-0" id="txt"></div>
                  </div> */}
                </div>
                <div className="cartoon">
                  <img
                    className="img-fluid"
                    src="/svg/dashbord-animation.gif"
                    alt="vector women with leptop"
                  />
                  {/* <video width="50" height="50">
                    <source src="/svg/dashbord-video.mp4" type="video/mp4" />
                  </video> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card widget-1">
                  <Link to={`all-listing`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round warning">
                          <div className="bg-round">
                            <img
                              width="70%"
                              src="/svg/listing.svg"
                              alt="All Listing"
                            />
                          </div>
                        </div>
                        <div>
                          <h4>774</h4>
                          <span>All Listings</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="card widget-1">
                  <Link to={`/team-leaders`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round warning">
                          <div className="bg-round">
                            <img
                              width="70%"
                              src="/svg/team-leader-icon.png"
                              alt="All Listing"
                            />
                          </div>
                        </div>
                        <div>
                          <h4>12855</h4>
                          <span>Team Lead</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card widget-1">
                  <Link to={`/all-leads`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round secondary">
                          <div className="bg-round">
                            <img width={"100%"} src="/svg/deal.png" alt="img" />
                          </div>
                        </div>
                        <div>
                          <h4>138</h4>
                          <span>Leads</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/owners`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round primary">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/lender.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>53</h4>
                            <span>Owner</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card widget-1">
                  <Link to={`/all-deals`}>
                    <div className="card-body">
                      <div className="widget-content">
                        <div className="widget-round warning">
                          <div className="bg-round">
                            <img
                              width={"70%"}
                              src="/svg/businessmen_icon.png"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div>
                          <h4>156</h4>
                          <span>Deals</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/tenants`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round success">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/user.svg"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>156</h4>
                            <span>Tenants</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-sm-4 box-col-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/agents`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round success">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/businessman.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>12460</h4>
                            <span>Agent</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="col-xl-12">
                  <div className="card widget-1">
                    <Link to={`/contacts`}>
                      <div className="card-body">
                        <div className="widget-content">
                          <div className="widget-round success">
                            <div className="bg-round">
                              <img
                                width={"80%"}
                                src="/svg/phone.png"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div>
                            <h4>127</h4>
                            <span>Contacts</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableComponent />
      </div>
    </>
  );
};

export default Home;
