import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({ field, form }) => {
  const apiKey = process.env.REACT_APP_TINY_MCE_EDITOR_API_KEY;

  const handleAddText = (htmlContent) => {
    const currentContent = field.value || "";
    const newContent = currentContent + htmlContent;
    form.setFieldValue(field.name, newContent);
  };

  return (
    <>
      <div>
        <div>
          <Editor
            apiKey={apiKey}
            value={field.value || ""}
            init={{
              height: 300,
              menubar: false,
              plugins: ["wordcount", "fullscreen", "lists"],
              toolbar:
                "fullscreen | undo redo | formatpainter casechange blocks | bold italic backcolor underline | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent |  a11ycheck code table ",
            }}
            onEditorChange={(content) => {
              form.setFieldValue(field.name, content);
            }}
          />
          {form.errors[field.name] && form.touched[field.name] && (
            <div className="text-danger">{form.errors[field.name]}</div>
          )}
        </div>

        <div className="tiny_add_text_button_container ">
          <button
            className="tiny_add_text_button"
            onClick={() => handleAddText("<p>Sublocation Text</p>")}
            type="button"
          >
            Add Sublocation Text
          </button>
          <button
            className="tiny_add_text_button"
            onClick={() => handleAddText("<p>Location Text</p>")}
            type="button"
          >
            Add Location Text
          </button>
          <button
            className="tiny_add_text_button"
            onClick={() =>
              handleAddText(
                `<p>Company Name: Binayah Properties L.L.C<br />
RERA ORN: 1162<br />
Address: Liberty Building, Office 1, M floor, Q Motors Building, SZR, Dubai<br />
Office phone no: 04 447 5431<br />
Office fax no: 044475431<br />
Primary email: info@binayah.com<br />
Website: www.binayah.com</p>
<p>Company Profile: Binayah Properties has been active in the UAE Real Estate market since 2007 and is one of the leading Real Estate companies in both the freehold and leasehold areas. Backed by a Management and Sales team with an experience of more than 30 years in the Real Estate sector, we offer our clients a variety of services ranging from property management, asset management, real estate brokerage services, assistance in buying, selling or renting, and other property consultancy services. Our asset management division overlooks a wide range of properties across residential, commercial, leasehold, and freehold areas. We have diversified into related business lines to further build value for our prestigious customers by venturing out into development of apartment buildings and other such projects. Our talented team of experts with substantial investment knowledge and insight into the complexities of the industry ensure our clients receive the best possible advice and service for investments.</p>`
              )
            }
            type="button"
          >
            Company Profile
          </button>
          <button
            className="tiny_add_text_button"
            onClick={() =>
              handleAddText(
                "<p>Call Sheena Mae (AGENT NAME) on +971 555099157 / 97144475430 or visit www.binayah.com for further details.</p>"
              )
            }
            type="button"
          >
            Agent Profile
          </button>
        </div>
      </div>
    </>
  );
};

export default TinyMCEEditor;
