import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import AddRole from "./AddRole";
import HelmetComponent from "../../../Common/HelmetComponent";

const AddRolePage = () => {
  return (
    <>
      <HelmetComponent title="Add-Role" />
      <div className="container page-containners-main set-default-height-min">
        <CustomBreadcrumb
          title="Add Role"
          home="Dashbord"
          currentPage="Add Role"
          addExtraPageTitle="Role Management"
          addExtraPageTitlePath="role"
        />
        <AddRole />
      </div>
    </>
  );
};

export default AddRolePage;
