import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../../Common/HelmetComponent";
import PublicFolderAddImages from "./publicFolderAddImages";

const UploadImagesInPublicFolder = () => {
  return (
    <>
      <HelmetComponent title="Public-Folder" />
      <div className="container page-containners-main set-default-height-min">
        <CustomBreadcrumb
          title="Upload Images In Public Folder"
          home="Dashbord"
          currentPage="Upload Public Folder Images"
          addExtraPageTitle="Public Folder"
          addExtraPageTitlePath="public-folder"
        />
        <PublicFolderAddImages />
      </div>
    </>
  );
};

export default UploadImagesInPublicFolder;
