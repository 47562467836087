import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditAgent from "./editAgent";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditAgentsPage = () => {
  return (
    <>
      <HelmetComponent title="Edit-Agent" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit Agent"
          home="Dashboard"
          currentPage="Edit Agent"
          addExtraPageTitle="Agent"
          addExtraPageTitlePath="agents"
        />
        <EditAgent />
      </div>
    </>
  );
};

export default EditAgentsPage;
