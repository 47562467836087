import React from "react";
import "../../../asset/css/pages/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="footer-copyright text-center col-md-12">
            <p className="mb-0">© Binayah 2024 All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
