import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomSelect from "../../../Common/Select2Box/customSelectbox";
import { addDealsOptionsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import Modal from "../../../Common/Model/ModelCommon";
import { refNoOptionsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import { allDealsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikDateField,
  FormikInputField,
  FormikCanselButton,
  FormikSelectField,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const EditAllDeals = () => {
  const [selectOldDealsModel, setSelectOldDealsModel] = useState(false);
  const [selectRefNoOptipn, setSelectRefNoOption] = useState(0);
  const [getIdWiseDatas, setGetIdWiseDatas] = useState([]);

  const getDealsOptionsData = addDealsOptionsData[0] || [];
  const redSelectNoOptions = refNoOptionsData[0] || [];
  let { id } = useParams();

  const getIdWiseData = (id) => {
    const findData = allDealsData.find((item) => id === item?._id);
    setGetIdWiseDatas(findData);
  };

  const handleCloseOldDealModel = () => {
    setSelectOldDealsModel(false);
  };

  const initialValues = {
    deal_id: getIdWiseDatas?._id || "",
    create_by: "",
    deal_price: "",
    agent: "",
    deal_date: "",
    unit_no: getIdWiseDatas?.Listings_UnitNo || "",
    category: "",
    beds: "",
    project: "",
    location: "",
    sub_location: "",
    tenancy_renewal_date: "",
  };

  const validationSchema = Yup.object()
    .shape({
      deal_id: Yup.string().required("Field is required."),
      create_by: Yup.string().required("Field is required."),
      deal_price: Yup.string().required("Field is required."),
      agent: Yup.string().required("Field is required."),
      deal_date: Yup.string().required("Field is required."),
      unit_no: Yup.string().required("Field is required."),
      category: Yup.string().required("Field is required."),
      beds: Yup.string().required("Field is required."),
      project: Yup.string().required("Field is required."),
      location: Yup.string().required("Field is required."),
      sub_location: Yup.string().required("Field is required."),
      tenancy_renewal_date: Yup.string().required("Field is required."),
    })
    .defined();

  const handelSubmitForm = (e) => {
    console.log(e);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="px-3 py-5 add-all-deals">
        <div className="mb-4 border-bottom-1 pb-4">
          Fieds Marked with (<span className="text-danger"> * </span>) are
          Mandatory
          <button
            className="select-old-deals-btn"
            onClick={() => setSelectOldDealsModel(true)}
          >
            Select Old Deals
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handelSubmitForm}
          enableReinitialize={true}
        >
          {() => {
            return (
              <>
                <Form>
                  <h4 className="fw-bold my-3">Deals Information</h4>
                  <div className="row d-flex  mb-3 ">
                    <FormikInputField
                      label="Deal Id"
                      name="deal_id"
                      placeholder="Enter Deal Id"
                    />
                    <FormikSelectField
                      label="Created by"
                      name="create_by"
                      options={getDealsOptionsData?.category_type}
                      placeholder=" - Select Created by - "
                    />
                  </div>
                  <h4 className="fw-bold my-3">Transaction Details</h4>
                  <div className="row d-flex  mb-3 ">
                    <FormikInputField
                      label="Deal Price"
                      name="deal_price"
                      placeholder="Enter Deal Price"
                    />
                  </div>
                  <h4 className="fw-bold my-3"> Commission Details</h4>
                  <div className="row d-flex  mb-3 ">
                    <FormikSelectField
                      label="Agent"
                      name="agent"
                      options={getDealsOptionsData?.select_by_type}
                      placeholder=" - Select Agent - "
                    />
                  </div>
                  <h4 className="fw-bold my-3"> Deal Closure</h4>
                  <div className="row d-flex  mb-3 ">
                    <FormikDateField label="Deal Date" name="deal_date" />
                  </div>
                  <h4 className="fw-bold my-3">Unit Details</h4>
                  <div className="row d-flex  mb-3 ">
                    <FormikInputField
                      label="Unit No"
                      name="unit_no"
                      placeholder="Enter Unit No"
                    />
                    <FormikSelectField
                      label="Category"
                      name="category"
                      options={getDealsOptionsData?.select_by_type}
                      placeholder=" - Select Category - "
                    />
                    <FormikSelectField
                      label="Beds"
                      name="beds"
                      options={getDealsOptionsData?.select_by_type}
                      placeholder=" - Select Beds - "
                    />
                    <FormikSelectField
                      label="Project"
                      name="project"
                      options={getDealsOptionsData?.select_by_type}
                      placeholder=" - Select Project - "
                    />
                    <FormikSelectField
                      label="Location"
                      name="location"
                      options={getDealsOptionsData?.select_by_type}
                      placeholder=" - Select Location - "
                    />
                    <FormikSelectField
                      label="Sub Location"
                      name="sub_location"
                      options={getDealsOptionsData?.select_by_type}
                      placeholder=" - Select Sub Location - "
                    />
                    <FormikDateField
                      label="Tenancy Renewal Date"
                      name="tenancy_renewal_date"
                    />
                  </div>
                  <div className="cansel-submit-note-btn text-center mt-5 mb-3">
                    <FormikSubmitButton />
                    <FormikCanselButton path="/all-deals" />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>

        {/* Select  */}
        <div className="model_inner">
          <Modal
            show={selectOldDealsModel}
            handleClose={handleCloseOldDealModel}
          >
            <div className="sdvds">
              <h6 className="mb-5">Old Deal</h6>
              <div className="row">
                <div className="col-12 mb-2 ">
                  <p className="m-0">
                    Select Ref No. <span className="text-danger">*</span>
                  </p>
                  <CustomSelect
                    options={redSelectNoOptions?.refOptions}
                    placeholder=" - Select Ref No. - "
                    value={selectRefNoOptipn}
                    onChange={(e) => {
                      setSelectRefNoOption(e);
                    }}
                  />
                </div>
              </div>
              <div className="text-end close-button-model">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setSelectOldDealsModel(false)}
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default EditAllDeals;
