import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import { useParams } from "react-router-dom";
import { propertyListData } from "../../../../asset/StastucJsonData/PropertyJsonData";
import CustomDatePicker from "../../../Common/DatePicker/DatePickerCommon";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import HelmetComponent from "../../../Common/HelmetComponent";
import {
  FormikCanselButton,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";

const EditOwnerProperty = () => {
  const [getIdWiseData, setGetIdWiseData] = useState([]);
  // const [startDate, setStartDate] = useState();

  let { id } = useParams();

  const getAgentData = (id) => {
    const findData = propertyListData.find((item) => id === item?._id);
    setGetIdWiseData(findData);
  };

  const initialValues = {
    type: "",
    category: "",
    location: "",
    sub_location: "",
    tenancy_start_date: "",
    tenancy_renew_date: "",
    statement: "",
  };

  const validationSchema = Yup.object()
    .shape({
      type: Yup.string().required("Field is required."),
      category: Yup.string().required("Field is required."),
      location: Yup.string().required("Field is required."),
      sub_location: Yup.string().required("Field is required."),
      tenancy_start_date: Yup.date().nullable().required("Date is required"),
      tenancy_renew_date: Yup.date().nullable().required("Date is required"),
      statement: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log("e", e);
  };

  useEffect(() => {
    getAgentData(id);
  }, [id]);

  return (
    <>
      <HelmetComponent title="Edit-Owner-Property" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title={`Edit Property for- ${getIdWiseData?.Agent1}`}
          home="Dashboard"
          currentPage={`Edit Property for- ${getIdWiseData?.Agent1}`}
          addExtraPageTitle="Owners"
          addExtraPageTitlePath="owners"
        />

        <div className="container my-5">
          <div className=" mb-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
            >
              {() => {
                return (
                  <>
                    <Form>
                      <div className="row my-3">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <p className="m-0">
                            Type <span className="text-danger">*</span>
                          </p>
                          <Field
                            className="form-control rounded-0"
                            type="text"
                            placeholder="Enter Unit Type"
                            name="type"
                          />
                          <ErrorMessage
                            name="type"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <p className="m-0">
                            Category <span className="text-danger">*</span>
                          </p>
                          <Field
                            className="form-control rounded-0"
                            type="text"
                            placeholder="Enter Category"
                            name="category"
                          />
                          <ErrorMessage
                            name="category"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <p className="m-0">
                            Location <span className="text-danger">*</span>
                          </p>
                          <Field
                            className="form-control rounded-0"
                            type="text"
                            placeholder="Enter Location"
                            name="location"
                          />
                          <ErrorMessage
                            name="location"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <p className="m-0">
                            Sub Location <span className="text-danger">*</span>
                          </p>
                          <Field
                            className="form-control rounded-0"
                            type="text"
                            placeholder="Enter Sub Location"
                            name="sub_location"
                          />
                          <ErrorMessage
                            name="sub_location"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <p className="m-0">
                            Tenancy Start Date{" "}
                            <span className="text-danger">*</span>
                          </p>
                          <Field name="tenancy_start_date">
                            {({ field, form }) => (
                              <CustomDatePicker
                                startDate={field.value}
                                setStartDate={(date) =>
                                  form.setFieldValue("tenancy_start_date", date)
                                }
                                src={"/svg/calendar.png"}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="tenancy_start_date"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                          <p className="m-0">
                            Tenancy Renew Date{" "}
                            <span className="text-danger">*</span>
                          </p>
                          <Field name="tenancy_renew_date">
                            {({ field, form }) => (
                              <CustomDatePicker
                                startDate={field.value}
                                setStartDate={(date) =>
                                  form.setFieldValue("tenancy_renew_date", date)
                                }
                                src={"/svg/calendar.png"}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="tenancy_renew_date"
                            component={"div"}
                            className="text-danger"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                          <p className="m-0">
                            Statement <span className="text-danger">*</span>
                          </p>
                          <Field
                            className="form-control rounded-0"
                            type="text"
                            placeholder="Enter Statement"
                            name="statement"
                          />
                          <ErrorMessage
                            name="statement"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className=" text-center mt-5 mb-3">
                        {/* <button
                          type="submit"
                          className="btn btn-secondary mx-2"
                        >
                          Submit
                        </button>

                        <Link to="/owners">
                          <button className="mx-2 btn btn-light">Cancel</button>
                        </Link> */}
                        <FormikSubmitButton />
                        <FormikCanselButton path="/owners" />
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOwnerProperty;
