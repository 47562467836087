import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import { useParams } from "react-router-dom";
import { agentPropertyListData } from "../../../../asset/StastucJsonData/PropertyJsonData";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  FormikDateField,
  FormikInputField,
  FormikCanselButton,
  FormikSubmitButton,
} from "../../../Common/FormikInputFields/CommonFromikFields";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditAgentProperty = () => {
  const [getIdWiseData, setGetIdWiseData] = useState([]);

  let { id } = useParams();

  console.log("getIdWiseData", getIdWiseData);

  const initialValues = {
    reference_no: getIdWiseData?.Reference || "",
    type: getIdWiseData?.Type || "",
    categorty: getIdWiseData?.Listings_Category || "",
    location: getIdWiseData?.Area_Location || "",
    sub_location: getIdWiseData?.Sub_Area_Location || "",
    tenancy_start_date: getIdWiseData?.tenancy_start_date || "",
    tenancy_renew_date: getIdWiseData?.tenancy_renew_date || "",
    statement: getIdWiseData?.Statement || "",
  };

  const validationSchema = Yup.object()
    .shape({
      reference_no: Yup.string().required("Field is required."),
      type: Yup.string().required("Field is required."),
      categorty: Yup.string().required("Field is required."),
      location: Yup.string().required("Field is required."),
      sub_location: Yup.string().required("Field is required."),
      tenancy_start_date: Yup.date()
        .nullable()
        .required("Tenancy Start Date is required"),
      tenancy_renew_date: Yup.date()
        .nullable()
        .required("Tenancy Renew Date is required"),
      statement: Yup.string().required("Field is required."),
    })
    .defined();

  const handleSubmitForm = (e) => {
    console.log("e", e);
  };

  const getAgentData = (id) => {
    const findData = agentPropertyListData.find((item) => id === item?._id);
    setGetIdWiseData(findData);
  };

  useEffect(() => {
    getAgentData(id);
  }, [id]);

  return (
    <>
      <HelmetComponent title="Edit-Agent-Property" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title={`Edit Agent Property for - ${getIdWiseData?.Agent1}`}
          home="Dashboard"
          currentPage={`Edit Agent Property for - ${getIdWiseData?.Agent1}`}
          addExtraPageTitle="Agents"
          addExtraPageTitlePath="agents"
        />

        <div className="container my-5">
          <div className="mb-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmitForm}
              enableReinitialize={true}
            >
              {() => {
                return (
                  <>
                    <Form>
                      <div className="row my-3">
                        <FormikInputField
                          label="Reference No."
                          name="reference_no"
                          placeholder="Enter Reference No"
                        />
                        <FormikInputField
                          label="Type"
                          name="type"
                          placeholder="Enter Type"
                        />
                        <FormikInputField
                          label="Category"
                          name="categorty"
                          placeholder="Enter Category"
                        />
                        <FormikInputField
                          label="Location"
                          name="location"
                          placeholder="Enter Location"
                        />
                        <FormikInputField
                          label="Sub Location"
                          name="sub_location"
                          placeholder="Enter Sub Location"
                        />

                        <FormikDateField
                          label="Tenancy Start Date"
                          name="tenancy_start_date"
                        />
                        <FormikDateField
                          label="Tenancy Renew Date"
                          name="tenancy_renew_date"
                        />
                        <FormikInputField
                          label="Statement"
                          name="statement"
                          placeholder="Enter Statement"
                        />
                      </div>
                      <div className="text-center mt-5 mb-3">
                        <FormikSubmitButton />
                        <FormikCanselButton path="/agents" />
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAgentProperty;
