import React from "react";
import "../../../asset/css/common/model.css";
import LocationList from "./LocationList";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const Location = () => {
  return (
    <>
      <HelmetComponent title="Location" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Location"
          home="Dashbord"
          currentPage="Location"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <LocationList />
      </div>
    </>
  );
};

export default Location;
