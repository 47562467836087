import React from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import EditAllListing from "./EditAllListing";
import HelmetComponent from "../../../Common/HelmetComponent";

const EditAllListingPage = () => {
  return (
    <>
      <HelmetComponent title="Edit-Listing" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Edit All Listing"
          home="Dashboard"
          currentPage="Edit All Listing"
          addExtraPageTitle="All Listing"
          addExtraPageTitlePath="all-listing"
        />
        <EditAllListing />
      </div>
    </>
  );
};

export default EditAllListingPage;
