import React from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "../../../asset/css/common/customeDatePicker.css";

const CustomDatePicker = ({ startDate, setStartDate, src }) => {
  return (
    <>
      <DatePicker
        className="custom-select"
        value={startDate}
        onChange={(date) => setStartDate(date)}
        readOnly={true}
        format="y/MM/dd"
        dayPlaceholder="dd "
        monthPlaceholder="mm "
        yearPlaceholder="yy "
        calendarIcon={
          <img
            width="23px"
            className="me-1 datepicker-img"
            src={src}
            alt="img"
          />
        }
      />
    </>
  );
};

export default CustomDatePicker;
