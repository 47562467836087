import React from "react";
import "../../../asset/css/common/model.css";
import SubLocationList from "./SubLocationList";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const SubLocation = () => {
  return (
    <>
      <HelmetComponent title="Sub-Location" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Sub Location"
          home="Dashboard"
          currentPage="Sub Location"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <SubLocationList />
      </div>
    </>
  );
};

export default SubLocation;
