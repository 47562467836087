import React from "react";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import OwnerList from "./OwnerList";
import HelmetComponent from "../../Common/HelmetComponent";

const OwnerPage = () => {
  return (
    <>
      <HelmetComponent title="Owner" />
      <div className="container page-containners-main ">
        <CustomBreadcrumb
          title="Manage Owners"
          home="Dashboard"
          currentPage="Owners"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <OwnerList />
      </div>
    </>
  );
};

export default OwnerPage;
