import React from "react";
import "../../../asset/css/common/model.css";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import FeaturesMasterList from "./FeaturesList";
import HelmetComponent from "../../Common/HelmetComponent";

const FeaturesMasterPage = () => {
  return (
    <>
      <HelmetComponent title="Features" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Features"
          home="Dashbord"
          currentPage="Features"
          addExtraPageTitle=""
          addExtraPageTitlePath=""
        />
        <FeaturesMasterList />
      </div>
    </>
  );
};

export default FeaturesMasterPage;
