import React from "react";
import WebsiteLeadsList from "./WebsiteLeadsList";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import HelmetComponent from "../../Common/HelmetComponent";

const WebsiteLeads = () => {
  return (
    <>
      <HelmetComponent title="Website-Leads" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Manage Website Leads"
          home="Dashboard"
          currentPage="Website Leads"
          addExtraPageTitle="All Leads"
          addExtraPageTitlePath="all-leads"
        />
        <WebsiteLeadsList />
      </div>
    </>
  );
};

export default WebsiteLeads;
