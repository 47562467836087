import React, { useEffect, useState } from "react";
import { allDealsData } from "../../../../asset/StastucJsonData/AllDealsJsonData";
import { useParams } from "react-router-dom";
import DetailComponent from "../../../Common/DetailsPageComponent/detailComponent";

const AllDealsDetailsComponent = () => {
  const [getId, setGetId] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const finData = allDealsData.find((userID) => id === userID._id);
    setGetId(finData);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <div className="container">
        <DetailComponent
          title="Deals Ref No. :"
          title1="Created By :"
          title2="Agent :"
          title3="Deal Date :"
          data={getId?.Reference}
          data1={getId?.Created_By}
          data2={" - "}
          data3={getId?.Deal_Estimated_Date}
        />
        <DetailComponent
          title="Unit No. :"
          title1="Category :"
          title2="Beds :"
          title3="Location :"
          data={getId?.Listings_UnitNo}
          data1={getId?.Listings_Category}
          data2={getId?.Listings_Beds}
          data3={getId?.Area_Location}
        />
        <DetailComponent
          title="Sub Location :"
          title1="Tenancy Renewal Date :"
          title2=""
          title3=""
          data={getId?.Sub_Area_Location}
          data1={getId?.Tenancy_Renewal_Date}
          data2=""
          data3=""
        />
      </div>
    </>
  );
};

export default AllDealsDetailsComponent;
