import React from "react";
import OtpForm from "../OtpForm/index";
import "../../../asset/css/common/otpModel.css";

const OtpModal = ({ isOpen, onClose, onSubmit, resendOtp }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="otp_model_main_containner">
        <div className="otp-modal-overlay">
          <div className="otp-modal-content">
            <button className="close-btn" onClick={onClose}>
              ×
            </button>
            {/* <h2 className="text-center">OTP</h2> */}
            <div className="otp_logo_containner">
              <img src="/svg/otp_logo.gif" alt="otp_logo" className="" />
            </div>
            <OtpForm onSubmit={onSubmit} resendOtp={resendOtp} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpModal;
